@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/mixins';

.NetworksGeo {
  text-align: center;
  padding: 40px 0;
  @include media-breakpoint-down(md) {
    padding: 40px 20px;
  }

  &__headline {
    margin: 0 auto 16px;
  }
  &__description {
    max-width: 648px;
    margin: 0 auto 24px;
    @include one-hulu-body-16;
  }
  .ZipCodeInput {
    max-width: 648px;
    margin: 32px auto 0;
  }

  &__inputEyebrow {
    color: $gray-15;
    margin-bottom: 0;
  }

  &__zipPrompt {
    color: $text-body;
    margin-top: 8px;
    margin-bottom: 0;
  }

  &__zipDisclaimer {
    margin: 0 auto 24px;
    max-width: 648px;
    color: $gray-12;
  }

  &__networkContainer {
    text-align: center;
    margin-bottom: 40px;
  }

  &__networkList {
    margin: 20px auto auto;
    width: auto;
  }

  &__logoGroup {
    margin-bottom: 40px;
  }

  &__groupTitle {
    color: $gray-6;
    text-align: center;
  }

  &__networkIcon {
    height: 40px;
    width: 70px;
    margin: 8px 5px;
    object-fit: contain;
  }

  &__logo-list {
    margin-top: 20px;
    padding-right: 15px;
    padding-left: 15px;
  }

  &__list-item {
    height: 88px;
    max-height: 88px;
    margin-bottom: 24px;
  }

  &__seeAllCta {
    margin-top: -15px;
  }

  .container-width {
    padding: 0;
  }
}