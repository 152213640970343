$prefix: '/static/hitch';

$nav-height: 75px;

$collection-spacing-sm: 24px;
$collection-spacing-md: 32px;
$collection-spacing-lg: 40px;

$zindex: (
  default: auto,
  hide: -1,
  bottom: 0,
  lowest: 0,
  lower: 10,
  low: 20,
  middle: 30,
  high: 40,
  higher: 50,
  highest: 60,
  top: 1000,
);

// container widths
$container-max-width-xxl: 1236px;
$container-max-width-xl: 976px;
$container-max-width-lg: 786px;
