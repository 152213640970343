@import '../../../../styles/breakpoints';
@import '../../../../styles/mixins';

.PortraitSlider {
  .Slider {
    &__item {
      padding: 0 8px;
      @include media-breakpoint-down(md) {
        pointer-events: all;
      }
    }
  }
}