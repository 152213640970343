@import '~@hulu/site-styles/colors';
@import '../../../../styles/breakpoints';
@import '../../../../styles/global';
@import '../../../../styles/mixins';
@import '../../../../styles/colors';

$standardHeightD: 660px;
$standardHeightT: 600px;
$standardHeightM: 660px;
$tallHeightD: 760px;
$tallHeightT: 660px;
$tallHeightM: 630px;

.ExpEmailCaptureMasthead {
  display: -ms-flexbox;
  -ms-flex-direction: column;
  -ms-align-items: center;
  background-image: linear-gradient(
    135deg,
    #231437 0%,
    #2c385e 50%,
    #336e6b 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  background-position: center top;
  background-size: cover;
  text-align: center;
  position: relative;

  // Variations
  &.align-left {
    .Masthead__container {
      & > * {
        @include media-breakpoint-up(md) {
          text-align: left;
          margin-left: 0;
          justify-content: flex-start;
        }
      }
    }
    .Anchor {
      margin: 0 auto;
      text-align: center;
    }
  }

  &.width-736 {
    .Masthead__container {
      & > * {
        width: 100%;

        @include media-breakpoint-up(sm) {
          width: 570px;
        }
        @include media-breakpoint-up(md) {
          width: 700px;
        }
        @include media-breakpoint-up(lg) {
          width: 736px;
        }
      }
    }
  }

  &.width-690 {
    .Masthead__container {
      & > * {
        width: 100%;

        @include media-breakpoint-up(sm) {
          width: 560px;
        }
        @include media-breakpoint-up(lg) {
          width: 560px;
        }
        @include media-breakpoint-up(xl) {
          width: 690px;
        }
      }
    }
  }

  &--fullscreen {
    height: 100vh;

    &.Masthead--tophat-included {
      height: calc(100vh - 74px);
    }

    .Anchor {
      margin: 0 auto;
      text-align: center;
    }
  }

  &--brand {
    border: 8px solid #0ee694;
    height: 100vh;

    .Masthead__container {
      padding-bottom: 70px;
      justify-content: flex-end;
    }

    .Masthead__children {
      display: flex;
      padding-bottom: 32px;
      visibility: visible;

      @include media-breakpoint-up(lg) {
        padding-bottom: 60px;
      }
    }
  }

  &--brand-border {
    border: 8px solid #0ee694;
  }

  // Size at different endpoints
  @include media-breakpoint-up(xs) {
    padding-top: 72px;
    min-height: $standardHeightM;

    &.Masthead--tall {
      padding-top: 41px;
      min-height: $tallHeightM;
    }
  }

  @include media-breakpoint-up(md) {
    padding-top: 72px;
    min-height: $standardHeightT;

    &.Masthead--tall {
      padding-top: 72px;
      min-height: $tallHeightT;
    }
  }

  @include media-breakpoint-up(lg) {
    padding-top: 80px;
    min-height: $standardHeightD;

    &.Masthead--tall {
      min-height: $tallHeightD;
    }
  }

  &.Masthead--short {
    min-height: 0px;
    .Masthead__container {
      padding-top: 49px;
      padding-bottom: 89px;
    }
    .Masthead__preheadline {
      font-size: 14px;
      padding-bottom: 16px;
    }
    .Masthead__primary-message {
      margin-top: 0;
    }
    @include media-breakpoint-up(lg) {
      .Masthead__container {
        padding-top: 12px;
      }
      .Masthead__preheadline {
        padding-bottom: 12px;
      }
    }
  }

  // Container for texts and contents inside masthead
  &__container {
    @extend .container-width;
    position: relative;
    // flex
    display: -ms-flexbox;
    -ms-flex-direction: column;
    -ms-justify-content: center;

    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;

    width: 100%;

    margin-left: 0;
    margin-right: 0;
    color: $white;
    line-height: 1.3;
    @include z-index(bottom);

    padding: 40px 80px;
    @include media-breakpoint-up(lg) {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    @include media-breakpoint-down(xxl) {
      margin-left: auto;
      margin-right: auto;
    }
    @include media-breakpoint-down(xl) {
      padding-right: 40px;
      padding-left: 40px;
    }

    & > * {
      margin-left: auto;
      margin-right: auto;
      width: 100%;

      @include media-breakpoint-up(md) {
        width: 570px;
      }

      @include media-breakpoint-up(lg) {
        width: 670px;
      }
    }
  }

  &__children {
    @extend .Masthead__container;
    flex-grow: 0;
    padding-top: 0;
  }

  &__legal {
    align-self: center;
    margin-top: 16px;
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 536px;
    }
  }

  &__banner {
    color: $text-white;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $red-1;

    @include one-hulu-body-16;
    @include z-index(high);

    @include media-breakpoint-up(lg) {
      @include one-hulu-body-24;
    }
  }

  &.mobile-portrait {
    .Masthead__container {
      padding: 0 20px 60px;

      &>* {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .Masthead__primary-message {
      @include one-hulu-body-14;
    }

    .Masthead__input-cta {
      padding: 20px 46px;
      height: auto;
    }

    .Masthead__legal {
      @include one-hulu-body-10;

      width: auto;
      margin-left: auto;
      margin-right: auto;
    }

    .BrandIcon {
      position: absolute;
      right: 20px;
      bottom: 0;
      width: 118px;
    }

    @include media-breakpoint-up(md) {
      min-height: 684px;

      .Masthead__container {
        display: block;
        margin-top: 382px;
        padding-bottom: 74px;
        color: $text-white;

        &>* {
          width: auto;
          text-align: center;
          margin-left: 32px;
          margin-right: 32px;
        }
      }

      .Masthead__subheadline {
        @include one-hulu-subtitle-18;
      }

      .Masthead__input {
        .Masthead__input-cta {
          margin: 0 auto;
        }
      }

      .Masthead__legal {
        @include one-hulu-body-12;

        width: 336px;
        margin-left: auto;
        margin-right: auto;
      }

      .BrandIcon {
        margin: 0;
        width: 190px;
      }
    }
  }

  &__headline {
    @include one-hulu-title-40;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      @include one-hulu-title-48;
    }

    &-container {
      @include media-breakpoint-up(xl) {
        width: 760px;
      }
    }
  }

  &__subheadline {
    @include one-hulu-subtitle-18;

    margin-top: 0.5em;
    margin-top: 8px;

    @include media-breakpoint-up(md) {
      @include one-hulu-subtitle-24;

      margin-top: 16px;
    }
  }

  &__preheadline {
    @include one-hulu-label-12;

    color: $white;
    padding-bottom: 7px;

    @include media-breakpoint-up(md) {
      padding-bottom: 17px;
    }
  }

  &__primary-message {
    @include one-hulu-body-16;

    margin-top: 20px;

    @include media-breakpoint-up(lg) {
      @include one-hulu-body-24;

      margin-top: 32px;
    }
  }

  &__secondary-message {
    @include one-hulu-body-12;

    margin-top: 20px;

    @include media-breakpoint-up(md) {
      @include one-hulu-body-14;
    }

    @include media-breakpoint-up(lg) {
      @include one-hulu-body-16;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__input {
    margin-top: 1.6em;

    &-label {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    div:not(:last-child) {
      padding-right: 5px;
    }

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: center;
      align-items: center;

      &.align-left {
        justify-content: flex-start;
      }
    }

    &-wrapper {
      position: relative;
      display: inline-block;

      @include media-breakpoint-down(md) {
        display: block;
        width: 261px;
        margin: 0 auto;

        &+button {
          width: 261px;
        }
      }
    }

    &-box {
      display: inline-block;
      width: 260px;
      height: 56px;
      padding: 0 1em;
      margin-right: 5px;
      background: $white;
      border: none;
      font-weight: 100;

      &::-ms-clear {
        width: 0;
        height: 0;
      }

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $text-placeholder;
        opacity: 1;
        /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $text-placeholder;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $text-placeholder;
      }

      @include media-breakpoint-down(md) {
        display: block;
        min-width: 136px;
        margin: 0 auto 10px;
      }

      @include media-breakpoint-up(lg) {
        width: 300px;
      }
    }

    &-cta {
      display: inline-flex;
      align-items: center;

      @include media-breakpoint-down(sm) {
        margin: 0 auto;
      }

      &:not(:first-child) {
        margin-left: 8px;

        @include media-breakpoint-down(sm) {
          margin-left: auto;
          margin-top: 8px;
        }
      }
    }

    &-icon {
      @include one-hulu-body-18;

      height: 28px;
      width: 28px;
      box-sizing: border-box;
      border: 2px solid $red-1;
      color: $red-1;
      border-radius: 14px;
      text-align: center;
      position: absolute;
      right: 20px;
      top: 10px;
      visibility: hidden;

      @include media-breakpoint-up(lg) {
        top: 14px;
      }
    }

    &--invalid {
      .Masthead__input-icon {
        visibility: visible;
      }

      .Masthead__input-box {
        color: $red-1;
      }
    }
  }

  .Masthead__input-cta.button--cta {
    width: 90%;
    background: #1CE783;
    @include media-breakpoint-up (md) {
      width: 93%;
    }
    @include media-breakpoint-up (lg) {
      width: 93%;
      margin-right: 4px;
    }
  }

  .Masthead__email-capture {
    &-container {
      background: #272C34;
      border-radius: 8px;
      width: 100%;
      align-items: center;
      @media (min-height: 0px) and (max-height: 600px) {
        height: 34vh;
      }
      @media (min-height: 601px) and (max-height: 670px) {
        height: 31vh;
      }
      @media (min-height: 671px) and (max-height: 800px) {
        height: 28vh;
      }
      @media (min-height: 801px) and (max-height: 1000px) {
        height: 23vh;
      }
      @media (min-height: 1001px) and (max-height: 1179px) {
        height: 20vh;
      }
      @media (min-height: 1180px) and (max-height: 1240px) {
        height: 18vh;
      }
      @media (min-height: 1241px) {
        height: 15vh;
      }
      &__error {
        background: #272C34;
        border-radius: 8px;
        width: 100%;
        align-items: center;
        @media (min-height: 0px) and (max-height: 600px) {
          height: 37vh;
        }
        @media (min-height: 601px) and (max-height: 670px) {
          height: 33vh;
        }
        @media (min-height: 671px) and (max-height: 800px) {
          height: 30vh;
        }
        @media (min-height: 801px) and (max-height: 1000px) {
          height: 25vh;
        }
        @media (min-height: 1001px) and (max-height: 1179px) {
          height: 22vh;
        }
        @media (min-height: 1180px) and (max-height: 1240px) {
          height: 20vh;
        }
        @media (min-height: 1241px) {
          height: 17vh;
        }
      }
    }

    &-prompt {
      padding-top: 16px;
      width: 100%;
    }

    &-input {
      width: 90%;
      background: #31343E;
      border-radius: 4px;
      height: 56px;
      font-family: 'Graphik';
      font-size: 16px;
      margin: 16px 0px 16px 0px;
      padding-right: 16px;
      padding-left: 12px;
      color: #A8A9AD;
      border: none;
      &::placeholder {
        color: #A8A9AD;
      }
      &:focus-visible {
        outline: none;
        border: 1px solid white;
      }
      @include media-breakpoint-up(md) {
        width: 93%;
        margin: 16px 0px 20px 0px;
      }

      &__error {
        width: 90%;
        background: #31343E;
        border-radius: 4px;
        height: 56px;
        font-family: 'Graphik';
        font-size: 16px;
        margin: 16px 0px 4px 0px;
        padding-left: 12px;
        color: #A8A9AD;
        border: 2px solid #F14668;
        box-sizing: border-box;
        &:focus-visible {
          border: 2px solid #F14668;
          outline: none;
        }
        @include media-breakpoint-up(md) {
          width: 93%;
        }
      }

      &-error-text {
        color: #F14668;
        float: left;
        text-align: left;
        padding: 0px 0px 10px 20px;
        width: 90%;
        height: 32px;
        a {
          color: #F14668;
        }
        @include media-breakpoint-up (md) {
          padding: 0px 0px 20px 28px;
        }
      }
    }
  }
}
