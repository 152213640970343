@import '~@hulu/web-ui/generated/_variables.scss';
@import '../styles/breakpoints.scss';
@import '../styles/variables.scss';

// Must be called from within an <img>. Used with lazysizes
// to set fade-in on lazy-loaded images.
@mixin lazyLoad($minHeight) {
  min-height: $minHeight;
  opacity: 0;
  transition: opacity 0.3s;

  &.lazyloaded {
    @if $minHeight != null {
      min-height: unset;
      opacity: 1;
    } @else {
      opacity: 1;
    }
  }
}

@mixin z-index($layer) {
  z-index: map-get($zindex, $layer);
}

// See http://joshbroton.com/quick-fix-sass-mixins-for-css-keyframe-animations/
@mixin animation($animate...) {
  $max: length($animate);
  $animations: '';

  @for $i from 1 through $max {
    $animations: #{$animations + nth($animate, $i)};

    @if $i < $max {
      $animations: #{$animations + ", "};
    }
  }
  -webkit-animation: $animations;
  -moz-animation:    $animations;
  -o-animation:      $animations;
  animation:         $animations;
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }
  @-moz-keyframes #{$animationName} {
    @content;
  }
  @-o-keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
}

@mixin flex($justify, $align, $wrap) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-wrap: $wrap;
  @if $wrap != null {
    flex-wrap: $wrap;
  } @else {
    flex-wrap: nowrap;
  }
}


// Build OneHulu typography mixins using variables defined in web-ui

/*** Title sizes ***/
@mixin one-hulu-title-48 {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-title-48-font-size;
  font-weight: $font-title-48-font-weight;
  letter-spacing: $font-title-48-letter-spacing;
  line-height: $font-title-48-line-height;
  text-transform: none;
}

@mixin one-hulu-title-48-caps {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-title-48-caps-font-size;
  font-weight: $font-title-48-caps-font-weight;
  letter-spacing: $font-title-48-caps-letter-spacing;
  line-height: $font-title-48-caps-line-height;
  text-transform: $font-title-48-caps-text-transform;
}

@mixin one-hulu-title-40 {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-title-40-font-size;
  font-weight: $font-title-40-font-weight;
  letter-spacing: $font-title-40-letter-spacing;
  line-height: $font-title-40-line-height;
  text-transform: none;
}

@mixin one-hulu-title-40-caps {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-title-40-caps-font-size;
  font-weight: $font-title-40-caps-font-weight;
  letter-spacing: $font-title-40-caps-letter-spacing;
  line-height: $font-title-40-caps-line-height;
  text-transform: $font-title-40-caps-text-transform;
}

@mixin one-hulu-title-32 {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-title-32-font-size;
  font-weight: $font-title-32-font-weight;
  letter-spacing: $font-title-32-letter-spacing;
  line-height: $font-title-32-line-height;
  text-transform: none;
}

@mixin one-hulu-title-32-caps {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-title-32-caps-font-size;
  font-weight: $font-title-32-caps-font-weight;
  letter-spacing: $font-title-32-caps-letter-spacing;
  line-height: $font-title-32-caps-line-height;
  text-transform: $font-title-32-caps-text-transform;
}

@mixin one-hulu-title-24 {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-title-24-font-size;
  font-weight: $font-title-24-font-weight;
  letter-spacing: $font-title-24-letter-spacing;
  line-height: $font-title-24-line-height;
  text-transform: none;
}

@mixin one-hulu-title-24-caps {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-title-24-caps-font-size;
  font-weight: $font-title-24-caps-font-weight;
  letter-spacing: $font-title-24-caps-letter-spacing;
  line-height: $font-title-24-caps-line-height;
  text-transform: $font-title-24-caps-text-transform;
}

@mixin one-hulu-title-18 {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-title-18-font-size;
  font-weight: $font-title-18-font-weight;
  letter-spacing: $font-title-18-letter-spacing;
  line-height: $font-title-18-line-height;
  text-transform: none;
}

@mixin one-hulu-title-18-caps {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-title-18-caps-font-size;
  font-weight: $font-title-18-caps-font-weight;
  letter-spacing: $font-title-18-caps-letter-spacing;
  line-height: $font-title-18-caps-line-height;
  text-transform: $font-title-18-caps-text-transform;
}

@mixin one-hulu-title-14 {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-title-14-font-size;
  font-weight: $font-title-14-font-weight;
  letter-spacing: $font-title-14-letter-spacing;
  line-height: $font-title-14-line-height;
  text-transform: none;
}

/*** Subtitle sizes ***/
@mixin one-hulu-subtitle-24 {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-subtitle-24-font-size;
  font-weight: $font-subtitle-24-font-weight;
  letter-spacing: $font-subtitle-24-letter-spacing;
  line-height: $font-subtitle-24-line-height;
  text-transform: none;
}

@mixin one-hulu-subtitle-24-caps {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-subtitle-24-caps-font-size;
  font-weight: $font-subtitle-24-caps-font-weight;
  letter-spacing: $font-subtitle-24-caps-letter-spacing;
  line-height: $font-subtitle-24-caps-line-height;
  text-transform: $font-subtitle-24-caps-text-transform;
}

@mixin one-hulu-subtitle-18 {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-subtitle-18-font-size;
  font-weight: $font-subtitle-18-font-weight;
  letter-spacing: $font-subtitle-18-letter-spacing;
  line-height: $font-subtitle-18-line-height;
  text-transform: none;
}

@mixin one-hulu-subtitle-18-caps {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-subtitle-18-caps-font-size;
  font-weight: $font-subtitle-18-caps-font-weight;
  letter-spacing: $font-subtitle-18-caps-letter-spacing;
  line-height: $font-subtitle-18-caps-line-height;
  text-transform: $font-subtitle-18-caps-text-transform;
}

@mixin one-hulu-subtitle-16 {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-subtitle-16-font-size;
  font-weight: $font-subtitle-16-font-weight;
  letter-spacing: $font-subtitle-16-letter-spacing;
  line-height: $font-subtitle-16-line-height;
  text-transform: none;
}

@mixin one-hulu-subtitle-16-caps {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-subtitle-16-caps-font-size;
  font-weight: $font-subtitle-16-caps-font-weight;
  letter-spacing: $font-subtitle-16-caps-letter-spacing;
  line-height: $font-subtitle-16-caps-line-height;
  text-transform: $font-subtitle-16-caps-text-transform;
}

@mixin one-hulu-subtitle-14 {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-subtitle-14-font-size;
  font-weight: $font-subtitle-14-font-weight;
  letter-spacing: $font-subtitle-14-letter-spacing;
  line-height: $font-subtitle-14-line-height;
  text-transform: none;
}

@mixin one-hulu-subtitle-14-caps {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-subtitle-14-caps-font-size;
  font-weight: $font-subtitle-14-caps-font-weight;
  letter-spacing: $font-subtitle-14-caps-letter-spacing;
  line-height: $font-subtitle-14-caps-line-height;
  text-transform: $font-subtitle-14-caps-text-transform;
}

@mixin one-hulu-subtitle-12 {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-subtitle-12-font-size;
  font-weight: $font-subtitle-12-font-weight;
  letter-spacing: $font-subtitle-12-letter-spacing;
  line-height: $font-subtitle-12-line-height;
  text-transform: none;
}

@mixin one-hulu-subtitle-12-caps {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-subtitle-12-caps-font-size;
  font-weight: $font-subtitle-12-caps-font-weight;
  letter-spacing: $font-subtitle-12-caps-letter-spacing;
  line-height: $font-subtitle-12-caps-line-height;
  text-transform: $font-subtitle-12-caps-text-transform;
}

@mixin one-hulu-subtitle-10-caps {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-subtitle-10-caps-font-size;
  font-weight: $font-subtitle-10-caps-font-weight;
  letter-spacing: $font-subtitle-10-caps-letter-spacing;
  line-height: $font-subtitle-10-caps-line-height;
  text-transform: $font-subtitle-10-caps-text-transform;
}

/*** Eyebrow styles ***/
@mixin one-hulu-eyebrow-18 {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-eyebrow-18-font-size;
  font-weight: $font-eyebrow-18-font-weight;
  letter-spacing: $font-eyebrow-18-letter-spacing;
  line-height: $font-eyebrow-18-line-height;
  text-transform: $font-eyebrow-18-text-transform;
}

@mixin one-hulu-eyebrow-16 {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-eyebrow-16-font-size;
  font-weight: $font-eyebrow-16-font-weight;
  letter-spacing: $font-eyebrow-16-letter-spacing;
  line-height: $font-eyebrow-16-line-height;
  text-transform: $font-eyebrow-16-text-transform;
}

@mixin one-hulu-eyebrow-14 {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-eyebrow-14-font-size;
  font-weight: $font-eyebrow-14-font-weight;
  letter-spacing: $font-eyebrow-14-letter-spacing;
  line-height: $font-eyebrow-14-line-height;
  text-transform: $font-eyebrow-14-text-transform;
}

@mixin one-hulu-eyebrow-12 {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-eyebrow-12-font-size;
  font-weight: $font-eyebrow-12-font-weight;
  letter-spacing: $font-eyebrow-12-letter-spacing;
  line-height: $font-eyebrow-12-line-height;
  text-transform: $font-eyebrow-12-text-transform;
}

@mixin one-hulu-eyebrow-10 {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-eyebrow-10-font-size;
  font-weight: $font-eyebrow-10-font-weight;
  letter-spacing: $font-eyebrow-10-letter-spacing;
  line-height: $font-eyebrow-10-line-height;
  text-transform: $font-eyebrow-10-text-transform;
}

/*** Label styles ***/
@mixin one-hulu-label-18 {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-label-18-font-size;
  font-weight: $font-label-18-font-weight;
  letter-spacing: $font-label-18-letter-spacing;
  line-height: $font-label-18-line-height;
  text-transform: $font-label-18-text-transform;
}

@mixin one-hulu-label-16 {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-label-16-font-size;
  font-weight: $font-label-16-font-weight;
  letter-spacing: $font-label-16-letter-spacing;
  line-height: $font-label-16-line-height;
  text-transform: $font-label-16-text-transform;
}

@mixin one-hulu-label-14 {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-label-14-font-size;
  font-weight: $font-label-14-font-weight;
  letter-spacing: $font-label-14-letter-spacing;
  line-height: $font-label-14-line-height;
  text-transform: $font-label-14-text-transform;
}

@mixin one-hulu-label-12 {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-label-12-font-size;
  font-weight: $font-label-12-font-weight;
  letter-spacing: $font-label-12-letter-spacing;
  line-height: $font-label-12-line-height;
  text-transform: $font-label-12-text-transform;
}

@mixin one-hulu-label-10 {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-label-10-font-size;
  font-weight: $font-label-10-font-weight;
  letter-spacing: $font-label-10-letter-spacing;
  line-height: $font-label-10-line-height;
  text-transform: $font-label-10-text-transform;
}

/*** Body styles ***/
@mixin one-hulu-body-24 {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-body-24-font-size;
  font-weight: $font-body-24-font-weight;
  letter-spacing: $font-body-24-letter-spacing;
  line-height: $font-body-24-line-height;
  text-transform: none;
}

@mixin one-hulu-body-18 {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-body-18-font-size;
  font-weight: $font-body-18-font-weight;
  letter-spacing: $font-body-18-letter-spacing;
  line-height: $font-body-18-line-height;
  text-transform: none;
}

@mixin one-hulu-body-16 {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-body-16-font-size;
  font-weight: $font-body-16-font-weight;
  letter-spacing: $font-body-16-letter-spacing;
  line-height: $font-body-16-line-height;
  text-transform: none;
}

@mixin one-hulu-body-14 {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-body-14-font-size;
  font-weight: $font-body-14-font-weight;
  letter-spacing: $font-body-14-letter-spacing;
  line-height: $font-body-14-line-height;
  text-transform: none;
}

@mixin one-hulu-body-12 {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-body-12-font-size;
  font-weight: $font-body-12-font-weight;
  letter-spacing: $font-body-12-letter-spacing;
  line-height: $font-body-12-line-height;
  text-transform: none;
}

@mixin one-hulu-body-10 {
  font-family: 'Graphik Web', 'Graphik', sans-serif;
  font-size: $font-body-10-font-size;
  font-weight: $font-body-10-font-weight;
  letter-spacing: $font-body-10-letter-spacing;
  line-height: $font-body-10-line-height;
  text-transform: none;
}
