@import '@hulu/web-ui/generated/_variables.scss';

.Dropdown {
  width: 288px;
  background: $color-background-base;
  color: $color-base-black;
  border-radius: 4px;
  position: relative;

  &__button {
    border: none;
    outline: none;
    border-radius: 4px;
    padding: 0 16px;
    height: 48px;
    display: block;
    width: 100%;
    text-align: left;
    background: $color-base-gray-300;
    color: $color-base-black;
    cursor: pointer;

    svg {
      float: right;
      margin-top: 2px;
    }

    &.isOpen {
      border-radius: 4px 4px 0 0;
    }

    &:hover, &:focus, &:active {
      background: rgba(0, 0, 0, 0.2);
    }
  }

  &__menu {
    position: absolute;
    z-index: 1;
    background: $color-background-base;
    list-style: none;
    padding: 0;
    margin: 0;
    text-indent: 0;
    width: 288px;
    box-shadow: 0px 7px 6px rgba(0, 0, 0, 0.7);
    max-height: 330px; // enough to show 6 results
    overflow: auto;

    &-item {
      margin: 6px 8px;
      border-radius: 4px;

      &.active {
        background: rgba(0, 0, 0, 0.1);
      }

      button {
        outline: none;
        background: none;
        display: block;
        border: none;
        padding: 0;
        color: $color-base-black;
        width: 100%;
        text-align: left;
        padding: 14px 8px;
        cursor: pointer;

        &:hover,
        &:focus,
        &:active {
          background: rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}
