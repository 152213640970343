@import '../../../styles/breakpoints';
@import '../../../styles/mixins';
@import '../../../styles/colors';

.value-prop--item {
  -ms-flex: 1;
  flex: 1;
  max-width: 512px;
  height: auto;
  color: $gray-6;
  margin: 0 auto 2.5em;
  text-align: center;

  &.align-left {
    @include media-breakpoint-up(lg) {
      text-align: left;
    }
  }

  @include media-breakpoint-down(sm) {
    display: block;
  }

  @include media-breakpoint-up(md) {
    -ms-display: flex;
    display: flex;

    -ms-justify-content: space-between;
    justify-content: space-between;
    flex-direction: column;
  }

  @include media-breakpoint-up(lg) {
    display: block;
    margin: 0 1.5em 2em;
  }

  @include media-breakpoint-up(xl) {
    -ms-display: flex;
    display: flex;
  }
}

.value-prop--item-image {
  margin-bottom: 1em;

  @include media-breakpoint-up(md) {
    -ms-display: flex;
    -ms-align-items: center;
    -ms-justify-content: space-between;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.value-prop--item-details {
  -ms-flex-grow: 3;
  flex-grow: 3;
}

.value-prop--icon {
  margin: auto;
  @include lazyLoad(50px);
}

.align-left {
  .value-prop--icon {
    margin: 0;
  }
}

.value-prop--eyebrow {
  display: block;
  margin-bottom: 0.5em;
  @include one-hulu-eyebrow-14;
}

.value-prop--headline {
  margin-bottom: 0.5em;
  margin-top: 4px;
  @include one-hulu-title-24;
}

.value-prop--body-copy {
  margin: 0 0 1.25em;
  margin-top: 26px;
  color: $text-body;
  @include one-hulu-body-16;

  @include media-breakpoint-down(sm) {
    margin-top: 0;
  }

  @include media-breakpoint-up(md) {
    margin-top: 0;
  }
}

.value-prop--link-option {
  margin-top: 26px;
  color: $color-base-blue;
  cursor: pointer;
  @include one-hulu-body-14;
  font-weight: bold;

  @include media-breakpoint-down(sm) {
    margin-top: 20px;
  }

  @include media-breakpoint-up(md) {
    margin-top: 20px;
  }
}