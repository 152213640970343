@import '../../../styles/breakpoints';
@import '../../../styles/mixins';
@import '../../../styles/colors';

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  @include z-index(top);
  overflow: auto;

  .modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    @include z-index(higher);
    background-color: $black;
    opacity: 0;
    transition: opacity 0.3s linear;
  }

  &.modal-show {
    display: block;
    opacity: 1;
    animation: 'fade-in' 0.15s linear forwards;

    .modal-backdrop {
      opacity: 0.5;
    }

    &.modal-closing {
      animation: 'fade-out' 0.15s ease-out forwards;
    }
  }

  &.modal-hide {
    display: none;
  }
}

.modal-dialog {
  width: auto;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: $white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: 0;
  @include z-index(higher);

  @include media-breakpoint-up(md) {
    width: 80%;
    max-width: 930px;
    margin: 80px auto;
  }

  @include media-breakpoint-down(sm) {
    min-height: 100vh;
  }
  
  .modal-dialog__title {
    text-align: center;
  }

  &--with-automated-collection-image {
    margin: 0 auto 80px auto;
  }


  /* Defines default dialog display styling */
  &.modal-dialog__default-display {
    &.modal-dialog__size-small {
      @include media-breakpoint-up(md) {
        max-width: 600px;
      }
    }

    .modal-dialog__title {
      padding: 0px 20px;
    }

    .modal-dialog__body,
    .modal-dialog__footer {
      margin: 24px 0 32px;
    }

    .modal-dialog__actions {
      margin: 24px 0 32px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      button {
        width: 327px;
      }
    }
  }
}

.modal--close {
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: none;
  -webkit-appearance: none;
  // Safari Mobile agents will add padding to buttons
  padding: 0;
}

.modal--header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: flex-end;
  padding: 32px 32px 0;

  @include media-breakpoint-down(md) {
    padding: 20px 20px 0;
  }
}

.modal--footer {
  padding-top: 20px;
  padding-bottom: 20px;
}

.modal_title--header {
  border-bottom: 1px solid $gray-2;
  color: $white;
  width: 100%;
  z-index: 61;

  @include media-breakpoint-down(md) {
    padding: 0 20px 20px;
  }

  &-with-title {
    margin-top: -66px;
  }

  &--scrolling {
    background: $gray-1;
    border-bottom: none;
  }

  .modal_title--header__text {
    margin: 0 auto;
    padding: 25px 0;
    text-align: center;
    user-select: none;
  }
}

.modal--body {
  transition: opacity 0.15s linear;
  padding: 0 20px 20px;

  @include media-breakpoint-up(md) {
    padding: 0 60px;
  }

  &-with-title {
    margin-top: -66px;
  }
  &--with-automated-collection-image {
    position: absolute;
    right: 0;
    left: 0;
  }
}

.modal-dialog__footer {
  @include one-hulu-body-16;
}
