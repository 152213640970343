@import '../../styles/colors';
@import '@hulu/web-ui/generated/_variables-dark.scss';

.toggle-switch {
  &__checkbox {
    position: absolute;
    height: 0;
    width: 0;
    visibility: hidden;
    &:checked + .toggle-switch__label .toggle-switch__button {
      left: calc(100% + 1px);
      transform: translateX(-100%);
    }
    &:checked + .toggle-switch__label {
      background: $color-base-green-primary;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    height: 30px;
    border-radius: 50px;
    position: relative;
    transition: background-color 0.2s;
    width: 56px;
    background: $color-base-gray-500;
  }

  &__button {
    position: absolute;
    top: -1px;
    left: -1px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    transition: 0.2s;
    background: $white;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  }
}
