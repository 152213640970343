@import "../../../styles/colors";
@import "../../../styles/breakpoints";
@import "../../../styles/mixins";

.TabContent {
  &__container {
    transform: translate3d(0, 0, 0);
    transition: all 0.6s ease;
    order: 1;
    @include media-breakpoint-up(md) {
      padding-top: 60px;
    }
    @include media-breakpoint-up(lg) {
      padding-top: 0;
    }
    &.--inactive {
      position: absolute;
      height: 0px;
      width: 0px;
      opacity: 0;
      transform: translate3d(0, 20px, 0);
      transition: none;
      top: 0;
      z-index: -2;
    }
  }

  &__headline {
    font-weight: bold;
    color: $white;
    margin-top: 80px;
    text-align: center;
    @include media-breakpoint-up(md) {
      margin-top: 40px;
    }
    @include media-breakpoint-up(lg) {
      text-align: left;
      margin-top: 25px;
      max-width: 650px;
    }

    &.--raised {
      margin-top: 40px;
      @include media-breakpoint-up(md) {
        margin-top: 20px;
      }
      @include media-breakpoint-up(lg) {
        margin-top: 25px;
      }
    }
  }

  &__subheadline {
    color: $white;
    max-width: 550px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 4px;
    @include media-breakpoint-up(lg) {
      max-width: 450px;
      margin-top: 18px;
      text-align: left;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
    }
    @include media-breakpoint-up(xl) {
      max-width: 500px;
    }
    @include media-breakpoint-up(xxl) {
      max-width: 550px;
    }
  }

  &__logoRow {
    display: flex;
    justify-content: center;
    height: 53px;
    flex-wrap: wrap;
    width: 350px;
    height: 100%;
    margin: 27px auto 0 auto;
    @include media-breakpoint-up(lg) {
      justify-content: flex-start;
      margin: 20px 0 0 0;
    }
  }

  &__logo {
    margin: 0 10.5px 20px 10.5px;
    width: 55px;
    height: 55px;
    @include media-breakpoint-up(lg) {
      margin: 0 20px 20px 0;

    }
  }

  &__disclaimer {
    text-align: center;
    margin: 10px 50px 0 50px;

    @include media-breakpoint-up(lg) {
      margin: 10px 0 0 0;
      text-align: left;
    }
  }
}

.logo-wall--bug img {
  min-height: 0px !important;
}