@import '../../../styles/breakpoints';
@import '../../../styles/mixins';
@import '../../../styles/colors';

.container {
  width: 100%;
  position: relative;
}

.right-aligned {
  @include media-breakpoint-up(lg) {
    flex-direction: row-reverse;
  }

  .cta--column--texts {
    text-align: left;

    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }

  .cta--image,
  .cta--column--actions,
  .cta--column--actions-with-input {
    justify-content: flex-start;

    @include media-breakpoint-down(md) {
      justify-content: center;
    }
  }

  &.call-to-action--with-image {
    .cta--column--actions-with-input {
      justify-content: flex-end;

      @include media-breakpoint-down(md) {
        justify-content: center;
      }

      .cta--column--actions {
        text-align: left;
      }
    }
  }
}

.center-aligned {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  * {
    max-width: 700px;
    text-align: center;
    align-self: center;
  }

  .cta {

    &__action-container,
    &__disclaimer {
      text-align: center;
    }

    &--column--texts {
      @include media-breakpoint-down(md) {
        align-self: center !important;
      }
    }
  }
}

.hide-body-md {
  .cta--body {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

.call-to-action-container {
  .cta {
    &__action-container {
      text-align: left;

      .cta__disclaimer {
        margin-top: 8px !important;
        margin-left: 0 !important;
      }
    }

    &__disclaimer {
      @include one-hulu-body-10;
      color: $text-body;
      opacity: 0.6;
      margin-top: 10px;
      line-height: 16px;

      @include media-breakpoint-down(md) {
        text-align: center;
      }

      a {
        color: $text-body;
        text-decoration: underline;
      }
    }

    &--headline {
      margin-bottom: 16px;
      @include one-hulu-title-32;
    }

    &--body {
      color: $text-body;
      margin-top: 0;
      margin-bottom: 1rem;
      @include one-hulu-body-16;
    }

    &--column--texts {
      margin-top: auto;
      margin-bottom: auto;
      align-self: center;

      @include media-breakpoint-down(md) {
        justify-content: center;
        align-self: auto;
      }
    }

    &--image {
      text-align: left;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: auto;
        object-fit: scale-down;

        max-width: 536px;
        max-height: 422px;
        @include lazyLoad(422px);

        @include media-breakpoint-down(xl) {
          max-width: 496px;
          max-height: 422px;
        }

        @include media-breakpoint-down(lg) {
          max-width: 376px;
          max-height: 422px;
        }

        @include media-breakpoint-down(md) {
          max-width: 688px;
          max-height: 300px;
        }

        @include media-breakpoint-down(sm) {
          max-width: 280px;
          max-height: 256px;
        }
      }

      @include media-breakpoint-down(md) {
        margin-top: 25px;
        justify-content: center;
        align-items: flex-end;
      }
    }

    &--code-input {
      display: inline-block;
      width: 260px;
      height: 56px;
      padding: 0 1em;
      margin-right: 5px;
      background: $white;
      border: none;
      text-align: left;
      width: 100%;
      height: 48px;
      @include one-hulu-body-16;

      &::-ms-clear {
        width: 0;
        height: 0;
      }
    }

    &--code-input-wrapper {
      position: relative;
      display: inline-block;
      width: 392px;

      @include media-breakpoint-down(lg) {
        width: 336px;
      }

      @include media-breakpoint-down(sm) {
        display: block;
        margin-top: 10px;
      }
    }

    &--invalid-code-icon {
      height: 28px;
      width: 28px !important;
      box-sizing: border-box;
      border: 2px solid $color-base-red;
      color: $color-base-red;
      border-radius: 14px;
      text-align: center;
      position: absolute;
      right: 20px;
      top: 10px;
      visibility: hidden;
      @include one-hulu-body-18;
    }

    &--code-input--invalid {
      .cta--invalid-code-icon {
        visibility: visible;
      }

      .cta--code-input {
        color: $color-base-red;
      }
    }

    &--column--actions {
      text-align: right;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      *:not(:first-child) {
        margin-top: 0px;
        margin-left: 8px;
      }

      @include media-breakpoint-down(md) {
        justify-content: center;
      }

      @include media-breakpoint-down(sm) {
        flex-direction: column;

        *:not(:first-child) {
          margin-left: 0px;
          margin-top: 8px;
        }

        * {
          width: 336px;
        }
      }

      @include media-breakpoint-down(xs) {
        * {
          width: 100%;
        }
      }
    }

    &--column--actions-with-input {
      text-align: right;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      *:not(:first-child) {
        margin-top: 0px;
        margin-left: 8px;
      }

      @include media-breakpoint-down(md) {
        flex-direction: column;

        *:not(:first-child) {
          margin-left: 0px;
          margin-top: 8px;
        }

        * {
          width: 336px;
        }
      }

      @include media-breakpoint-down(xs) {
        * {
          width: 100%;
        }
      }
    }
  }

  .call-to-action {
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    flex-wrap: wrap;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      text-align: center;
      align-items: stretch;
    }
  }

  .call-to-action--with-image {
    padding-bottom: 0px;

    .cta--column--actions {
      justify-content: flex-start;

      @include media-breakpoint-down(md) {
        justify-content: center;
      }
    }

    .cta--column--actions-with-input {
      justify-content: flex-start;

      @include media-breakpoint-down(md) {
        justify-content: center;
      }
    }
  }
}

// Dark theme CTA
.dark {
  &.call-to-action-container {
    background-color: $gray-6;

    div,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $text-white;
    }
  }

  .cta--headline,
  .cta--body,
  .cta__disclaimer,
  .cta__disclaimer a {
    color: $text-white;
  }

  .cta--code-input--invalid .cta--invalid-code-icon {
    color: $color-base-red;
  }
}

.brandlp {
  &.call-to-action-container {
    background-color: $green;

    .section-headline {
      @include one-hulu-title-40;
    }
  }
}