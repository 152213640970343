@mixin tile-highlight-border-on-hover-and-active(
  $border-color: $color-base-gray-700,
  $border-radius: $component-tile-vessel-border-radius * 1px,
  $stroke-width: $component-tile-vessel-stroke-width * 1px
) {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: $stroke-width * -2;
    bottom: $stroke-width * -2;
    left: $stroke-width * -2;
    right: $stroke-width * -2;
    border: $stroke-width solid $border-color;
    border-radius: $border-radius;
    opacity: 0;
    transition: opacity 400ms cubic-bezier(0, 0, 0, 1);
  }

  &:hover {
    &::before {
      opacity: 1;
      transition: none;
    }
  }

  &:active {
    &::before {
      opacity: 0.5;
      transition: none;
    }
  }
}

@mixin cover {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}