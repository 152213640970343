@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/mixins';

.ConnectedLoginContainer {
  @include one-hulu-body-24;
  padding-top: 110px;
  padding-bottom: 40px;
  text-align: left;

  .row {
    margin-left: 0;
    margin-right: 0;

    @include media-breakpoint-down(sm) {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
  }

  .section-headline {
    @include one-hulu-title-48;
    margin: 0;
  }

  a {
    color: $text-link-2;
  }

  img {
    width: 100%;
    height: auto;
    flex-shrink: 0;
    @include lazyLoad(441px);
  }

  &__description {
    margin-bottom: 1.5rem;
  }
  &__steps {
    list-style: none;
    padding: 0;

    .ConnectedLoginStep {
      margin-bottom: 1.5rem;

      .QRCodeContainer {
        max-width: 160px;
        max-height: 160px;
        margin-top: 1rem;
        margin-bottom: 2.5rem;
      }
    }
  }

  &__footnote {
    @include one-hulu-body-10;
  }

  @include media-breakpoint-down(xl) {
    & {
      @include one-hulu-body-18;
      padding-top: 120px;

      .section-headline {
        @include one-hulu-title-40;
      }
    }

    &__text {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 32px;
      margin-top: 32px;
    }
  }

  @include media-breakpoint-down(md) {
    & {
      padding-top: 100px;
    }
  }

  @include media-breakpoint-down(sm) {
    & {
      @include one-hulu-body-14;
      padding-top: 104px;

      .section-headline {
        @include one-hulu-title-18;
      }
    }

    &__text {
      order: 2;
    }

    &__image {
      order: 1;

    }
  }

  &__image {
    padding-left: 3em;
    padding-right: 3em;
  }

  &__text, &__image {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.theme__dark {
  .ConnectedLoginContainer {
    .section-headline {
      color: $white;
    }
    .ConnectedLoginContainer__text {
      color: $white;
    }
  }
}
