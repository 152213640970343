@import '../../../styles/breakpoints';
@import '../../../styles/colors';

.CollectionsModal.modal-dialog {
  background-color: $black;

  @include media-breakpoint-up(md) {
    max-width: none !important;
  }

  @include media-breakpoint-up(xxl) {
    max-width: 1440px !important;
  }

  .modal--body {
    background-color: $black;
    padding: 0;
  }

  .modal__content {
    display: flex;
    position: relative;
    height: 420px;

    @include media-breakpoint-up(md) {
      height: 430px;
    }

    @include media-breakpoint-up(lg) {
      height: 809px;
      overflow: hidden;
    }

    .MastheadVideo__video {
      height: 177.77vw;
      min-height: auto;
      opacity: 1;
      width: 100vw;

      @include media-breakpoint-up(md) {
        width: 100vw;
        height: 56.25vw;
      }

      @include media-breakpoint-up(lg) {
        height: 809px;
        width: 1440px;
        min-width: auto;
        min-height: auto;
        max-width: none;
      }
    }

    .modal__copy {
      align-items: flex-start;
      color: $white;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 95px;
      width: 100%;
      z-index: 1;
      padding: 0 20px 40px;

      @include media-breakpoint-up(md) {
        padding: 40px;
      }

      @include media-breakpoint-up(lg) {
        padding: 0 80px;
        justify-content: center;
      }

      &--title {
        color: $white;
      }

      &--description {
        margin-top: 5px;

        @include media-breakpoint-up(md) {
          margin-top: 0;
        }

        @include media-breakpoint-up(lg) {
          width: 606px;
        }

        &--automation {
          flex: 5 1 auto;
        }
      }

      &--brand-logo {
        width: 75px;
        &--automation {
          flex: 1;
          padding-left: 20px;
        }
      }

      &--automation {
        padding-top: 420px;

        @include media-breakpoint-up(lg) {
          padding-top: 300px;
        }
      }

      &--flex-box--automation {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }

    .modal__scrim {
      opacity: 2.5;
      background-image: linear-gradient(5deg, rgb(0, 0, 0) 0%, transparent 50%),
        radial-gradient(
          at right top,
          rgba(0, 0, 0, 0.5) 0%,
          rgba(0, 0, 0, 0) 20%
        );
      position: absolute;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
    }
  }

  .modal__collections {
    background-color: $black;

    @include media-breakpoint-up(lg) {
      background-color: transparent;
      bottom: 200px;
      position: relative;
    }

    .NonSubEditorialCollection {
      background-color: transparent;
      padding: 0;

      @include media-breakpoint-up(lg) {
        padding: 0 25px;
      }

      @include media-breakpoint-up(xl) {
        padding: 0 40px;
      }
    }

    .NonSubSimpleCollection {
      background-color: transparent;
      padding-top: 0;

      @include media-breakpoint-up(lg) {
        padding-top: 40px;
      }

      .SimpleCollection__title {
        color: $white;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 15px;
        font-weight: 600;

        @include media-breakpoint-up(md) {
          font-size: 18px;
          font-weight: 800;
        }
      }

      .Slider--tile {
        .Slider__item {
          @include media-breakpoint-up(lg) {
            flex: 0 0 (100% / 4);
          }

          .Tile__title {
            color: $white;
            font-size: 16px;
            line-height: 24px;

            @include media-breakpoint-up(md) {
              font-size: 18px;
              font-weight: 800;
              line-height: 20px;
            }
          }

          .Tile__description {
            color: $white;
            font-size: 12px;
            line-height: 18px;

            @include media-breakpoint-up(md) {
              font-size: 14px;
              line-height: 21px;
            }
          }
        }

        .Slider__button {
          outline: 0;

          @include media-breakpoint-up(lg) {
            margin-top: calc((50% * (9 / 16) / 4) - (24px / 4));
          }
        }

        .Slider__see-more-button {
          color: $white;
          background: $black;
          margin-top: 0;
          margin-bottom: 30px;
        }
      }
    }
  }

  .modal--footer {
    background-color: $black;
  }

  .modal--close {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1001;
  }

  .modal--close svg {
    width: 16px;
  }

  .modal--close svg #NSX-Modal-X {
    stroke: $white;
  }
}
