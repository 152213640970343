@import '../../../styles/breakpoints';
@import '../../../styles/mixins';
@import '../../../styles/global';

.plan-card {
  flex: 1 1 0;
  background-color: $white;
  box-shadow: 0 2px 10px 0 rgba(134, 141, 155, 0.2);
  margin: 0 12px;
  max-width: 536px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  @include media-breakpoint-down(md) {
    flex: 1 1 auto;
    margin: 20px 8px;
    width: 100%;
    box-sizing: border-box;
  }
  @include media-breakpoint-down(sm) {
    margin: 20px auto;
  }
  @media (min-width: 768px) and (max-width: 878px) {
    width: 32%;
  }

  @include media-breakpoint-up(lg) {
    width: 648px;
  }

  &--highlight,
  &--one-up {
    transform: translateY(-32px);
    box-shadow: 0 20px 24px 0 rgba(134, 141, 155, 0.2);
    .plan-card__border {
      width: 100%;
    }
    @include media-breakpoint-down(sm) {
      transform: none;
    }
  }

  &--one-up {
    transform: none;

    @include media-breakpoint-up(lg) {
      max-width: 648px;
    }
  }

  &__border {
    height: 3px;
    width: 0%;
    background: linear-gradient(
      90deg,
      #05aaa3 0%,
      $green3 56.73%,
      #92ed8d 100%
    );
    transition: width 0.3s ease-in-out;
  }

  &__wrapper {
    position: relative;
    height: 100%;

    &--one-up {
      padding-bottom: 0;

      .plan-card {
        &__header {
          padding: 40px 24px;
        }

        &__mobile-wrapper {
          @include media-breakpoint-down(sm) {
            &--open {
              max-height: none;
              bottom: 0;
            }

            .plan-card__addons {
              margin-left: 36px;
            }
          }

          @include media-breakpoint-up(md) {
            .plan-card__list {
              padding: 32px 85px;
            }

            .plan-card__addons {
              margin-left: 38px;
            }
          }

          @include media-breakpoint-up(lg) {
            .plan-card__list {
              padding: 32px 100px;
            }

            .plan-card__addons {
              margin-left: 55px;
            }
          }
        }

        &__expanded-section--open {
          bottom: 0;
        }
      }
    }
  }

  &__label {
    display: inline-block;
    border-radius: 100px;
    margin-bottom: 16px;
    padding: 8.5px 27px 10.5px;
    background: $color-base-green-secondary;

    p {
      letter-spacing: 1px;
      margin: 2px auto 0;
      color: $color-base-gray-1300;
      @include one-hulu-eyebrow-12;

      @include media-breakpoint-down(lg) {
        margin-top: 0;
      }
    }
    @include media-breakpoint-down(lg) {
      margin-bottom: 20px;
    }
  }

  &__header {
    text-align: center;
    padding: 40px 20px;
    @include media-breakpoint-down(sm) {
      padding: 40px 24px 106px;
    }

    > p {
      margin: 8px auto 0;
      color: $text-body;
      @include one-hulu-body-24;
      @include media-breakpoint-down(lg) {
        @include one-hulu-body-16;
      }
    }
    > h3 {
      text-transform: uppercase;
      color: $text-body;
      margin: 0;
    }
  }

  &__priceline {
    p {
      display: inline-block;
      color: $text-body;
      margin: 24px 0 0;
      @include media-breakpoint-down(md) {
        margin: 8px 0 0;
      }
    }
  }

  &__amount {
    margin: 0 auto;
  }

  &__expander {
    position: absolute;
    bottom: 0;
    height: 66px;
    margin: 0 auto 0;
    left: 0;
    right: 0;
    border-top: 1px solid $accent-grey;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__button-wrapper {
    text-align: center;
    margin: 15px auto 0;
    width: 100%;
    max-width: 344px;
    .button--cta {
      width: 100%;
      max-width: 344px;
    }
  }

  &__sublink {
    text-align: center;
    @include one-hulu-body-14;
    color: $gray-28;
    margin-top: 5px;

    @include media-breakpoint-down(md) {
      @include one-hulu-body-12;
    }

    p {
      @include one-hulu-body-14;
      @include media-breakpoint-down(md) {
        @include one-hulu-body-10;
      }
    }
    a {
      color: $text-link-3;
    }
  }

  &__list {
    background-color: $text-white;
    border-top: 1px solid $accent-grey;
    padding: 32px 50px 98px;
    ul {
      list-style: none;
      margin: 0;
    }
    li {
      padding: 12px 0;
      color: $plan-card-text;
    }
    @include media-breakpoint-down(md) {
      padding: 32px 30px 98px;
    }
    @include media-breakpoint-down(sm) {
      padding: 32px 40px;
    }
    @include media-breakpoint-down(xs) {
      padding: 32px 20px;
    }
  }

  &__list-check {
    background: url('/static/hitch/static/icons/Pricing_Checkmark_green-light.svg');
    display: inline-block;
    height: 18px;
    width: 18px;
    margin: auto 28px -4px -46px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__expanded-heading {
    background-color: $text-white;
    margin-bottom: 40px;
    border-bottom: 1px solid $accent-grey;
    color: $text-body;
    text-align: center;
  }

  &__expander {
    background-color: $text-white;
  }

  &__expanded-section {
    bottom: 66px;
    display: block;
    padding: 0 80px;
    background-color: $text-white;
    max-height: 0;
    overflow: hidden;
    position: relative;
    transition: max-height 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
    @include media-breakpoint-down(lg) {
      padding: 0 30px;
    }
    @include media-breakpoint-down(sm) {
      position: static;
    }
    &--open {
      max-height: 650px;
      transition: max-height 0.5s ease-in;
      @include media-breakpoint-down(lg) {
        padding: 0 30px;
      }
    }
    @include media-breakpoint-down(sm) {
      max-height: none;
      overflow: auto;
      padding: 0px 40px;
    }
    @include media-breakpoint-down(xs) {
      padding: 0 20px;
    }
  }

  &__expand-button {
    @extend %base-button-link;
    color: $gray-29;
    cursor: pointer;
    text-decoration: none;
    @include one-hulu-body-18;
    &--open {
      .plan-card__icon-plus {
        transform: scaleY(-1);
      }
    }
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__mobile-expand-button {
    @extend .plan-card__expand-button;
    display: none;
    cursor: pointer;
    &--open {
      .plan-card__icon-plus-mobile {
        transform: scaleY(-1);
      }
    }
    @include media-breakpoint-down(sm) {
      display: inline-block;
    }
  }

  &__addons {
    ul {
      list-style-type: none;
      padding: 0;
    }
    li {
      color: $gray-29;
      margin: 20px 0;
      &:last-of-type {
        margin-bottom: 35px;
      }
    }
    @include media-breakpoint-up(lg) {
      margin-left: 20px;
    }
  }

  &__mobile-wrapper {
    @include media-breakpoint-down(sm) {
      bottom: 66px;
      max-height: 0;
      overflow: hidden;
      position: relative;
      transition: max-height 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
      &--open {
        max-height: 1500px;
        transition: max-height 0.5s ease-in;
      }
    }
  }

  &__icon-plus,
  &__icon-plus-mobile {
    background: url('/static/hitch/static/icons/arrow-caret.svg') no-repeat
      center center/cover;
    height: 7px;
    width: 12px;
    display: inline-block;
    vertical-align: middle;
    transition: transform 0.7s ease-in-out;
  }

  &__tooltip {
    background: url('/static/hitch/static/icons/tooltip.svg') no-repeat;
    display: inline-block;
    height: 18px;
    width: 18px;
    vertical-align: middle;
    padding-bottom: 20px;
  }
}
