@import '../../../styles/variables';
@import '../../../styles/breakpoints';
@import '../../../styles/colors';

.ListCardItem {
  align-content: flex-start;
  align-items: center;
  color: $gray-4;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 23px;

  &__item {
    align-items: center;
    display: flex;
    flex: 0 0 100%;
    font-size: 16px;
    line-height: 24px;
    min-height: 48px;

    @include media-breakpoint-up(md) {
      flex: 0 0 (100% / 3);
      line-height: 20px;
      min-height: 50px;
      padding-right: 12px;
    }

    @include media-breakpoint-up(lg) {
      align-items: center;
      display: flex;
      flex: 0 0 (100% / 5);
    }

    & span {
      color: $gray-1;

      &:hover {
        color: $gray-4;
      }
    }
  }
}