/**
 * this file to have its contents put into site-styles whenever convenient.
 * because variables may be missing and constantly in flux, this file serves
 * as a buffer until things are settled enough to move everything into
 * site-styles in one go
 */

@import '~@hulu/site-styles/variables';
@import '~@hulu/site-styles/colors';
@import '~@hulu/site-styles/typography';
@import '~@hulu/site-styles/screen_xl_variables';
@import './variables';

/* stylelint-disable color-no-hex */
$collection-border: 1px solid $gray-6;
$transparent-black: rgba($black, 0.4);
$transparent-white: rgba($white, 0.4);
$recorded-red: #da2001;
$hulu-scrim: linear-gradient(0deg, rgba(30, 30, 30, 0.24), transparent);
$thumbnail-scrim: linear-gradient(345deg, rgba(30, 30, 30, 0.2), transparent 32%);
$transparent-gray: rgba(174, 180, 191, 0.15);
$badge-gray-background: rgba($gray-1, 0.6);
$badge-border: rgba(235, 237, 242, 1);
$slider-button-shadow: rgba(94, 101, 115, 0.2);
$tile-transparent-white: rgba(255, 255, 255, 0.7);

@mixin collection-spacing {
  margin-top: $collection-spacing-sm;
  border-bottom: $collection-border;

  @media (min-width: $screen-md-min) {
    margin-top: $collection-spacing-md;
  }

  @media (min-width: $screen-xl-min) {
    margin-top: $collection-spacing-lg;
  }
}
