// IMPORTANT: Only place functions here, otherwise those classes will be duplicated in other scss which import this.

$grid-breakpoints: (
    xs: 0,
    sm: 440px,
    md: 768px,
    lg: 1024px,
    xl: 1280px,
    xxl: 1600px
) !default;

// Breakpoint viewport sizes and media queries.
//
// Breakpoints are defined as a map of (name: minimum width), order from small to large:
//
//    (xs: 0, sm: 576px, md: 768px)
//
// The map defined in the `$grid-breakpoints` global variable is used as the `$breakpoints` argument by default.

// Name of the next breakpoint, or null for the last breakpoint.
//
//    >> breakpoint-next(sm)
//    md
//    >> breakpoint-next(sm, (xs: 0, sm: 576px, md: 768px))
//    md
//    >> breakpoint-next(sm, $breakpoint-names: (xs sm md))
//    md
@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
    $n: index($breakpoint-names, $name);
    @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
  }

  // Minimum breakpoint width. Null for the smallest (first) breakpoint.
  //
  //    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px))
  //    576px
  @function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
    $min: map-get($breakpoints, $name);
    @return if($min != 0, $min, null);
  }

  // Maximum breakpoint width. Null for the largest (last) breakpoint.
  // The maximum value is calculated as the minimum of the next one less 0.1.
  //
  //    >> breakpoint-max(sm, (xs: 0, sm: 576px, md: 768px))
  //    767px
  @function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
    $next: breakpoint-next($name, $breakpoints);
    @return if($next, breakpoint-min($next, $breakpoints) - 1px, null);
  }

  // Returns a blank string if smallest breakpoint, otherwise returns the name with a dash infront.
  // Useful for making responsive utilities.
  //
  //    >> breakpoint-infix(xs, (xs: 0, sm: 576px, md: 768px))
  //    ""  (Returns a blank string)
  //    >> breakpoint-infix(sm, (xs: 0, sm: 576px, md: 768px))
  //    "-sm"
  @function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
    @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
  }

  // Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
  // Makes the @content apply to the given breakpoint and wider.
  @mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    @if $min {
      @media (min-width: $min) {
        @content;
      }
    } @else {
      @content;
    }
  }

  // Media of at most the maximum breakpoint width. No query for the largest breakpoint.
  // Makes the @content apply to the given breakpoint and narrower.
  @mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
    $max: breakpoint-max($name, $breakpoints);
    @if $max {
      @media (max-width: $max) {
        @content;
      }
    } @else {
      @content;
    }
  }

  // Media that spans multiple breakpoint widths.
  // Makes the @content apply between the min and max breakpoints
  @mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
    @include media-breakpoint-up($lower, $breakpoints) {
      @include media-breakpoint-down($upper, $breakpoints) {
        @content;
      }
    }
  }

  // Media between the breakpoint's minimum and maximum widths.
  // No minimum for the smallest breakpoint, and no maximum for the largest one.
  // Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
  @mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
    @include media-breakpoint-between($name, $name, $breakpoints) {
      @content;
    }
  }
