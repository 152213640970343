@import '../../../../styles/breakpoints';

.TileSlider {
  .Slider--tile {
    .Slider {
      @include media-breakpoint-down(sm) {
        &__item {
          flex: 0 0 50%;
        }
        &__controls {
          margin-top: calc((80% * (9 / 16) / 3) - (24px / 3));
        }
      }
    }
  }
}
