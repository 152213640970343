@import '../../../../styles/breakpoints';

.ImageBackground {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-position: center top;
  background-size: cover;
}

@include media-breakpoint-only(xs) {
  .bg-center-sm,
  .bg-center-top-sm {
    .ImageBackground {
      background-position: center top;
    }
  }
  .bg-right-sm,
  .bg-right-top-sm {
    .ImageBackground {
      background-position: right top;
    }
  }
  .bg-left-sm,
  .bg-left-top-sm {
    .ImageBackground {
      background-position: left top;
    }
  }
  .bg-center-bottom-sm {
    .ImageBackground {
      background-position: center bottom;
    }
  }
  .bg-right-bottom-sm {
    .ImageBackground {
      background-position: right bottom;
    }
  }
  .bg-left-bottom-sm {
    .ImageBackground {
      background-position: left bottom;
    }
  }
}

@include media-breakpoint-only(sm) {
  .bg-center-md,
  .bg-center-top-md {
    .ImageBackground {
      background-position: center top;
    }
  }
  .bg-right-md,
  .bg-right-top-md {
    .ImageBackground {
      background-position: right top;
    }
  }
  .bg-left-md,
  .bg-left-top-md {
    .ImageBackground {
      background-position: left top;
    }
  }
  .bg-center-bottom-md {
    .ImageBackground {
      background-position: center bottom;
    }
  }
  .bg-right-bottom-md {
    .ImageBackground {
      background-position: right bottom;
    }
  }
  .bg-left-bottom-md {
    .ImageBackground {
      background-position: left bottom;
    }
  }
}

@include media-breakpoint-up(md) {
  .bg-center-lg,
  .bg-center-top-lg {
    .ImageBackground {
      background-position: center top;
    }
  }
  .bg-right-lg,
  .bg-right-top-lg {
    .ImageBackground {
      background-position: right top;
    }
  }

  .bg-left-lg,
  .bg-left-top-lg {
    .ImageBackground {
      background-position: left top;
    }
  }

  .bg-center-bottom-lg {
    .ImageBackground {
      background-position: center bottom;
    }
  }
  .bg-right-bottom-lg {
    .ImageBackground {
      background-position: right bottom;
    }
  }
  .bg-left-bottom-lg {
    .ImageBackground {
      background-position: left bottom;
    }
  }
}
