@import "../../../styles/breakpoints";
@import "../../../styles/mixins";
@import "../../../styles/colors";

.SupportedDevices {
  margin: 0 auto;
  background: $background-white-2;
  padding: 24px 0;
  font-family: "Graphik", Helvetica, Arial, sans-serif;
  &.--dark {
    background-color: $background-faux-black;
  }
  @include media-breakpoint-up(md) {
    padding: 60px 0;
  }
  &__inner-container {
    background-color: $white;
    max-width: 272px;
    margin: 0 auto;
    padding-bottom: 20px;
    border-radius: 8px;
    &.--dark {
      background-color: $gray-16;
    }
    @include media-breakpoint-up(sm) {
      max-width: 327px;
      min-height: 485px;
    }
    @include media-breakpoint-up(md) {
      max-width: 456px;
      min-height: 579px;
      margin: 0 auto;
    }
    @include media-breakpoint-up(lg) {
      max-width: 900px;
      min-height: 408px;
      margin: 0 auto;
    }
  }
  &__top-bar {
    height: 4px;
    background-color: $green2;
    border-radius: 8px 8px 0 0;
    @include media-breakpoint-up(lg) {
      height: 6px;
    }
  }
  &__text-fields {
    margin: 21px 16px 20px;
    &.--dark {
      color: $white;
    }
    @include media-breakpoint-up(md) {
      margin: 36px 48px 24px;
    }
    @include media-breakpoint-up(lg) {
      margin: 34px 50px 28px;
    }
  }
  &__headline {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.25px;
    font-weight: 600;
    @include media-breakpoint-up(sm) {
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -0.5px;
    }
    @include media-breakpoint-up(md) {
      font-size: 40px;
      line-height: 50px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 48px;
      line-height: 60px;
      text-align: center;
    }
  }
  &__description {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.65px;
    color: $gray-12;
    margin-top: 12px;
    &.--dark {
      color: $white;
      opacity: 0.8;
    }
    @include media-breakpoint-up(lg) {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }
  }
  &__tab-section {
    margin-left: 12px;
    display: flex;
    flex-wrap: wrap;
    @include media-breakpoint-up(md) {
      margin-left: 48px;
    }
    @include media-breakpoint-up(lg) {
      justify-content: center;
      margin-left: 0;
    }
  }
  &__tab {
    background-color: $background-white-2;
    color: $gray-15;
    border: none;
    border-radius: 100px;
    text-align: center;
    padding: 11px 12px;
    font-size: 12px;
    font-family: "Graphik", Helvetica, Arial, sans-serif;
    line-height: 18px;
    letter-spacing: 0.25px;
    font-weight: 500;
    margin: 0 8px 8px 0;
    &:hover {
      cursor: pointer;
      background-color: $gray-14;
    }
    &.--active {
      background-color: $black;
      color: $white;
      &:hover {
        cursor: default;
      }
    }
    &.--dark {
      background-color: $background-faux-black;
      color: $white;
      opacity: 0.6;
      &:hover {
        background-color: $gray-17;
      }
      &.--active {
        background-color: $gray-18;
        color: $white;
        opacity: 1;
      }
    }
    @include media-breakpoint-up(sm) {
      padding: 11px 20px;
      font-size: 14px;
    }
    @include media-breakpoint-up(lg) {
      padding: 10px 20px;
      font-size: 16px;
    }
  }
  &__gray-bar {
    border: 1px solid $gray-13;
    width: 88.6%;
    margin: 20px auto;
    &.--dark {
      border-color: $gray-19;
    }
  }
  &__device-section {
    margin-left: 20px;
    &.--centered {
      @include media-breakpoint-up(lg) {
        margin-top: 55px;
      }
    }
    @include media-breakpoint-up(md) {
      margin-left: 48px;
    }
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  &__device {
    font-size: 14px;
    line-height: 36px;
    letter-spacing: 0.65px;
    color: $gray-15;
    display: flex;
    &.--dark {
      color: $white;
    }
    @include media-breakpoint-up(md) {
      font-size: 16px;
      line-height: 48px;
      letter-spacing: 0.5px;
    }
    @include media-breakpoint-up(lg) {
      margin-right: 52px;
      margin-bottom: 20px;
      width: 216px;
    }
  }
  &__img {
    height: 32px;
    width: 32px;
    margin-right: 8px;
    @include media-breakpoint-up(md) {
      height: 48px;
      width: 48px;
    }
  }
  &__text-box {
    display: table;
    @include media-breakpoint-up(lg) {
      min-height: 48px;
      min-width: 160px;
    }
  }
  &__text {
    font-size: 14px;
    line-height: 36px;
    letter-spacing: 0.65px;
    @include media-breakpoint-up(md) {
      font-size: 16px;
      line-height: 48px;
      letter-spacing: 0.5px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 16px;
      line-height: normal;
      display: table-cell;
      vertical-align: middle;
      line-height: 20px;
    }
  }
  &__view-button {
    margin: 0 auto;
    display:block;
    font-size: 14px;
    line-height: 14px;
    font-family: "Graphik", Helvetica, Arial, sans-serif;
    font-weight: 600;
    border: 2px solid $gray-14;
    border-radius: 4px;
    text-align: center;
    width: 60%;
    letter-spacing: 1.5px;
    padding: 11px 0;
    background-color: transparent;
    &:hover {
      cursor: pointer;
    }
    &.--viewingMore {
      @include media-breakpoint-up(md) {
        margin-bottom: 18px;
      }
      @include media-breakpoint-up(lg) {
        margin-bottom: 28px;
      }
    }
    &.--dark {
      color: $white;
    }
    @include media-breakpoint-up(md) {
      width: 216px;
    }
  }
  &__arrow-down {
    position: relative;
    border: solid $black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    bottom: 2px;
    margin-left: 4px;
    &.--dark {
      border-color: $white;
    }
  }
  &__arrow-up {
    position: relative;
    border: solid $black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    top: 3px;
    margin-left: 4px;
    &.--dark {
      border-color: $white;
    }
  }
  &__disclaimer {
    color: $gray-12;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    margin-top: 20px;
    &.--dark {
      color: $white;
      a {
        color: $text-link-2;
      }
    }
    @include media-breakpoint-up(md) {
      margin-top: 28px;
    }
  }
}
