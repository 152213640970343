@import '../../../../styles/global';
.AdaptiveCollection {
  @extend .container--wide;
  overflow: hidden;
  margin-top: 30px;

  .SimpleCollection__description {
    text-align: center;
  }
}
