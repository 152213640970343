// These values are meant to overwrite and align
// styles in Login Modal with One Hulu styles.
// Since the Login Modal is a component within @hulu/login-modal,
// we are using !important to enforce the values.
// this file can be removed once @hulu/login-modal has been updated with
// One Hulu styling

@import '@hulu/web-ui/generated/_variables-dark.scss';
@import '../../../styles/colors';
@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

// TODO: Legacy login remove #HUWEB-30424
// Specifically the .hulu-login class section can be removed after the legacy login is removed
.hulu-login {
  a {
    color: $color-base-blue !important;
  }

  .login-message {
    font-size: $font-title-24-font-size !important;
    font-weight: $font-title-24-caps-font-weight !important;
    letter-spacing: $font-title-24-caps-letter-spacing !important;
    line-height: $font-title-24-caps-line-height !important;
  }

  .iforget-message {
    a {
      font-size: $font-label-14-font-size !important;
    }
  }

  .start-trial-message,
  .start-trial-message a {
    font-size: $font-navigation-14-font-size !important;
    letter-spacing: $font-navigation-14-letter-spacing !important;
    line-height: $font-navigation-14-line-height !important;
  }
}

#login-modal {
  .modal-dialog {
    border-radius: 0;
    width: 432px;
    background-color: $white;
    border: 0;
    box-shadow: 0 2px 4px 0rgba(0,0,0,0.15);

    &.modal-unified-login {
      width: 600px;
      max-width: 100vw;
      min-height: 112px;
    }

    .modal--header {
      height: 48px;

      .modal--close {
        font-weight: 200;
        font-stretch: normal;
        color: $text-body;
        position: absolute;
        height: 16px;
        width: 16px;
        top: 24px;
        right: 24px;

        svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    .modal--body {
      text-transform: none;
      padding: 0;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;

      .loginView {
        box-shadow: none;
        margin: -48px auto 0;
        width: auto;
        padding: 40px;

        .title {
          font-size: $font-title-24-font-size !important;
          font-weight: $font-title-24-caps-font-weight !important;
          letter-spacing: $font-title-24-caps-letter-spacing !important;
          line-height: $font-title-24-caps-line-height !important;
          margin: 32px auto;
        }

        .forget-link {
          a {
            font-size: $font-label-14-font-size !important;
          }
        }

        .start-trial,
        .start-trial a {
          font-size: $font-navigation-14-font-size !important;
          letter-spacing: $font-navigation-14-letter-spacing !important;
          line-height: $font-navigation-14-line-height !important;
        }
      }
    }

    .modal--footer {
      height: 0px;
      padding: 0;
    }
  }
}
