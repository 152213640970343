/** @define SimpleCollection */
@import '../../../../styles/h3o-components';
@import '../../../../styles/mixins';

.SimpleCollection {
  @include collection-spacing;

  position: relative;

  &__title {
    color: $gray-1;
    margin-bottom: 22px;
    @include one-hulu-label-14;

    @media (max-width: $screen-sm-max) {
      margin-bottom: 20px;
    }
    @include media-breakpoint-up(lg) {
      @include one-hulu-label-18;
    }
    &:hover, &:focus, &:active {
      color: $gray-1;
    }
  }

  &__tray-header  {
    display: block;
  }

  &__view-all-link {
    position: absolute;
    top: 0;
    right: 0;
  }

  .Tile__content {
    height: 6em;
    margin-bottom: 16px;
  }

  .NetworkTile__content {
    height: 3em;
    margin-bottom: 16px;
  }
}
