@import '../../../../styles/mixins';
@import '../../../../styles/colors';

.header {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 35px;

  @include media-breakpoint-up(lg) {
    padding-top: 80px;
    padding-bottom: 40px;
  }

  &__headline {
    margin: 0 0 16px;
  }

  &__description {
    @include one-hulu-body-16;

    @include media-breakpoint-up(lg) {
      @include one-hulu-body-18;
    }
  }
}
