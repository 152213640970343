@import '../../../../styles/mixins.scss';
@import '../../../../styles/global';
@import '../../../../styles/breakpoints';

.PortraitCollection {
  @extend .container--wide;
  border-bottom: none;
  background-color: $white;
  padding-top: 40px;

  &__title {
    color: $gray-6;
    margin-top: 0;
    margin-bottom: 20px;
    &:hover, &:focus, &:active, &:visited {
      color: $gray-6;
    }
  }

  &__tray-header {
    display: block;
  }
}
