@import '../../../styles/mixins';
@import '../../../styles/colors';

.nav-item {
  color: $gray-6;
  cursor: pointer;
  padding: 31px 0 17px;
  margin: 4px 30px 4px 0px;
  border-bottom: 5px solid transparent;
  display: inline-block !important;
  opacity: 0.7;

  &--active {
    opacity: 1;
  }
}