@import '../../styles/breakpoints';

.MastheadVideo {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;

  &__video {
    /* Center the video */
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    animation: fade-in 1s ease-in forwards;
  }
}

