@import '~@hulu/site-styles/colors';
@import '../../../../styles/breakpoints';
@import '../../../../styles/global';
@import '../../../../styles/mixins';
@import '../../../../styles/colors';

$standardHeightD: 660px;
$standardHeightT: 600px;
$standardHeightM: 660px;
$tallHeightD: 760px;
$tallHeightT: 660px;
$tallHeightM: 630px;

.ExpMasthead {
  display: -ms-flexbox;
  -ms-flex-direction: column;
  -ms-align-items: center;
  background-image: linear-gradient(
    135deg,
    #231437 0%,
    #2c385e 50%,
    #336e6b 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  background-position: center top;
  background-size: cover;
  text-align: center;
  position: relative;

  // Size at different endpoints
  @include media-breakpoint-up(xs) {
    padding-top: 72px;
    min-height: $standardHeightM;

    &.Masthead--tall {
      padding-top: 41px;
      min-height: $tallHeightM;
    }
  }

  @include media-breakpoint-up(md) {
    padding-top: 72px;
    min-height: $standardHeightT;

    &.Masthead--tall {
      padding-top: 72px;
      min-height: $tallHeightT;
    }
  }

  @include media-breakpoint-up(lg) {
    padding-top: 80px;
    min-height: $standardHeightD;

    &.Masthead--tall {
      min-height: $tallHeightD;
    }
  }

  &.Masthead--short {
    min-height: 0px;
    .Masthead__container {
      padding-top: 49px;
      padding-bottom: 89px;
    }
    .Masthead__preheadline {
      font-size: 14px;
      padding-bottom: 16px;
    }
    .Masthead__primary-message {
      margin-top: 0;
    }
    @include media-breakpoint-up(lg) {
      .Masthead__container {
        padding-top: 12px;
      }
      .Masthead__preheadline {
        padding-bottom: 12px;
      }
    }
  }

  // Continner for texts and contents inside masthead
  &__container {
    @extend .container-width;
    position: relative;
    // flex
    display: -ms-flexbox;
    -ms-flex-direction: column;
    -ms-justify-content: center;

    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;

    width: 100%;

    margin-left: 0;
    margin-right: 0;
    color: $white;
    line-height: 1.3;
    @include z-index(bottom);

    padding: 40px 80px;
    @include media-breakpoint-up(lg) {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    @include media-breakpoint-down(xxl) {
      &.left-align {
        margin-left: unset;
        margin-right: unset;
      }

      margin-left: auto;
      margin-right: auto;
    }
    @include media-breakpoint-down(xl) {
      padding-right: 40px;
      padding-left: 40px;
    }

    &.left-align > * {
      margin-left: unset;
      margin-right: unset;
    }

    & > * {
      margin-left: auto;
      margin-right: auto;

      width: 100%;

      @include media-breakpoint-up(md) {
        width: 570px;
      }

      @include media-breakpoint-up(lg) {
        width: 670px;
      }
    }
  }

  &__cta-container {
    margin-top: 16px;
    display: grid;
    justify-content: center;
    grid-template-columns: 0.8fr 0.8fr;
    column-gap: 16px;

    @include media-breakpoint-up(md) {
      grid-template-columns: 1fr 1fr;
      column-gap: 32px;
    }
  }

  &__legal {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 570px;
    }

    @include media-breakpoint-up(lg) {
      width: 670px;
    }

    margin-top: 16px;
    width: 100%;

    .Masthead__hulu-only {
      padding-top: 10px;
      font-size: 16px;
      a {
        color: #ffffff;
        border-bottom: 1px solid rgba(255, 255, 255, 1);
        text-decoration: none;
      }
    }
  }

  &.mobile-portrait {
    .Masthead__container {
      padding: 0 20px 60px;

      & > * {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .Masthead__input-cta {
      padding: 20px 46px;
      height: auto;
    }

    .Masthead__legal {
      @include one-hulu-body-10;

      width: auto;
      margin-left: auto;
      margin-right: auto;
    }

    .BrandIcon {
      position: absolute;
      right: 20px;
      bottom: 0;
      width: 118px;
    }

    @include media-breakpoint-up(md) {
      min-height: 684px;

      .Masthead__container {
        display: block;
        margin-top: 382px;
        padding-bottom: 74px;
        color: $text-white;

        & > * {
          width: auto;
          text-align: center;
          margin-left: 32px;
          margin-right: 32px;
        }
      }

      .Masthead__subheadline {
        @include one-hulu-subtitle-18;
      }

      .Masthead__input {
        .Masthead__input-cta {
          margin: 0 auto;
          background: #1ce783;
        }
      }

      .Masthead__legal {
        @include one-hulu-body-12;

        width: 336px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &__headline {
    @include one-hulu-title-40;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      @include one-hulu-title-48;
    }

    &-container {
      @include media-breakpoint-up(xl) {
        width: 760px;
      }
    }
  }

  &__subheadline {
    @include one-hulu-subtitle-18;

    margin-top: 0.5em;
    margin-top: 8px;

    @include media-breakpoint-up(md) {
      @include one-hulu-subtitle-24;

      margin-top: 16px;
    }
  }

  &__preheadline {
    @include one-hulu-label-12;

    color: $white;
    padding-bottom: 7px;

    @include media-breakpoint-up(md) {
      padding-bottom: 17px;
    }
  }

  &__primary-message {
    @include one-hulu-body-16;

    margin-top: 20px;

    @include media-breakpoint-up(lg) {
      @include one-hulu-body-24;

      margin-top: 32px;
    }
  }

  &__secondary-message {
    @include one-hulu-body-12;

    margin-top: 20px;

    @include media-breakpoint-up(md) {
      @include one-hulu-body-14;
    }

    @include media-breakpoint-up(lg) {
      @include one-hulu-body-16;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__input {
    margin-top: 1.6em;

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: center;
      align-items: center;

      &.align-left {
        justify-content: flex-start;
      }
    }

    &-cta {
      display: inline-flex;
      align-items: center;
      width: 100%;
      @include media-breakpoint-down(sm) {
        margin: 0 auto;
        width: auto;
      }

      &:not(:first-child) {
        margin-left: 8px;

        @include media-breakpoint-down(sm) {
          margin-left: auto;
          margin-top: 8px;
        }
      }
    }
  }
}
