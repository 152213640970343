@import '../../../../styles/breakpoints';
@import '../../../../styles/fluid-grid';
@import '../../../../styles/colors';

.EpisodeCollection {
  &__seasons {
    color: $text-body;
    font-size: 16px;
    line-height: 18px;
    padding: 32px 0;

    @include media-breakpoint-up(lg) {
      padding: 54px 0;
    }
  }

  &__season {
    margin: 0 12px;
    opacity: 0.5;
    color: $gray-6;

    &--active {
      font-weight: 600;
      opacity: 1;
    }
  }

  &__season:not(.EpisodeCollection__season--disabled) {
    &:hover {
      color: $gray-6;
      font-weight: 500;
      opacity: 1;
    }
  }

  &__select {
    position: relative;
    display: inline;

    select {
      padding-right: 28px;
      font-size: 16px;
      color: $gray-5;
      -moz-appearance: none;
      -webkit-appearance:none;
      appearance: none;
      border: none;
      background-color: transparent;
      outline: none;
    }

    &-arrow {
      background: none;
      position: absolute;
      top: 1px;
      right: 8px;
      border: solid $gray-5;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 4px;
      transform: rotate(45deg);
      transition: transform 0.3s ease;
      transform-origin: 65% 65%;
      pointer-events: none;
    }
  }

  &__items {
    @extend .grid-container;
  }

  &__item {
    @extend .grid-col;
    margin-bottom: 56px;

    .Tile__description {
      white-space: normal;
    }

    @include media-breakpoint-down(sm) {
      flex-basis: 100%;
      margin-bottom: 24px;

      .Tile {
        position: relative;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }

      .Tile__thumbnail {
        margin-bottom: 16px;
        margin-right: 12px;
      }

      .Tile__description {
        flex: 0 0 100%;
        align-self: flex-start;
      }

      .Tile__brand {
        width: 32px;
        height: 32px;
      }
    }
  }
}