@import '../../../styles/breakpoints';

.BrandIcon {
  position: absolute;
  bottom: 60px;
  right: 80px;
  width: 144px;
  height: 144px;
  @include media-breakpoint-down(xl) {
    right: 40px;
  }
  @include media-breakpoint-down(md) {
    width: 100px;
    height: 100px;
    bottom: 44px;
  }
  @include media-breakpoint-down(sm) {
    bottom: 40px;
    position: initial;
    margin-right: 0;
    padding-top: 10px;
    width: 50px;
    height: 50px;
  }
  @include media-breakpoint-down(xs) {
    bottom: 36px;
    right: 20px;
  }
}

.BrandIcon__brand-img {
  position: absolute;
  bottom: 0;
  right: 0;
  height: auto;
  max-width: 100%;
  @include media-breakpoint-down(sm) {
    position: initial;
  }
}
