@import '../../../../styles/breakpoints';
@import '../../../../styles/global';
@import '../../../../styles/colors';

.CollectionDetails {
  &__tab {
    padding: 25px 0;

    @include media-breakpoint-up(md) {
      padding: 33px 0 39px;
      max-width: 500px;
    }

    @include media-breakpoint-up(md) {
      padding: 48px 0 151px;
      max-width: 650px;
    }
  }
  &__subtitle {
    color: $background-faux-black;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      margin-bottom: 16px;
    }
  }
  &__title {
    color: $background-faux-black;
    margin-bottom: 12px;

    @include media-breakpoint-up(md) {
      margin-bottom: 16px;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 20px;
    }
  }
  &__desc {
    color: $gray-20;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      margin-bottom: 16px;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 20px;
    }
  }
}
