@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '~@hulu/site-styles/font_utils';
@import '../../../styles/mixins';

#description-modal.modal-show {
  display: flex;
  align-items: center;
}

.modal-dialog.description-modal {
  max-width: 600px;

  @include media-breakpoint-down(sm) {
    margin: 20px auto;
    max-width: 80%;
    max-height: 80%;
    min-height: 0;
  }

  .modal--header {
    padding: 0;

    .modal--close {
      position: absolute;
      right: 28px;
      top: 28px;
      svg {
        height: 16px;
        width: 16px;
      }
    }
  }

  .modal--body {
    overflow: auto;
    padding: 0 !important;
  }

  .DetailEntityModal {
    &__title {
      padding: 40px 40px 0 40px;
      color: $background-faux-black;
    }

    &__description {
      color: $text-body;
      margin: 24px 40px 20px 40px;
    }

    .DetailEntityMetadata {
      padding: 0 40px;

      &__credits {
        margin-bottom: 16px;

        p {
          @include one-hulu-body-12;
          @include media-breakpoint-up(lg) {
            @include one-hulu-body-14;
          }

          &:not(:last-of-type) {
            margin-bottom: 4px;
          }
        }
      }

      &__rating, &__hub, &__tags {
        @include one-hulu-body-12;
        @include media-breakpoint-up(lg) {
          @include one-hulu-body-14;
        }
      }

      &__genre {
        color: $text-body;
        @include one-hulu-body-12;
        @include media-breakpoint-up(lg) {
          @include one-hulu-body-14;
        }

        &:hover, &:visited, &:active, &:focus {
          color: $text-body;
        }
      }
    }
  }
}
