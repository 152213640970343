@import '../../../../styles/breakpoints';
@import '../../../../styles/colors';
@import '../../../../styles/mixins';

.exp-grid {
  padding: 0 0 14px;

  .exp-grid-row {
    align-content: center;
    display: flex;
    justify-content: flex-start;
    margin: 0 -12px;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    .exp-grid-column {
      padding: 12px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.is-section {
    .exp-grid-row {
      padding-top: 119px;
      padding-bottom: 38px;
      padding-left: 20px;
      padding-right: 20px;
      flex-direction: column-reverse;

      @include media-breakpoint-up(md) {
        flex-direction: row;
      }

      @include media-breakpoint-up(sm) {
        padding-top: 88px;
        padding-bottom: 50px;
      }
      
      @include media-breakpoint-up(md) {
        padding-top: 167px;
        padding-bottom: 167px;
      }

      @include media-breakpoint-up(xl) {
        padding-top: 104px;
        padding-bottom: 58px;
      }
    }
  }
}

.theme__dark {
  .exp-grid, .section-headline {
    background-color: $background-faux-black;
    color: $white;
  }
}
