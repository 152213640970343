@import "~@hulu/site-styles/variables";
@import '~@hulu/site-styles/font_utils';
@import '../../../styles/breakpoints';
@import '../../../styles/mixins';
@import '../../../styles/colors';

.NetworkIcon {
  height: 90px;
  margin: 14px;
  position: relative;
  width: 90px;

  &__frame {
    box-shadow: 0 1px 2px 0 $tooltip-shadow-color;
    border-radius: 50%;
    height: 90px;
    overflow: hidden;
    width: 90px;
  }

  &__logo {
    background-size: 75px;
    background-repeat: no-repeat;
    background-position: 50%;
    display: table-cell;
    @include one-hulu-body-12;
    height: 90px;
    overflow: hidden;
    text-align: center;
    vertical-align: middle;
    width: 90px;
  }

  &__logo-be {
    background-size: 56px;
  }

  &__network-name-invisible {
    display: none;
  }

  &__badge {
    bottom: -20px;
    cursor: pointer;
    position: absolute;
    width: 100%;

    .__react_component_tooltip {
      margin: 0;
      padding: 0;
      border: none;
      background-color: transparent;
      &::before,&::after {
        content: none;
        left: 0;
      }
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .HuluTooltip {
      display: block;
      width: 100%;
      @include one-hulu-label-10;
      text-align: center;
      line-height: unset;
    }
  }
}
