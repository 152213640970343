@import '../../../styles/global';

.tabs {
  overflow-x: hidden;
  &__container {
    @extend .container--wide;
  }

  &__divider {
    box-sizing: border-box;
    height: 2px;
    width: 100%;
    border: 1px solid $accent-grey;
  }

  &__container {
    @extend .container-width;
    overflow-x: hidden;
  }

  &__content {
    transition: all 0.4s ease;
  }

  &__content--slider {
    display: flex;
  }

  &__tab {
    flex: 0 0 100%;
    opacity: 0;
    transition: all 0.5s ease;
    display: none;

    &--active {
      opacity: 1;
      display: block;
    }
  }
}

