@import '../../../styles/breakpoints';
@import '../../../styles/mixins';
@import '../../../styles/colors';

.content-tabs {
  padding: 40px 0;
  text-align: center;

  .text-media {
    padding-bottom: 0;
    @include media-breakpoint-down(sm) {
      padding-top: 0;
    }
  }
  &--active-bar {
    display: block;
    cursor: default;
    height: 5px;
    background: linear-gradient(270deg,#92ed8d 0%,#64d98b 42.64%,#05aaa3 100%);
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: right center;
    transition: width .4s cubic-bezier(.455,.03,.515,.955), transform .4s cubic-bezier(.455,.03,.515,.955);
  }
  .active {
    .content-tabs--active-bar {
      transform: scaleX(1);
      transform-origin: left center;
    }
  }
  h2 {
    text-align: center;
    margin: 0 auto 16px;
    @include one-hulu-title-32;
  }
  &--info {
    padding-bottom: 19px;
  }
  &--description {
    color: $gray-6;
    @include one-hulu-body-16;
  }
  &--tabs-wrapper {
    overflow: hidden;
    position: relative;
    text-align: center;
    height: 40px;
    &::before,
    &::after {
      content: " ";
      width: 25px;
      height: 40px;
      top: 0;
      position: absolute;
      @include z-index(low);
    }
    &::before {
      background: -moz-linear-gradient(left, rgba(245,246,247,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(245,246,247,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(245,246,247,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      left: 0;
    }
    &::after {
      background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(245,246,247,1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(245,246,247,1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(245,246,247,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      right: 0;
    }
  }
  &--scroll, &--mobile {
    overflow-x: hidden;
    white-space: nowrap;
    width: 100%;
    margin: 0 auto;
    display: inline-flex;
    justify-content: center;
    position: relative;
    padding-bottom: 14px;
    -webkit-overflow-scrolling: touch;
  }
  &--scroll {
    overflow-x: scroll;
  }
  &--outer-container {
    position: relative;
    margin: 0 auto;
    display: block;
    padding-left: 49px;
    padding-right: 49px;
  }
  &--outer-container-mobile {
    position: relative;
    margin: 0 auto;
    left: 49px;
    padding-right: 49px;
    .content-tabs--inner-container {
      transform-origin: right center;
      transition: transform .4s cubic-bezier(.455,.03,.515,.955);
    }
  }
  &--inner-container {
    margin: 2px auto 0 ;
    padding: 0;
    border-top: 1px solid $content-tabs-border;
    @include media-breakpoint-down(xs) {
      border-top: none;
    }
  }
  &--tab {
    color: $gray-28;
    padding: 13px 21px 0;
    list-style: none;
    display: inline-block;
    float: none;
    @include one-hulu-eyebrow-14;
    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      padding-right: 0;
    }
    @media (hover: hover) {
      span:hover  {
        color: $green4;
      }
    }
    &.active {
      color: $green2;
      span {
        cursor: default;
      }
    }
    span {
      padding-top: 15px;
      cursor: pointer;
    }
  }
  &--content-pane {
    opacity: 0;
    transform: translate3d(0,20px,0);
    order: 2;
    width: 100%;
    &.active {
      opacity: 1;
      transform: translate3d(0,0,0);
      transition: all .6s ease;
      order: 1;
    }
    .text-media--no-image .text-media--text {
      width: 100%;
    }
  }
  &--content-panes {
    display: flex;
  }
  &--content-wrapper {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
  }
}