@import "../../../styles/breakpoints";
@import "../../../styles/mixins";
@import "../../../styles/colors";

.Billboard {
  background-color: $gray-21;
  font-family: "Graphik Web", "Graphik", Helvetica, Arial, sans-serif;
  padding: 1px;

  &__border {
    margin: 19px 10px;
    border: 4px solid $green;
    box-sizing: border-box;
    border-radius: 16px;

    @include media-breakpoint-up(md) {
      margin: 40px 18px;
    }
  }

  &__container {
    margin: 0 auto;
    padding-top: 60px;
    padding-bottom: 20px;
    background-image: none;

    @include media-breakpoint-up(md) {
      padding: 95px 0 50px;
      background-image: none !important;
    }
    @include media-breakpoint-up(lg) {
      max-width: $container-max-width-lg;
      padding: 100px 0;
    }
    @include media-breakpoint-up(xl) {
      max-width: $container-max-width-xl;
    }
    @include media-breakpoint-up(xxl) {
      max-width: $container-max-width-xxl;
    }
  }

  &__eyebrow,
  &__headline,
  &__subheadline,
  &__downArrow {
    text-align: center;
    color: $white;
    margin: 0 auto;
  }

  &__eyebrow {
    color: $brand-green;
    @include one-hulu-eyebrow-12;

    @include media-breakpoint-up(lg) {
      @include one-hulu-eyebrow-14;
    }
  }

  &__headline {
    margin: 16px 3% 0;
    @include one-hulu-title-32;

    @include media-breakpoint-up(md) {
      @include one-hulu-title-48;
    }
    @include media-breakpoint-up(lg) {
      white-space: nowrap;
    }
  }

  &__subheadline {
    margin: 12px 5% 0;
    @include one-hulu-body-16;

    @include media-breakpoint-up(md) {
      margin: 18px 10% 0;
    }
    @include media-breakpoint-up(lg) {
      margin: 18px 17.5% 0;
      @include one-hulu-body-18;
    }
    @include media-breakpoint-up(xl) {
      margin: 18px 12% 0;
    }
    @include media-breakpoint-up(xxl) {
      margin: 18px 17.5% 0;
    }
  }

  &__modalLink {
    text-decoration: underline;
    opacity: 0.8;
    color: $white;
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    margin: 16px auto 0 auto;
    @include one-hulu-label-12;

    @include media-breakpoint-up(md) {
      @include one-hulu-label-14;
    }
    @include media-breakpoint-up(lg) {
      @include one-hulu-label-16;
    }
  }

  &__downArrow {
    background: url("/static/hitch/static/icons/dropdown_arrow.svg") no-repeat
      center;
    height: 16px;
    margin-top: 55px;
    cursor: pointer;
    width: 18px;
    padding: 0;
    border: none;
    text-align: center;
    justify-content: center;
    display: flex;
    @include animation("move-up-and-down 1s infinite alternate");
    @include keyframes(move-up-and-down) {
      0% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(-10px);
      }
    }
    @include media-breakpoint-up(md) {
      margin-top: 50px;
    }
    @include media-breakpoint-up(xl) {
      margin-top: 50px;
    }
    @include media-breakpoint-up(xxl) {
      margin-top: 70px;
    }
  }

  &__disclaimer {
    text-align: center;
    margin: 12px 5% 0;

    @include media-breakpoint-up(md) {
      margin: 18px 10% 0;
      margin: 0 5%;
    }
    @include media-breakpoint-up(lg) {
      margin: 18px 17.5% 0;
    }
    @include media-breakpoint-up(xl) {
      margin: 18px 12% 0;
    }
    @include media-breakpoint-up(xxl) {
      margin: 18px 17.5% 0;
    }
  }
}
