@import '../../../styles/mixins';
@import '../../../styles/colors';

.logo-wall--bug {
    display: -ms-flexbox;
    -ms-flex-align: center;
    -ms-justify-content: center;
    display: flex;
    align-items: center;
    overflow: hidden;
    color: $gray-6;
    width: 100%;
    height: 100%;
    max-height: 88px;
    max-width: 88px;
    margin: 0 auto;
    background: url('/static/hitch/static/logos/networks/network-logo-bg@2x.png') center center no-repeat;
    background-size: contain;
    img {
      width: 80%;
      font-size: 10px;
      font-weight: 700;
      text-align: center;
      color: $black;
      margin: 0 auto;
      @include lazyLoad(null);
    }
  }
