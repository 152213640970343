@import '../../../styles/breakpoints';

.value-props--wrapper {
    display: block;
    margin: 48px 0;
  
    @include media-breakpoint-down(sm) {
      margin: 40px 0;
    }
  
    .container-width {
      @include media-breakpoint-up(lg) {
        -ms-display: flex;
        -ms-justify-content: space-between;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
      }
    }
  }