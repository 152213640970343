@import '../../../../styles/breakpoints';
@import '../../../../styles/mixins';
@import '../../../../styles/colors';

html {
  scroll-behavior: smooth;
}
.exp-plan-builder-escape-hatch-formv2 {
  height: 105%;
  &.--expanded {
    height: 108%;
  }
  @include media-breakpoint-up(md) {
    height: 105%;
    &.--expanded {
      height: 107%;
    }
  }

  .exp-plan-builder {
    padding-top: 74px;
    display: block;
    background-color: $background-faux-black-2;
    color: $white;
    .exp-plan-builder-container-width {
      display: flex;
      justify-content: center;
      align-content: center;
      flex-direction: column;
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
      padding: 0px 24px;
      @include media-breakpoint-up(md) {
        padding: 0px;
      }
      .title-section {
        display: flex;
        flex-direction: column;
        padding-top: 40px;
        .plan-builder-title {
          align-self: center;
          padding-bottom: 24px;
          @include media-breakpoint-up(lg) {
            font-size: 40px;
            padding-bottom: 16px;
          }
        }
        .plan-builder-subtitle {
          text-align: center;
          padding-bottom: 20px;
        }
        .start-price-text {
          text-align: center;
          font-size: 18px;
          font-weight: 400;
          line-height: 20px;
          .price {
            color: #1ce783;
            display: inline-block;
          }
        }
      }
      .question-section {
        display: flex;
        flex-direction: column;
        padding: 0px 0px;
        .question-container {
          padding-top: 40px;
          &.--disabled {
            opacity: 30%;
          }
          .question {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0px 0px 20px 0px;

            img {
              max-height: 100px;
              max-width: 100px;
              align-self: center;
              margin-bottom: 40px;
              @include media-breakpoint-up(lg) {
                margin-bottom: 60px;
                max-height: 160px;
                max-width: 160px;
              }
            }
            .question-description {
              padding-top: 8px;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
            }
            .question-linkText {
              padding-top: 8px;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              &.--disabled {
                pointer-events: none;
              }
            }
          }
          .answers {
            margin-bottom: 24px;
            .answer-button {
              border: 2px solid rgba(white, 0.2);
              background-color: $background-faux-black-2;
              color: white;
              border-radius: 8px;
              min-height: 75px;
              height: auto;
              width: 100%;
              padding: 0px 16px;
              margin-bottom: 16px;
              cursor: pointer;
              &.--disabled {
                cursor: default;
              }
              &.--selected {
                border: 2px solid #1ce783;
              }
              .answer-container {
                gap: 16px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: row;
                padding-top: 16px;
                padding-bottom: 16px;
                &.--single-column {
                  justify-content: center;
                }

                .left-column {
                  font-family: 'Graphik';
                  font-size: 16px;
                  font-weight: 600;
                  text-align: left;

                  flex-basis: 50%;
                  @include media-breakpoint-up(md) {
                    flex-basis: 50%;
                  }
                  &.--full-width {
                    flex-basis: 100%;
                  }
                  .left-column-top-row {
                    font-family: 'Graphik';
                    font-size: 16px;
                    font-weight: 600;
                    padding-bottom: 4px;
                  }
                  .left-column-bottom-row {
                    font-family: 'Graphik';
                    font-size: 12px;
                    font-weight: 400;
                  }
                  &.--more-space {
                    flex-basis: 65%;
                  }
                  &.--live-more-space {
                    flex-basis: 80%;
                  }
                }
                .right-column {
                  font-family: 'Graphik';
                  font-size: 12px;
                  font-weight: 400;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;
                  text-align: right;
                  flex-basis: 45%;
                  @include media-breakpoint-up(md) {
                    flex-basis: 50%;
                  }
                  .bold-text {
                    font-family: 'Graphik';
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                  }
                }
              }
            }
          }
          hr {
            width: 100%;
            margin: 0px;
            border: 1px solid rgba(white, 0.1);
          }
        }
      }
      .bottom-section {
        display: flex;
        flex-direction: column;
        padding: 40px 0px 275px 0px;

        @include media-breakpoint-up(md) {
          padding: 80px 0px 250px 0px;
        }
        .bottom-content {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          align-self: center;
        }
      }
    }
    a {
      color: $text-link-4;
    }
  }

  ~ .modal-wrapper {
    .modal-dialog {
      @include media-breakpoint-up(lg) {
        width: 600px;
      }
    }
    .modal-dialog__title {
      padding: 0px 24px;
      text-align: left;
      font-size: 20px;
      font-weight: 600;
      font-family: 'Graphik';
      line-height: 28px;
    }
    .modal--body {
      padding: 0px 24px;
      font-size: 14px;
      font-weight: 400;
      font-family: 'Graphik';
      line-height: 20px;
      a {
        color: $text-link-4;
      }
    }
  }
}
