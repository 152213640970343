@import '../../../styles/breakpoints';
@import '../../../styles/variables';
@import '../../../styles/global';
@import '../../../styles/mixins';
@import '../../../styles/colors';

$one-hulu-nav-height-override: 74px;

.page {
  header {
    border-bottom: 0;
    height: $one-hulu-nav-height-override;

    nav {
      height: $one-hulu-nav-height-override;
    }
  }

  .navigation {
    &--minimal {
      .navigation__login-button {
        display: none;
      }

      .GlobalNav-module_GlobalNavigation__navend__Mp19G {
        display: none;
        @include media-breakpoint-up(lg) {
          display: inline;
        }
      }

      &.navigation--cta-always {
        .navigation__cta {
          @include media-breakpoint-up(lg) {
            display: inline;
          }
        }
      }
    }

    &--static {
      &.navigation--cta-always {
        .navigation__cta {
          display: inline;
        }
      }
    }

    &--sticky {
      .navigation__cta {
        display: none;
        &--active {
          @include media-breakpoint-up(lg) {
            display: inline;
          }
        }
      }
      &.navigation--cta-always {
        .navigation__cta {
          display: inline;
        }
      }
    }

    &--fixed {
      .navigation__cta {
        display: none;
        &--active {
          @include media-breakpoint-up(lg) {
            display: inline;
          }
        }
      }
      &.navigation--cta-always {
        .navigation__cta {
          @include media-breakpoint-up(lg) {
            display: inline;
          }
        }
      }
    }

    &__cta {
      margin-right: 15px;
      display: none;

      &--always-show {
        display: inline;
      }

      &--active {
        display: inline;
      }

      &--in-menu {
        margin: 0 4px;
        width: calc(100% - 8px);
      }

      &--hidden {
        display: none !important;
      }

      &--transparent {
        background-color: transparent;
        color: $white;
      }
    }

    &__item {
      &--transparent {
        background: transparent;
      }
      &--mobile-only {
        @include media-breakpoint-up(lg) {
          display: none;
        }
      }
    }

    &__logged-out-button {
      color: var(--global-nav-text-color);
      justify-content: center;
      align-items: center;
      height: 40px;
      border-radius: var(--global-nav-item-border-radius);
      transition-duration: var(--global-nav-transition-duration);
      transition-timing-function: ease;
      transition-property: background-color, color;
      cursor: pointer;
      display: none;

      @include media-breakpoint-up(lg) {
        padding: 0 16px;
        display: flex;
      }

      &:not(:last-of-type) {
        margin-right: 16px;
      }

      &:hover {
        background-color: var(--global-nav-item-background--hover);
        color: var(--global-nav-item-active);
      }

      &:active {
        background-color: var(--global-nav-item-background--press);
        color: var(--global-nav-item-active);
      }
    }

    &__toaster-scrim {
      height: 80px;
      display: flex;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.5) 100%
      );
      align-items: center;
      justify-content: center;
    }

    &__toaster-cta {
      transition: unset;
      transform: unset;
      width: calc(100% - 40px);
      &:hover {
        transform: unset;
      }
    }

    // ensure web-ui hamburger is hidden on larger screens
    .NavMenu-module_NavMenu__3Xget {
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }
}

.theme__dark {
  .navigation {
    background-color: $gray-16;
  }
}
