@import '../../../../styles/mixins';

.bundle-modal {
  padding: 0 5px;
  &__section {
    padding: 20px 0;
  }
  &__section-header {
    margin-bottom: 15px;
  }
  &__section-item {
    display: flex;
    margin-bottom: 20px;
  }
  &__item-description {
    padding-left: 16px;
  }
  &__disclaimer {
    margin: 20px 0;
    @include one-hulu-body-16;
  }
}
