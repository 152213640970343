@import '~@hulu/site-styles/font_utils';
@import 'mixins';
@import 'breakpoints';
@import 'mixins';
@import 'colors';

#modal {
  position: relative;
  z-index: 1000;
}

.visually-hidden {
  @media screen {
    display: none;
  }
}

/** Universal utility **/
.white {
  color: $text-white !important;
}

.justify-content-center {
  -webkit-box-pack: center!important;
  -ms-flex-pack: center!important;
  justify-content: center!important;
}

.modal-open {
  overflow: hidden;
}

.hidden {
  display: none !important;
  visibility: hidden !important;
}

.container-width {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 140px;
  padding-right: 140px;

  @include media-breakpoint-down(lg) {
    padding-left: 44px;
    padding-right: 44px;
  }
  @include media-breakpoint-down(md) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @include media-breakpoint-down(sm) {
    padding-left: 20px;
    padding-right: 20px;
  }

  &--small {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
  }
}

.legal-container {
  max-width: 768px;
  margin: 120px auto 0px auto;
  padding: 0px 10px;
  a, a:active, a:hover, a:visited {
    text-decoration: underline !important;
  }
}

.container--wide {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;

  @include media-breakpoint-down(md) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include media-breakpoint-up(md) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @include media-breakpoint-up(xxl) {
    padding-left: 56px;
    padding-right: 56px;
  }
}

%base-button {
  height: 46px;
  min-width: 136px;
  padding: 0 32px;
  border: none;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 1px;
  cursor: pointer;
  text-align: center;

  @include media-breakpoint-up(lg) {
    height: 56px;
  }
}

%base-button-link {
  background: none;
  color: inherit;
  padding: 0;
  font: inherit;
  border: none;
  cursor: pointer;
  text-decoration: underline;
}

.button-link {
  background: none;
  color: inherit;
  padding: 0;
  font: inherit;
  border: none;
  cursor: pointer;
  text-decoration: underline;
}

.primary-button {
  @extend %base-button;
  background: linear-gradient(45deg, $green2 0%, $green3 100%);
  color: $white !important;
  &:hover, &:visited, &:active {
    color: $white;
  }
}

.welcome-button {
  @extend %base-button;
  background: $green;
  color: $background-faux-black !important;
  &:hover, &:visited, &:active {
    color: $background-faux-black;
  }
}

.secondary-button {
  @extend %base-button;
  background: transparent;
  border: 2px solid $green4;
  color: $green4 !important;
  &:hover, &:visited, &:active {
    color: $white;
  }
}

.button--white {
  @extend %base-button;
  color: $cta-button-black;
  background: $white;
}

.button--black {
  @extend %base-button;
  color: $white;
  background: $cta-button-black;
}

.button--grey-border {
  @extend %base-button;
  border: 1.5px solid $gray-28;
  color: $gray-6;
  background: transparent;
}

.dark .secondary-button {
  border: 2px solid $white;
  color: $white !important;
}

.button,
.button--cta {
  text-transform: uppercase;
  white-space: nowrap;
  border-radius: 4px;
  cursor: pointer;
}

/* Solid Button */

.button--cta {
  transform: translateY(0);
  transition: box-shadow 0.2s ease, transform 0.1s ease;
  &.button--white-outline,
  &.button--black-outline {
    @extend %base-button;
    background: transparent;
  }
  &.button--white-outline {
    color: $white;
    border: 2px solid $cta-button-transparent-stroke;
  }
  &.button--black-outline {
    color: $cta-button-black;
    border: 2px solid $cta-button-transparent-dark-stroke;
  }
  &.button--white-outline:hover {
    border: 2px solid $cta-button-transparent-stroke-hover;
  }
  &.button--black-outline:hover {
    border: 2px solid $cta-button-transparent-dark-stroke-hover;
  }
  &.--green-with-black-text {
    color: $cta-button-black;
    background: $green;
    &:hover {
      color: $cta-button-black;
      background: $green-hover;
    }
  }
  &.--green-with-white-text {
    color: $white;
    background: $green;
    &:hover {
      background: $green-hover;
    }
  }
}

.button--black.button--cta:hover {
  background-color: $cta-button-gray;
}
.button--white.button--cta:hover {
  background-color: $cta-button-white-hover;
}

.button--cta:active {
  transform: translateY(0);
}

/* Outline Stroke Buttons */

.button--cta .outline-light {
  border: 2px solid $cta-button-transparent-stroke;
  box-shadow: 0 0 0 rgba(0,0,0,0);
  transform: translateY(0);
  background: transparent;
  transition: all 0.2s ease;
}

.button--cta .outline-light:hover {
  background: transparent;
  color: $cta-button-black;
  transform: translateY(-2px);
  box-shadow: 0 3px 6px rgba(0,0,0,0.2);
}

.button--cta .outline-light:active {
  box-shadow: 0 3px 6px rgba(0,0,0,0);
  transform: translateY(1px);
}

.section-body {
  color: $text-body;
}

%gradient-skrim, .gradient-skrim {
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    top:0;
    height: 180px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.76) 15.54%, rgba(0, 0, 0, 0.192) 60.23%, rgba(0, 0, 0, 8e-05) 100%);
  }
}

@keyframes fade-in {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fade-in {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fade-in {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fade-in {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fade-in {
  from { opacity: 0; }
  to   { opacity: 1; }
}


@keyframes fade-out {
  from { opacity: 1; }
  to   { opacity: 0; }
}

/* Firefox < 16 */
@-moz-keyframes fade-out {
  from { opacity: 1; }
  to   { opacity: 0; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fade-out {
  from { opacity: 1; }
  to   { opacity: 0; }
}

/* Internet Explorer */
@-ms-keyframes fade-out {
  from { opacity: 1; }
  to   { opacity: 0; }
}

/* Opera < 12.1 */
@-o-keyframes fade-out {
  from { opacity: 1; }
  to   { opacity: 0; }
}

.page {
  display: flex;
  flex-direction: column;
  color: $black-5;

  &.theme__dark {
    .content-wrapper {
      background-color: $background-faux-black;
    }
  }
}

.content-wrapper {
  flex: 1 0 auto;

  &--brand {
    background-color: $white;

    .section-headline {
      @include one-hulu-title-32;
    }
  }
}

.section-headline {
  margin: 0 0 16px;
  color: $gray-6;
  @include one-hulu-title-32;

  @include media-breakpoint-down(sm) {
    margin-bottom: 10px;
  }
}

.arrow-button {
  border: none;
  background: transparent;
  cursor: pointer;
  @include one-hulu-subtitle-14;
  color: $text-link-3;
}

.eyebrow {
  @include one-hulu-eyebrow-14;
  color: $gray-6;
  margin-bottom: 10px;
}

.section-disclaimer {
  @include one-hulu-body-10;
  color: $white-with-opacity;

  a,
  a:hover,
  a:active,
  a:visited,
  a:focus {
    color: $white-with-opacity;
    text-decoration: none;
    border-bottom: 1px solid $white-with-opacity;
  }

  &--dark {
    color: $gray-1;

    a,
    a:hover,
    a:active,
    a:visited,
    a:focus {
      color: $gray-1;
      border-bottom: 1px solid $gray-1;
    }
  }
}

.text-strike {
  text-decoration-color: $green;
}
