@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/mixins';

.row {
  margin-left: 0;
  margin-right: 0;

  @include media-breakpoint-down(sm) {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.text-media {
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: left;

  @include media-breakpoint-down(sm) {
    text-align: center;
  }

  &--brand-enabled {
    background-color: $white;
  }

  img {
    width: 100%;
    height: auto;
    flex-shrink: 0;
    @include lazyLoad(441px);
  }

  @include media-breakpoint-down(md) {
    &.text-media-right {
      .text-media--paragraph {
        margin-bottom: 0;
      }

      .text-media--disclaimer-top {
        display: none;
      }

      .text-media--disclaimer-bottom {
        display: block !important;
      }
    }
  }

  @include media-breakpoint-down(md) {
    &--text {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 32px;
      margin-top: 32px;
    }
  }

  @include media-breakpoint-down(sm) {
    .text-media--right {
      order: 1;
    }

    &--image.text-media--right {
      order: 2;
    }
  }

  &--left,
  &--right {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &--headline {
    margin-bottom: 8px;
  }

  &--paragraph {
    color: $text-body;
    margin-top: 0;
    margin-bottom: 0.5rem;
    @include one-hulu-body-16;
  }

  &--disclaimer-bottom {
    display: none;
    max-width: 490px;
    margin: 25px auto 0;
    padding: 0 10px;
    @include one-hulu-body-12;
  }

  &__logos {
    margin: 30px auto 0 -16px;
    max-width: 450px;

    @include media-breakpoint-between(md, lg) {
      margin: 0 0 0 -8px;
      max-width: 420px;
    }

    @include media-breakpoint-down(md) {
      margin: 0 auto;
    }
  }

  &__logo-wrapper {
    height: 88px;
    margin-bottom: 24px;

    @include media-breakpoint-down(md) {
      margin-bottom: 8px;
    }
  }

  &__logo {
    &.logo-wall--bug {
      max-width: initial;
    }
  }

  &__button-wrapper {
    margin-top: 18px;

    @include media-breakpoint-down(md) {
      margin-top: 0;
      margin-bottom: 32px;
    }
  }

  %base-button {
    color: $text-link-3;
    cursor: pointer;
  }

  &__button {
    @extend %base-button;
    border: none;
    background-color: transparent;
    padding: 0;
  }

  &__link,
  &__link:hover {
    @extend %base-button;
  }
  &__legal {
    margin-top: 8px;
    @include one-hulu-body-10;
    @include media-breakpoint-up(md) {
      margin-top: 16px;
    }
  }
}

.text-media--no-image {
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
  display: flex;
  justify-content: center;

  .text-media--text {
    width: 872px;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-down(xl) {
      width: 808px;
    }

    @include media-breakpoint-down(lg) {
      width: 776px;
    }

    @include media-breakpoint-down(md) {
      width: 512px;
    }

    @include media-breakpoint-down(sm) {
      width: 280px;
    }
  }
}