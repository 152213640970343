@import '../../../styles/breakpoints';
@import '../../../styles/mixins';

.ConnectedLogin {
  @include media-breakpoint-up(md) {
    & {
      @include one-hulu-title-24;
    }
  }

  @include media-breakpoint-up(xl) {
    & {
      @include one-hulu-title-32;
    }
  }
}
