@import '../../../styles/breakpoints';
@import '../../../styles/mixins';
@import '../../../styles/colors';

$badge-min-height: 27px;
$navigation-padding: 74px;
$head-card-height-lg: 215px;
$head-card-height-md: 201px;
$head-card-height-sm: 189px;
$head-card-height-no-badge: $head-card-height-lg - $badge-min-height;

.plan-head-column-for-new-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  padding: 20px 0px;

  @include media-breakpoint-up(md) {
    padding: 20px 40px;
  }

  @include media-breakpoint-up(lg) {
    height: $head-card-height-lg;
    padding: 15px 0 10px;
    border-bottom: 1px solid $gray-3;
  }
}

.is-dark {
  .plan-head-column-for-new-toggle {
    @include media-breakpoint-up(lg) {
      border-bottom: 1px solid $gray-dimmed;
    }
  }
}

.plan-comparison-chart-with-toggle {
  background-color: $white;
  position: relative;
  padding-bottom: 40px;
}

.plans-container {
  background-color: $white;
  color: $gray-1;
  font-family: Graphik;
  position: relative;

  @include media-breakpoint-up(lg) {
    max-width: 900px;
    margin: 0 auto;
  }

  @include media-breakpoint-up(xl) {
    max-width: $container-max-width-xl;
  }

  @include media-breakpoint-up(xxl) {
    max-width: $container-max-width-xxl;
  }

  .plan-head-initial {
    position: initial;
    width: 100%;
  }

  .plan-head-container {
    display: flex;
    flex-direction: column;
    background-color: $white;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      padding-top: 0;
    }

    &.plan-head-fixed {
      position: fixed;
      width: 100%;
      @include media-breakpoint-up(lg) {
        max-width: 900px;
      }
      @include media-breakpoint-up(xl) {
        max-width: $container-max-width-xl;
      }
      @include media-breakpoint-up(xxl) {
        max-width: $container-max-width-xxl;
      }
    }
  }

  .plan-head__description {
    text-align: center;
    width: 260px;
    margin: 0 auto;
    @include media-breakpoint-up(sm) {
      width: 315px;
    }
    @include media-breakpoint-up(md) {
      width: 708px;
    }
    @include media-breakpoint-up(lg) {
      text-align: left;
      max-width: 30vw;
      margin: 0;
    }
    @include media-breakpoint-up(xl) {
      width: 100%;
    }
  }
  .plan-head-column {
    display: block;
    top: 0;
    padding: 20px;

    @include media-breakpoint-up(md) {
      padding: 20px 40px;
    }

    @include media-breakpoint-up(lg) {
      height: $head-card-height-lg;
      padding: 15px 0 10px;
      border-bottom: 1px solid $gray-3;
    }
  }

  .plan-head {
    text-align: center;
    display: flex;
    padding: 0;
    top: 0;

    &__card {
      padding-top: 8px;
      padding-bottom: 31px;
      border-top: 1px solid $gray-7;
      border-bottom: 1px solid $gray-3;

      display: flex;
      align-items: flex-end;
      justify-content: center;
      height: $head-card-height-sm;

      &:not(:last-child) {
        border-right: 1px solid $gray-3;
        @include media-breakpoint-up(lg) {
          border-right: none;
        }
      }
      @include media-breakpoint-up(md) {
        height: $head-card-height-md;
      }

      @include media-breakpoint-up(lg) {
        padding-top: 32px;
        padding-bottom: 31px;
        border-top: none;
        height: $head-card-height-lg;
      }

      &-1 {
        background-color: $white;
        @include media-breakpoint-up(lg) {
          background-color: $background-light-grey;
        }
      }

      &-badge {
        height: 33px;
        @include media-breakpoint-up(md) {
          height: inherit;
        }
        @include media-breakpoint-up(lg) {
          height: 33px;
        }
        span {
          border-radius: 100px;
          color: $color-base-gray-1300;
          letter-spacing: 1px;
          background: $color-base-green-secondary;
          padding: 7px 10px 5px;
          // not mobile first because oneHulu won't override

          @include media-breakpoint-up(md) {
            font-size: 12px;
            padding: 7px 15px 5px;
          }

          @include media-breakpoint-up(xl) {
            padding: 14px 27px;
          }
        }
      }

      &-content {
        width: 100%;
      }

      &-eyebrow,
      &-eyebrow-image {
        color: $gray-10;
        margin-top: 6px;
        margin-bottom: 14px;
        line-height: 1;
        font-size: 10px;
        text-transform: uppercase;
        @include media-breakpoint-up(md) {
          padding-top: 10px;
          margin-bottom: 5px;
          line-height: 1;
          font-size: 14px;
        }
      }
      &-eyebrow-image {
        margin-top: 2px;
        margin-bottom: 4px;
        @include media-breakpoint-up(md) {
          margin-top: 3px;
          padding-top: 4px;
        }
        @include media-breakpoint-up(lg) {
          margin-top: 4px;
        }
      }

      &-title {
        color: $gray-1;
        margin-bottom: 13px;
        font-weight: 600;
      }

      &-disclaimer {
        justify-content: center;
        margin-top: 8px;
        @include one-hulu-body-10;
        a {
          color: $text-link;
          text-decoration: underline;
        }
      }

      &--short {
        @include media-breakpoint-up(lg) {
          height: $head-card-height-no-badge;
        }
      }
    }
  }

  .plan-feature {
    &-item {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      border-bottom: 1px solid $gray-3;

      &:last-of-type {
        border-bottom: 1px solid $gray-7;
      }
    }

    &__title {
      display: flex;
      justify-content: center;
      min-width: 100%;
      padding: 13px 10px 0;
      text-align: center;

      @include media-breakpoint-up(lg) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        padding: 20px 0;
        min-width: 0;
      }

      img {
        padding-left: 6px;
      }

      &--pricing-row {
        @extend .plan-feature__title;
        font-size: 14px;

        span {
          font-size: 14px;
        }
      }
    }

    &__separator {
      display: none;
      width: 1px;
      height: 20px;
      background: $gray-3;

      @include media-breakpoint-up(md) {
        display: block;

        &:last-child {
          display: none;
        }
      }

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    &__bullet {
      img {
        height: 12px;
        @include media-breakpoint-up(lg) {
          height: 16px;
        }
      }

      &_default,
      &-standard {
        color: $gray-8;
      }

      &_text {
        &--strikethrough {
          color: $gray-12;
          display: block;
          text-decoration: line-through;
        }
      }
    }

    &__check {
      flex: 1;
      text-align: center;
      padding: 17px 10px;
      align-self: stretch;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      @include media-breakpoint-up(md) {
        padding: 7px 10px 17px;
      }

      @include media-breakpoint-up(lg) {
        padding: 20px 0;
      }

      &-1 {
        background-color: $white;
        @include media-breakpoint-up(lg) {
          background-color: $background-light-grey;
          align-self: stretch;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .plan-addon {
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s ease;

    &_show {
      display: block;
      max-height: 2000px;
    }

    &_controller {
      display: flex;
      height: 70px;
      border-top: 1px solid $gray-7;
      border-bottom: 1px solid $gray-7;
      justify-content: center;
      align-items: center;

      &:hover {
        cursor: pointer;
      }

      img {
        padding-left: 8px;
      }
    }

    &__head {
      text-align: center;
      justify-content: center;
      padding: 30px 0;
      border-bottom: 1px solid $gray-3;

      @include media-breakpoint-up(md) {
        padding: 20px 0 30px;
      }

      @include media-breakpoint-up(lg) {
        text-align: left;
        padding: 0;
        &-desktop {
          padding: 48px 0 18px;
        }
      }

      &-content {
        padding: 48px 0 16px;
        border-bottom: 1px solid $gray-7;
        @include media-breakpoint-up(lg) {
          display: none;
        }
      }

      &-title {
        color: $gray-1;
        margin-bottom: 12px;

        span {
          display: none;
        }
      }

      &-desc {
        @include one-hulu-body-14;
      }

      &-disclaimer {
        @include media-breakpoint-up(sm) {
          margin-bottom: 16px;
        }
      }

      &-0 {
        display: none;
        @include media-breakpoint-up(lg) {
          display: inline;
        }
      }

      &-1 {
        display: none;
        @include media-breakpoint-up(lg) {
          display: inline;
          background-color: $background-light-grey;
        }
      }

      &:last-child {
        @include media-breakpoint-up(lg) {
          background-color: $background-light-grey;
        }
      }
    }

    &-item {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      border-bottom: 1px solid $gray-3;
      &:last-of-type {
        border-bottom: none;
      }
    }

    &__check {
      text-align: center;
      padding: 17px 20px;
      flex: 1;
      align-self: stretch;
      display: flex;
      justify-content: center;
      align-items: center;
      @include media-breakpoint-up(lg) {
        padding: 20px 0;
      }

      &-1 {
        background-color: $white;
        @include media-breakpoint-up(lg) {
          background-color: $background-light-grey;
        }
      }
    }

    &__info-modal {
      color: $black-2;
      text-align: left;
    }

    &__header {
      margin: 0 auto 24px;
      color: $gray-6;
      @include one-hulu-subtitle-24;
    }
  }

  .plan-card {
    &__button-wrapper {
      text-align: center;
      margin: 20px auto 0;
      width: 100%;
      height: 40px;
      display: none;

      @include media-breakpoint-up(md) {
        max-width: 344px;
        display: block;
      }

      @include media-breakpoint-up(lg) {
        margin: 6px auto 0;
      }

      .button--cta {
        padding: 0;
        height: 40px;
        white-space: normal;
        font-size: 12px;
        @include media-breakpoint-up(md) {
          padding: 0 28px;
        }

        @include media-breakpoint-up(lg) {
          height: 48px;
        }
      }

      &--mobile {
        display: block;
        height: 40px;
        @include media-breakpoint-up(md) {
          display: none;
        }

        .button--cta {
          height: 40px;
          min-width: 100px;
          padding-left: 8px;
          padding-right: 8px;
          white-space: normal;
          font-size: 12px;
        }
      }
    }
  }

  &__bottom-container {
    margin: 29px 0px 0px 10px;
    height: 117px;
  }

  .plans-disclaimer__container {
    display: flex;
    align-items: center;
    &:first-of-type {
      margin-top: 24px;
    }
    &:last-of-type {
      margin-bottom: 60px;
    }
  }

  &__features-container {
    & > div {
      padding: 0px;
    }

    &__disclaimer {
      text-align: center;
      @include one-hulu-body-10;

      @include media-breakpoint-up(lg) {
        background-color: $white;
        text-align: left;
      }
    }

    &-col-0 {
      background-color: $white;

      @include media-breakpoint-up(lg) {
        background-color: $background-light-grey;
      }

      & > div {
        padding-top: 10px;
      }
    }

    &-col-1 {
      background-color: $white;

      @include media-breakpoint-up(lg) {
        background-color: $background-light-grey;
      }

      div:last-child {
        padding-bottom: 40px;
      }
    }
  }
}

.plans-container__features-container__disclaimer .section-disclaimer {
  color: $gray-1;
}

.plans-container__features-container__disclaimer {
  .section-disclaimer {
    a,
    a:hover,
    a:active,
    a:visited,
    a:focus {
      color: $gray-1;
      text-decoration: underline;
    }
  }
}
.is-dark {
  .plans-container__features-container__disclaimer {
    .section-disclaimer {
      a,
      a:hover,
      a:active,
      a:visited,
      a:focus {
        color: $white;
        text-decoration: underline;
      }
    }
  }
}

.plan-feature__check-not-highlighted,
.plan-head__card-not-highlighted {
  background-color: inherit;
}
.plan-feature__check-highlighted,
.plan-head__card-highlighted {
  @include media-breakpoint-up(lg) {
    background-color: $background-light-grey;
  }
}

.is-dark {
  &.plan-comparison-chart-with-toggle,
  .plans-container,
  .plan-head-container,
  .plans-container__features-container__disclaimer,
  .plans-container__features-container__disclaimer .section-disclaimer {
    background-color: $background-faux-black;
    color: $white;
    .modal-wrapper {
      color: $black;
    }
  }
  .plan-head__card-title,
  .plan-addon__head-title {
    color: $white;
  }
  .plan-head-container {
    border-top: 1px solid $gray-dimmed;
    &.--hide-toggle {
      border: none;
    }
  }
  .plan-head-column {
    @include media-breakpoint-up(lg) {
      border-bottom: 1px solid $gray-dimmed;
    }
  }
  .plan-head {
    &__card {
      border-top: 1px solid $gray-dimmed;
      border-bottom: 1px solid $gray-dimmed;
      &:not(:last-child) {
        border-right: 1px solid $gray-dimmed;
        @include media-breakpoint-up(lg) {
          border-right: none;
        }
      }
      @include media-breakpoint-up(lg) {
        border-top: none;
      }
      &-badge {
        span {
          background: $green;
        }
      }
      &-eyebrow {
        color: $gray-30;
      }
    }
  }
  .plan-feature {
    &-item {
      border-bottom: 1px solid $gray-dimmed;
      &:last-of-type {
        border-bottom: 1px solid $gray-dimmed;
      }
    }
    &__bullet_text {
      &--strikethrough {
        color: $color-base-gray-500;
      }
    }
  }
  .plan-addon {
    &__head {
      border-bottom: 1px solid $gray-dimmed;
    }
    &_controller {
      border-top: 1px solid $gray-dimmed;
      border-bottom: 1px solid $gray-dimmed;
    }
    &-item {
      border-bottom: 1px solid $gray-dimmed;
      &:last-of-type {
        border-bottom: none;
      }
    }
  }

  .plan-feature__check-not-highlighted,
  .plan-head__card-not-highlighted {
    background-color: inherit;
  }
  .plan-feature__check-highlighted,
  .plan-head__card-highlighted {
    @include media-breakpoint-up(lg) {
      background-color: $gray-11;
    }
  }
}
