@import '../../../styles/global';
@import '../../../styles/breakpoints';
@import '../../../styles/mixins';

.HeroSliderMasthead {
  &__head {
    background-color: $white;
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 450px;
    overflow: hidden;
    @include z-index(low);
    @extend %gradient-skrim;

    @include media-breakpoint-up(md) {
      height: 432px;
    }

    @include media-breakpoint-up(lg) {
      height: 480px;
    }

    @include media-breakpoint-up(xxl) {
      height: 496px;
    }
  }

  &__hero {
    width: 100vw;
    position: relative;
    left: 0;
    top: 0;
  }

  &__buttonSection {
    display: flex;
    @include media-breakpoint-down(sm) {
      gap: 18px;
    }
    @include media-breakpoint-up(md) {
      flex-direction: row;
      gap: 10px;
    }
  }

  &__heros {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__items {
    width: 100%;
    height: 100%;
    display: flex;
    transition: transform 0.5s ease;

    .MastheadTile__thumbnail {
      height: 100%;
    }

    .MastheadTile__spacer {
      padding-bottom: 0;
    }
  }

  &__accent-bar {
    bottom: 0;
    border-bottom: 4px;
    border-bottom-style: solid;
    left: 0;
    position: absolute;
    width: 100%;
  }

  &__item {
    opacity: 0;
    flex: 0 0 100%;
    transition: opacity 1s ease-in-out;

    &--active {
      opacity: 1;
    }

    .MastheadTileBackground__art img {
      height: auto;
      width: 100%;

      @include media-breakpoint-up(xl) {
        position: absolute;
        top: -25%;
      }
    }

    .MastheadTileBackground__gradient {
      display: block;
    }

    .MastheadTileBackground__scrim {
      background: linear-gradient(
        180deg,
        hsla(0, 0, 0, 0.4) 0%,
        hsla(0, 0, 0, 0) 100%
      );
    }
  }

  &__content {
    @include one-hulu-body-14;

    align-items: center;
    color: $white;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    padding: 0 20px;
    position: absolute;
    top: 0;
    width: 100%;

    @include media-breakpoint-up(md) {
      align-items: flex-start;
      margin: 0 0 0 76px;
      padding: 0;
      width: 403px;
    }

    @include media-breakpoint-up(lg) {
      width: 476px;
    }

    @include media-breakpoint-up(xl) {
      margin: 0 0 0 140px;
    }

    .button--cta {
      margin-top: 12px;

      @include media-breakpoint-down(xs) {
        padding: 0 18px;
        &:active,
        &:focus,
        &:hover {
          padding: 0 18px; /* Need to override Hulu Web UI Button padding of 24px on both default and state changes */
        }
      }

      @include media-breakpoint-up(md) {
        margin-top: 20px;
      }
    }
  }

  &__description {
    @include one-hulu-body-14;

    position: relative;
    margin: 12px 0;
    text-align: center;

    @include media-breakpoint-up(md) {
      text-align: left;
      margin: 20px 0;
    }

    @include media-breakpoint-up(lg) {
      @include one-hulu-body-16;
    }
  }

  &__disclaimer {
    @include one-hulu-body-10;
    margin-top: 10px;
    position: relative;

    @include media-breakpoint-up(md) {
      @include one-hulu-body-12;
    }
  }

  &__legal {
    @include one-hulu-body-10;
    margin-top: 8px;
    position: relative;

    @include media-breakpoint-up(md) {
      @include one-hulu-body-12;
    }
  }

  &__networkContainer {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    padding: 5px 0;
    position: relative;

    @include media-breakpoint-up(md) {
      justify-content: flex-start;
      margin-top: 0;
      width: 540px;
    }

    img {
      max-height: 50px;
      max-width: 200px;
      text-indent: 10px;

      @include media-breakpoint-up(md) {
        max-height: 62px;
        max-width: 220px;
      }
    }

    span {
      text-align: center;

      @include media-breakpoint-up(md) {
        text-align: left;
      }
    }
  }

  &__showName {
    bottom: 16px;
    color: $white;
    left: 20px;
    opacity: 0.8;
    position: absolute;

    @include media-breakpoint-up(md) {
      bottom: 32px;
      left: 76px;
    }

    @include media-breakpoint-up(lg) {
      bottom: 40px;
    }

    @include media-breakpoint-up(xl) {
      left: 140px;
    }
  }
}
