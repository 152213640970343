/** @define Tile */

@import '~@hulu/site-styles/variables';
@import '~@hulu/site-styles/screen_xl_variables';
@import '~@hulu/site-styles/colors';
@import '~@hulu/site-styles/font_utils';
@import '~@hulu/site-styles/typography';
@import '~@hulu/web-ui/generated/_variables.scss';
@import '../../../styles/h3o-components';
@import '../../../styles/breakpoints';
@import '../../../styles/mixins';

$vessel-stroke: $component-tile-vessel-stroke-width + px;
$vessel-padding: $component-tile-vessel-padding + px;
$tile-vessel-offset: calc(#{$vessel-stroke} + #{$vessel-padding});
$animation-duration: 0.35s;

.Tile {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: $tile-vessel-offset;

  &__content {
    position: relative;

    &--with-menu {
      padding-right: 13px;
    }
  }

  &__metadata {
    list-style: none;
    opacity: 0;
    padding-left: 0;
  }

  &__meta {
    display: inline;
    border-right: 1px solid $gray-5;
    padding: 0 5px;
  }

  &__meta:last-child {
    border-right: none;
  }

  &__meta:first-child {
    padding: 0 5px 0 0;
  }

  &__action {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-bottom: 6px;

    &-container {
      position: relative;
    }

    &--hover {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__title {
    margin: 0 0 8px;
    color: $color-base-black;
    padding: 0;
    -webkit-text-stroke-width: 0.35px;
    -webkit-text-stroke-color: $text-stroke-dark-transparent;
    transition: -webkit-text-stroke-color 0.3s ease;

    &-link {
      display: inline-block;
      color: $gray-1;
      -webkit-text-stroke-width: 0.35px;
      -webkit-text-stroke-color: $text-stroke-dark-transparent;
      transition: -webkit-text-stroke-color 0.3s ease;

      mark { /* stylelint-disable-line */
        @include Medium-Font;
        padding: 0;
        background-color: $white;
      }
    }

    &--clickable:hover { /* stylelint-disable-line */
      .Tile__title-link {
        color: $gray-1;
        -webkit-text-stroke-color: $text-stroke-dark-opaque;
      }
    }
  }

  &__metadata,
  &__description {
    color: $color-base-black;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
  }

  &__rollover,
  &__controls {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
  }

  &__rollover {
    opacity: 0;
    transition: opacity 0.5s ease;
  }

  &__controls {
    background-color: $transparent-black;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $component-tile-emphasis-standard-border-radius + px;
  }

  &__icon {
    width: 40%;
    height: 40%;
    max-width: 64px;
    font-size: 0;
    color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    svg { /* stylelint-disable-line */
      height: 100%;
      width: 100%;

      #circle { /* stylelint-disable-line */
        stroke-dasharray: 200;
        stroke-dashoffset: 200;
        transition: stroke-dashoffset 0.4s ease;
      }
    }

    &--retina {
      svg { /* stylelint-disable-line */
        margin: 0 auto;
        display: block;
        height: 100%;

        //To get deisired width we must multiple by this fraction becuase of the width-ratio mismatch in the svg file
        width: calc(72px * 30 / 29);

        @media (min-width: $screen-md-min) {
          width: calc(80px * 30 / 29);
        }

        @media (min-width: $screen-lg-min) {
          width: calc(88px * 30 / 29);
        }

        @media (min-width: $screen-xl-min) {
          width: calc(96px * 30 / 29);
        }
      }
    }
  }

  &__image,
  &__rollover-image {
    width: 100%;
    height: auto;
    border-radius: $component-tile-emphasis-standard-border-radius + px;
  }

  &__image {
    position: absolute;
    top: 0;
    @include lazyLoad(124px);
  }

  &__placeholder-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(//assetshuluimcom-a.akamaihd.net/h3o/icons/tile-fallback.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: $component-tile-emphasis-standard-border-radius + px;
  }

  &__scrim {
    background: $hulu-scrim;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $component-tile-emphasis-standard-border-radius + px;
  }

  &__scrim--thumbnail {
    background: $thumbnail-scrim;
    border-radius: $component-tile-emphasis-standard-border-radius + px;
  }

  &__scrim-text {
    color: $white;
    text-align: center;
    padding: 24px;
    opacity: 0.87;
  }

  &__brand {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 12px;
    pointer-events: none;
    width: 50px;
    height: 50px;

    @media (max-width: $screen-md-min) {
      margin: 10px;
    }
  }

  &__upcoming {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 6px;
    height: 16px;
    pointer-events: none;
    display: flex;
    justify-content: start;
    align-items: center;

    @include media-breakpoint-up(md) {
      margin: 10px 13px;
    }
  }


  &__badge {
    position: absolute;
    margin: 12px;
    padding: 6px;
    cursor: pointer;
    pointer-events: none;

    @media (max-width: $screen-md-min) {
      margin: 10px;
    }

    &--status {
      top: 0;
      right: unset;
    }

    &--time {
      bottom: 0;
      left: 0;
    }

    &--duration {
      background-color: $badge-gray-background;
    }

    &--reair {
      border: 1px solid $badge-border;
      padding: 4px;
      background: $white;
      color: $black;
    }

    &--live {
      background-color: $badge-gray-background;
      background-image: url(//assetshuluimcom-a.akamaihd.net/h3o/icons/thunderbolt-icon.svg);
      background-repeat: no-repeat;
      background-position: 5px 50%;
      background-size: 19%;
      padding-left: 19px;
    }

    &--new {
      border: 1px solid $badge-border;
      background: $white;
      padding: 4px;
      color: $black;
    }

    &--recorded {
      background: $recorded-red;
      background-image: url(//assetshuluimcom-a.akamaihd.net/h3o/icons/tick-icon.svg);
      background-repeat: no-repeat;
      background-position: 5px 48%;
      background-size: 22%;
      padding-left: 22px;
      color: $white;
    }
  }

  &--dark {
    .Tile {
      &__title {
        color: $white;
        -webkit-text-stroke-color: $text-stroke-light-transparent;

        &--clickable:hover { /* stylelint-disable-line */
          -webkit-text-stroke-color: $text-stroke-light-opaque;
        }
      }

      &__action,
      &__description,
      &__metadata {
        color: $tile-transparent-white;
      }

      &__meta {
        border-right: 1px solid $transparent-white;
      }

      &__meta:last-child {
        border-right: none;
      }
    }
  }

  &__statusBar {
    position: absolute;
    bottom: 0;
  }

  &__thumbnail {
    display: block;
    position: relative;
    margin-bottom: $component-tile-textual-metadata-offset + px;
    width: 100%;
    background: $transparent-gray;
    overflow: hidden;
    border-radius: $component-tile-emphasis-standard-border-radius + px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
    }
  }

  &__thumbnail--with-hover {
    overflow: unset;
    .Tile__rollover {
      border-radius: $component-tile-emphasis-standard-border-radius + px;

      // vessel
      &::before {
        position: absolute;
        content: '';
        left: calc(#{$tile-vessel-offset} * -1);
        top: calc(#{$tile-vessel-offset} * -1);
        right: calc(#{$tile-vessel-offset} * -1);
        bottom: calc(#{$tile-vessel-offset} * -1);
        border: $component-tile-vessel-stroke-width + px solid $color-base-gray-700;
        border-radius: $component-tile-vessel-border-radius + px;
        transition: opacity $animation-duration ease-out;
        opacity: 0;
      }
    }
    &:hover {
      .Tile__rollover {
        opacity: 1;
        // vessel
        &::before {
          opacity: 1;
          transition: none;
        }
        svg {
          #circle { /* stylelint-disable-line */
            stroke-dashoffset: 0;
          }
        }
      }

      + .Tile__content {
        .Tile__action--base {
          opacity: 0;
        }

        .Tile__metadata,
        .Tile__action--hover {
          opacity: 1;
        }
      }
    }
  }

  &__spacer {
    width: 100%;
    padding-bottom: 56.25%;
  }

  &__thumbnail--no-hover {
    cursor: default;
  }
}

