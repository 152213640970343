@import '../../../styles/colors';

.supported-devices {
  &--device-title {
    color: $text-white;
    margin: 5px auto;
  }
  &--device-subtitle {
    color: $gray-28;
  }
}
