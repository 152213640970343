@import '~@hulu/site-styles/variables';
@import '~@hulu/site-styles/screen_xl_variables';
@import '~@hulu/site-styles/colors';
@import '~@hulu/site-styles/font_utils';
@import '~@hulu/site-styles/typography';
@import '../../../styles/colors';
@import '../../../styles/mixins';

$content-padding: 24px;
$content-padding-lg: 32px;

.MastheadTileBackground {
  &__wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    transition: transform 0.4s ease, box-shadow 0.4s ease;
  }
  &__base,
  &__art,
  &__gradient,
  &__scrim {
    // expand these slightly outside of their container so they aren't subject
    // to aliasing around the edges during subpixel width remainders and during
    // scaling transitions
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
  }
  &__gradient {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  &__art {
    // needs to be expanded to fit the container
    width: calc(100% + 4px);
    opacity: 0.96;
    transition: opacity 1s, transform 12s cubic-bezier(0.23, 1, 0.32, 1);
  }
}
