@import '../../../styles/breakpoints';
@import '../../../styles/mixins';

.toaster {
  display: block;
  position: fixed;
  width: 100%;
  bottom: 0;
  text-align: center;
  @include z-index(middle);
  transition: transform .5s ease;
  transform: translateY(150%);
  visibility: hidden;

  &.toaster--mobile-only {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &--active {
    visibility: visible;
    &:not(.toaster--mobile-only) {
      transform: translateY(0);
    }

    &.toaster--mobile-only {
      @include media-breakpoint-down(md) {
        transform: translateY(0);
      }
    }
  }
}
