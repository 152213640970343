@import '../../../../styles/breakpoints';
@import '../../../../styles/colors';
@import '../../../../styles/mixins';

.exp-no-fees-minimalist {
  background-color: $black;
  color: $white;
  font-family: Graphik;
  padding: 60px 15px;

  * {
    box-sizing: border-box;
  }

  &__title {
    display: flex;
    justify-content: center;
    margin-bottom: 64px;
    text-align: center;
    flex-direction: column;

    span {
      font-size: 28px;
      font-weight: bold;
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
      gap: 8px;

      span {
        font-size: 40px;
      }
    }

    @include media-breakpoint-up(lg) {
      span {
        font-size: 56px;
      }
    }
  }

  &__disclaimer {
    font-size: 10px;
    width: 100%;
    text-align: center;
    font-weight: 300;

    @include media-breakpoint-up(md) {
      font-size: 12px;
      text-align: left;
    }
  }

  &__table-container {
    margin: auto;
    max-width: 400px;

    @include media-breakpoint-up(md) {
      max-width: 677px;
    }
  }

  &__table {
    margin-bottom: 64px;

    @include media-breakpoint-up(md) {
      margin-bottom: 30px;
    }
  }

  &__header {
    display: flex;
    border-bottom: 1px solid $gray-3;

    .highlighted {
      background: linear-gradient(
        90deg,
        rgba(65, 173, 120, 0.4) -6.92%,
        rgba(65, 173, 120, 0.08) 39.62%,
        rgba(65, 173, 120, 0) 83.35%
      );
      border: 2px solid $green;
      border-bottom: none;
      box-sizing: border-box;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .first-column {
      width: 40%;

      @include media-breakpoint-up(md) {
        width: 45%;
      }
    }
  }

  &__row {
    display: flex;

    .highlighted {
      background: linear-gradient(
        90deg,
        rgba(65, 173, 120, 0.4) -6.92%,
        rgba(65, 173, 120, 0.08) 39.62%,
        rgba(65, 173, 120, 0) 83.35%
      );
      border-left: 2px solid $green;
      border-right: 2px solid $green;
      box-sizing: border-box;
    }

    .highlighted.last-row {
      border-bottom: 2px solid $green;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .first-column {
      width: 40%;
      padding-left: 0;

      span {
        @include media-breakpoint-up(md) {
          font-weight: 500;
        }
      }

      @include media-breakpoint-up(md) {
        width: 45%;
      }
    }

    .hulu-column {
      width: 25%;
      padding-left: 20px;

      span {
        @include media-breakpoint-up(md) {
          font-weight: 400;
        }
      }

      @include media-breakpoint-up(md) {
        width: 20%;
        padding-left: 30px;
      }
    }

    .cable-column {
      padding-left: 20px;

      @include media-breakpoint-up(md) {
        padding-left: 30px;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid $gray-3;
    }
  }

  &__column {
    width: 33%;
    line-height: 16px;
    font-size: 10px;
    padding: 6px 13px;

    &--header {
      width: 33%;
      line-height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px;

      span {
        font-size: 11px;
        font-weight: 500;

        @include media-breakpoint-up(sm) {
          font-size: 12px;
        }

        @include media-breakpoint-up(md) {
          font-size: 23px;
          line-height: 31px;
          font-weight: bold;
        }
      }

      .hulu-white-logo {
        width: 55px;
        height: 21px;

        @include media-breakpoint-up(md) {
          width: 75px;
          height: 27px;
        }
      }

      .live-tv-text {
        font-size: 12px;
        font-weight: 500;

        @include media-breakpoint-up(md) {
          font-size: 16px;
        }
      }

      &.first-column {
        padding-left: 0;
      }

      &.hulu-column {
        width: 25%;

        @include media-breakpoint-up(md) {
          width: 20%;
        }
      }

      &.cable-column {
        padding-left: 12px;

        span {
          text-transform: uppercase;

          @include media-breakpoint-up(md) {
            text-transform: none;
            word-break: normal;
          }
        }

        @include media-breakpoint-up(md) {
          padding-left: 30px;
        }
      }

      @include media-breakpoint-up(md) {
        padding: 24px 10px;
      }
    }

    .dollar-icon {
      width: 15px;
      height: 20px;

      @include media-breakpoint-up(md) {
        width: 20px;
        height: 25px;
      }
    }

    span {
      @include media-breakpoint-up(md) {
        font-size: 16px;
      }
    }

    @include media-breakpoint-up(md) {
      font-size: 16px;
      padding: 12px 10px;
      align-items: center;
      display: flex;
      line-height: 25px;
    }
  }
}
