@import '../../../styles/global';

$indicator_animation_duration: 0.3s;

.nav {
  @extend .container--wide;
  display: block;
  position: relative;

  &--centered {
    text-align: center;
  }

  &__items {
    display: inline-block;
    position: relative;
    white-space: nowrap;
    transition: transform $indicator_animation_duration ease-out, width $indicator_animation_duration ease-out;
  }

  &__accent-bar {
    display: block;
    height: 4px;
    transition: transform $indicator_animation_duration ease-out, width $indicator_animation_duration ease-out;
  }
}
