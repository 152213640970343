@import '../../../styles/breakpoints';
@import '../../../styles/mixins';
@import '../../../styles/global';

.Toaster {
  display: block;
  position: fixed;
  width: 100%;
  bottom: 0;
  text-align: center;
  visibility: hidden;
  @include z-index(middle);
  transition: transform 0.5s ease, visibility 0.8s ease;
  transform: translateY(150%);
  @include media-breakpoint-up(md) {
    display: none;
  }
  &--active {
    visibility: visible;
    transform: translateY(0);
  }
  &__info {
    font-size: 12px;
    margin-bottom: 4px;
    font-weight: normal;
    line-height: 18px;
    opacity: 0.8;
    text-transform: none;
    letter-spacing: unset;
  }
  &__scrim {
    height: 110px;
    display: flex;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );
    align-items: center;
    justify-content: center;
  }

  &__text {
    margin: 0;
  }

  &__button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60px !important;
    transition: unset;
    transform: unset;
    width: calc(100% - 40px);
    transform: translateY(0);
    &:hover {
      transform: unset;
    }
    span {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
