@import '~@hulu/site-styles/variables';
@import '~@hulu/site-styles/screen_xl_variables';
@import '~@hulu/site-styles/colors';
@import '~@hulu/site-styles/font_utils';
@import '~@hulu/site-styles/typography';
@import '../../styles/breakpoints';
@import '../../styles/colors';
@import '../../styles/mixins';

.library {
  background-color: $black;
  position: relative;

  &__header {
    color: $white;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;

    @include media-breakpoint-up(md) {
      margin: 0 auto;
      max-width: 80%;
    }

    @include media-breakpoint-up(lg) {
      max-width: 786px;
    }

    @include media-breakpoint-up(xl) {
      max-width: 996px;
    }

    @include media-breakpoint-up(xxl) {
      max-width: 1236px;
    }
  }

  &__content {
    text-align: center;
    padding: 50px 20px;

    @include media-breakpoint-up(md) {
      padding-top: 88px;
    }

    @include media-breakpoint-up(lg) {
      text-align: center;
      margin-top: 0;
    }
  }

  &__title {
    margin: 16px 0 10px;
  }

  &__description {
    @include media-breakpoint-up(lg) {
      max-width: 680px;
      margin: 0 auto;
      padding-top: 16px;
    }

    @include media-breakpoint-up(xl) {
      max-width: 792px;
    }
  }

  &__grid-container {
    background-color: $black;
    padding: 0 20px 60px;
    position: relative;
    z-index: 2;
    max-width: 786px;
    margin: 0 auto;

    @include media-breakpoint-up(xl) {
      max-width: 100%;
      padding: 0 0 100px;
    }
  }

  &__grid {
    display: grid;
    column-gap: 20px;
    row-gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    @include media-breakpoint-up(xl) {
      justify-content: center;
      grid-template-columns: repeat(4, 295px);
      grid-template-rows: auto;
    }
  }

  &__grid-item {
    button {
      font-family: Graphik;
      background: none;
      margin: 0;
      padding: 0;
      border: none;
      color: $white;
      cursor: pointer;
    }
  }

  &__item-wrapper {
    position: relative;
    text-align: left;

    &--automated {
      &:hover {
        .library {
          &__item-overlay {
            @include media-breakpoint-up(md) {
              margin-bottom: 20px;
              transition: 0.3s ease-in-out;
            }
          }
        }
      }

      .library {
        &__grid-item {
          margin: auto;
        }

        &__item-gradient {
          opacity: 0;
          position: absolute;
        }

        &__item-background {
          object-fit: cover;
          @include lazyLoad(100px);
        }

        &__item-gradient,
        &__item-scrim,
        &__item-background {
          @include media-breakpoint-up(xs) {
            height: 252px;
            width: 157px;
          }
          @media screen and (min-width: 490px) {
            height: 340px;
            width: 212px;
          }
          @media screen and (min-width: 660px) {
            height: 450px;
            width: 294px;
          }
          @include media-breakpoint-up(md) {
            height: 542px;
            width: 354px;
          }
          @include media-breakpoint-up(xl) {
            height: 450px;
            width: 294px;
          }
        }

        &__item-overlay {
          top: auto;
          left: auto;
          bottom: 0;
          width: 100%;
          @include media-breakpoint-up(md) {
            transition: 0.3s ease-in-out;
          }
        }

        &__item-eyebrow {
          @include media-breakpoint-down(sm) {
            font-size: 7px;
            font-weight: 500;
            line-height: 7px;
            letter-spacing: 0.32px;
          }
        }

        &__item-category {
          @include media-breakpoint-down(sm) {
            font-size: 17px;
            font-weight: 600;
            line-height: 20.4px;
            letter-spacing: -0.11px;
            padding-bottom: 8px;
            padding-top: 6px;
          }
        }

        &__item-title {
          @include media-breakpoint-up(xs) {
            font-size: 7px;
            font-weight: 400;
            line-height: 11px;
            margin-bottom: 0;
            padding-right: 25px;
          }
          @include media-breakpoint-up(sm) {
            padding-right: 40px;
          }
          @include media-breakpoint-up(md) {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 19px;
            padding-right: 50px;
          }
        }

        &__item-background-brand-logo {
          position: absolute;
          bottom: 0;
          right: 0;
          @include media-breakpoint-up(xs) {
            width: 30px;
            margin-bottom: 16px;
            margin-right: 10px;
          }
          @include media-breakpoint-up(md) {
            width: 45px;
            margin-right: 21px;
            margin-bottom: 39px;
          }
        }
      }
    }
  }

  &__item-gradient {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
        156.82deg,
        rgba(0, 0, 0, 0.6) 4.58%,
        rgba(0, 0, 0, 0) 69.61%
      ),
      linear-gradient(
        24.5deg,
        rgba(0, 0, 0, 0.2) 4.71%,
        rgba(0, 0, 0, 0) 71.49%
      );
  }

  &__item-background {
    width: 100%;
    object-fit: contain;
  }

  &__item-overlay {
    position: absolute;
    top: 0;
    left: 0;
    padding: 15px;
    @include media-breakpoint-up(md) {
      padding: 25px;
    }
  }

  &__item-category {
    color: $white;
  }

  &__item-eyebrow {
    margin-bottom: 5px;
    @include media-breakpoint-up(md) {
      margin-bottom: 10px;
    }
  }

  &__item-title {
    margin-top: 0;
  }

  &__item-legal {
    padding-top: 10px;
    display: block;
    color: $white;
  }

  .poster--hover-rounded-corners {
    @include media-breakpoint-between(md, lg) {
      padding: 0 4px 45px;
    }

    .library {
      &__item-background {
        border-radius: 10px;
      }
      &__item-gradient,
      &__item-scrim {
        border-radius: 10px;
      }
      @include media-breakpoint-up(xl) {
        &__grid {
          grid-template-columns: repeat(4, 311px);
        }
      }

      @include media-breakpoint-up(md) {
        &__item-gradient,
        &__item-scrim {
          top: 4px;
          bottom: 4px;
          left: 4px;
          right: 4px;
        }

        &__item-wrapper {
          border: 4px solid rgba(255, 255, 255, 0);
          border-radius: 16px;
          padding: 4px;

          &:hover {
            border-color: $gray-31;
            transition: ease-out 0.3s;

            .library__item-gradient {
              background: $black-3;
              opacity: 0.6;
            }
          }
        }
      }
    }
  }
}
