@import "~@hulu/site-styles/variables";
@import '~@hulu/site-styles/font_utils';
@import '../../styles/breakpoints';
@import '../../styles/mixins';
@import '../../styles/colors';

.ZipCodeInput {
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;

  &__submitButton {
    width: 48px;
    height: 48px;
    border-radius: 4px;
    background-color: $cta-button-black;
    color: $white;
    border: 0;
    float: left;
    @include one-hulu-eyebrow-16;

    &:hover {
      background-color: $cta-button-gray;
    }
  }

  &__invalidZip {
    text-align: left;
    color: $color-base-red;
  }

  &__searchIcon {
    @include one-hulu-title-24;
  }

  &__input {
    width: calc(100% - 56px);
    margin-right: 8px;
    height: 48px;
    padding: 8px 12px;
    background-color: $gray-24;
    background-image: none;
    border: 1px solid $gray-25;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    transition: border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s;
    float: left;
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $text-placeholder;
      opacity: 1; /* Firefox */
    }
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $text-placeholder;
    }
    &::-ms-input-placeholder { /* Microsoft Edge */
      color: $text-placeholder;
    }
  }

  .error {
    border-color: $color-base-red;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  }

  &__area {
    margin-top: 16px;
    color: $gray-26;
    line-height: 136%;
    text-align: left;
  }

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  &__loadingIcon {
    display: inline-block;
    width: 25px;
    height: 25px;
    position: relative;
    margin-left: 10px;
    margin-top: 8px;

    &::before {
      content: '';
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 25px;
      height: 25px;
      margin-top: -10px;
      margin-left: -10px;
      border-radius: 50%;
      border: 3px solid $hulu-brand-dark;
      border-top-color: $color-base-green-tertiary;
      animation: spinner 0.6s linear infinite;
    }
  }
}

