@import '../../../styles/breakpoints';
@import '../../../styles/mixins';
@import '../../../styles/colors';

.tophat {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 74px;
  color: $text-body;
  text-align: center;
  position: relative;
  background-color: $white;

  &__button {
    position: absolute;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    color: $gray-29;
    padding: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    transition: opacity .25s ease-in-out;
    @include one-hulu-title-48;

    @include media-breakpoint-up(md) {
      right: 15px;
    }

    &:hover {
      opacity: .8;
    }
  }

  &__body {
    @include one-hulu-body-14;
    padding: 10px 70px;

    @include media-breakpoint-up(md) {
      padding: 0 64px;
      @include one-hulu-body-18;
    }
  }

  &__prefix {
    color: $green2;
  }

  &__suffix,
  a {
    color: $text-body;
    border-bottom: 1px solid $text-body;
    text-decoration: none;

    &:hover {
      color: $text-body;
      text-decoration: none;
    }
  }
}