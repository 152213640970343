.banner {
  margin: 0;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1001;
  top: 0;
}

.banner-enter {
  transform: translateY(-100%);
}
.banner-enter.banner-enter-active {
  transform: translateY(0%);
  transition: transform 500ms ease-out;
}
.banner-leave {
  transform: translate(0%);
}
.banner-leave.banner-leave-active {
  transform: translateY(-100%);
  transition: transform 500ms ease-in;
}
