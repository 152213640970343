@import '~@hulu/site-styles/font_utils';
@import '../../../styles/breakpoints';
@import '../../../styles/mixins';
@import '../../../styles/colors';

.plan-container {
  margin: 0 auto;
  padding: 40px 0 !important;
  @include media-breakpoint-down(sm) {
    padding: 40px 20px !important;
  }

  &__header {
    text-align: center;
  }

  &__headline {
    color: $gray-6;
    margin: 0 auto 16px;

    &--brand {
      @include Black-Font;
      text-transform: uppercase;
    }
    @include media-breakpoint-down(sm) {
      margin-bottom: 10px;
    }
  }

  &__description {
    margin: 0 auto;
    color: $text-body;
    @include media-breakpoint-down(sm) {
      margin: 5px auto 0;
    }
  }

  &__row {
    display: flex;
    margin: 40px auto 0;
    justify-content: center;
    align-items: flex-start;
    @include media-breakpoint-down(md) {
      padding: 0 10px;
    }
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: stretch;
      margin: 10px auto auto;
    }
  }

  &__disclaimer {
    margin: 30px 0 10px;
    text-align: center;
    @include one-hulu-body-10;
  }

  &__has-three {
    @include media-breakpoint-down(md) {
      padding: 0px 15px;
    }

    @include media-breakpoint-between(md, lg) {
      padding: 0px 40px;
    }

    @include media-breakpoint-between(xl, xxl) {
      padding: 0px 70px;
    }

    @include media-breakpoint-up(xxl) {
      padding: 0px 128px;
    }
  }
}
