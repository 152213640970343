@import '~@hulu/site-styles/font_utils';
@import '../../../styles/breakpoints';
@import '../../../styles/colors';

.tier-modal {
  &__parent {
    &.modal-dialog {
      @include media-breakpoint-up(md) {
        width: 616px;
      }
    }
  }

  &__name {
    color: $gray-6;
    margin: 0 auto 24px;
  }

  &__pricing {
    color: $gray-1;
    margin-bottom: 24px;
  }

  &__body {
    color: $gray-5;
    margin-bottom: 16px;
  }

  &__logo-wrapper {
    height: 88px;
  }
}
