@import '../../../styles/global';
@import '../../../styles/breakpoints';
@import '../../../styles/colors';

.CollectionTabs {
  border-bottom: 1px solid $accent-grey;

  .tabs__container {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }

  .nav {
    background-color: $white;

    .nav-item {
      margin-right: 40px;
    }
  }

  .tab {
    @extend .container--wide;
    padding-bottom: 32px;
    background-color: $text-white;

    @include media-breakpoint-up(md) {
      padding-bottom: 4px;
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: 24px;
    }
  }

  &--withHeroSlider {
    .nav__accent-bar {
      // To override inline styling
      background-color: $gray-11 !important;
    }
  }
}
