.LoginForm {
  &__dialog {
    margin: 40px auto;
    position: relative;

    .login-page-modal {
      height: auto;
    }

    .auth-login-mobile {
      overflow: auto;
    }
  }
}
