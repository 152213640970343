@import '../../../../styles/mixins';
@import '../../../../styles/colors';

.live-tv-comp-chart {
  padding: 24px 0;
  padding-bottom: 40px;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-up(md) {
    padding: 24px 24px 40px 24px;
  }

  .headline {
    padding: 24px;
    color: white;
    text-align: center;

    @include media-breakpoint-up(md) {
      padding: 16px 0;
    }

    .title {
      padding-bottom: 16px;
      font-size: 28px;

      @include media-breakpoint-up(md) {
        font-size: 40px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 56px;
      }
    }

    .subtitle {
      font-weight: 400;
      padding-bottom: 28px;

      @include media-breakpoint-up(md) {
        padding-bottom: 56px;
      }

      @include media-breakpoint-up(lg) {
        padding-bottom: 72px;
      }
    }
  }

  & table,
  th,
  td {
    color: white;
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;

    @include media-breakpoint-up(md) {
      width: auto;
      table-layout: auto;
    }

    .empty-header-cell {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .hulu-column {
      background-color: $gray-32;
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  & tr {
    box-sizing: border-box;
    border-bottom: 1px solid $color-base-gray-800;
  }

  & thead tr {
    position: sticky;
    top: 74px;
    background-color: black;
    border-bottom: none;

    @include media-breakpoint-up(md) {
      border-bottom: 1px solid $color-base-gray-800;
    }

    & th {
      box-shadow: inset 0 1px 0 $toggler-background,
        inset 0 -1px 0 $toggler-background, inset 1px 0 0 $toggler-background;

      &:nth-child(2) {
        box-shadow: inset 0 1px 0 #999, inset 0 -1px 0 #999;
      }

      @include media-breakpoint-up(md) {
        box-shadow: none;

        &:nth-child(2) {
          box-shadow: none;
        }
      }
    }

    @include media-breakpoint-up(md) {
      & th:nth-child(2) {
        background-color: $gray-32;
      }
    }
  }

  .column_header_cell {
    padding: 16px 8px;

    @include media-breakpoint-up(md) {
      padding: 16px;
    }

    @include media-breakpoint-up(lg) {
      padding: 44.5px;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-items: center;
      gap: 24px;

      .badge {
        background-color: $brand-green;
        border-color: $brand-green;
        border-radius: 32px;
        padding: 10px 8px;
        color: $gray-910;
        height: 32px;

        @include media-breakpoint-up(md) {
          padding: 10px 16px;
        }

        @include media-breakpoint-up(lg) {
          padding: 10px 24px;
        }
      }

      .cta {
        @include media-breakpoint-down(md) {
          padding: 14px 8px;
        }

        @include media-breakpoint-down(sm) {
          height: 40px;
        }
      }
    }
  }

  .row-header-desktop {
    text-align: left;
    padding: 24px;
    padding-left: 0;

    display: none;
    flex-direction: column;
    gap: 8px;

    @include media-breakpoint-up(md) {
      display: flex;
    }
  }

  .row-header-mobile {
    border-bottom: 0;

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;

      padding: 12px 16px;
    }

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  .cell {
    text-align: center;

    @include media-breakpoint-down(sm) {
      padding: 20px;
    }
  }
}
