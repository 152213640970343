@import '../../../../styles/breakpoints';
@import '../../../../styles/colors';
@import '../../../../styles/global';

.exp-plan-builder-escape-hatch-footer {
  background-color: $white;
  overflow: hidden;
  width: 100%;
  z-index: 1;
  position: fixed;
  bottom: 0;
  &.--expanded {
    bottom: 0;
  }
  .top-row {
    display: flex;
    flex-direction: column;
    padding: 20px 24px;
    @include media-breakpoint-up(md) {
      padding: 20px 24px;
      flex-wrap: wrap;
      flex-direction: row;
    }
    @include media-breakpoint-up(lg) {
      padding: 20px 40px;
      flex-wrap: wrap;
      flex-direction: row;
    }
    &.--expanded {
      max-height: 375px;
      padding: 24px;
      @include media-breakpoint-up(md) {
        padding: 24px;
      }
      @include media-breakpoint-up(lg) {
        padding: 24px 40px;
      }
    }
    .title-with-price {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      width: 100%;
      @include media-breakpoint-up(md) {
        flex: 6 6 100%;
        flex-wrap: wrap;
        align-content: center;
        &.--expanded {
          flex: 6 6 65%;
        }
      }
      @include media-breakpoint-up(lg) {
        &.--expanded {
          flex: 6 6 75%;
        }
      }
      @include media-breakpoint-up(xl) {
        &.--expanded {
          flex: 6 6 80%;
        }
      }
    }
    
    .exp-plan-builder-footer-title {
      order: 1;
      width: 65%;
    }
    .exp-plan-builder-footer-details {
      color: $gray-5;
      order: 3;
      padding-top: 4px;
      @include media-breakpoint-up(md) {
        order: 4;
      }
    }
    .exp-plan-builder-footer-pricing {
      order: 2;
    }

    .exp-plan-builder-footer-button-container {
      order: 4;
      height: 0px;
      overflow: hidden;
      transition: height 0.5s ease-in;
      &.--expanded {
        height: 75px;
        width: auto;
      }
      @include media-breakpoint-up(md) {
        order: 3;
        height: 0px;
        width: 0px;
        transition: height 0.3s ease-in;
        &.--expanded {
          height: 48px;
          width: auto;
          margin-left: 20px;
        }
      }
    }

    .exp-plan-builder-footer-button {
      margin-top: 24px;
      @include media-breakpoint-up(md) {
        margin-top: 0px;
        flex: 1 1 127px;
        &.--hasPlanDetails {
          margin-top: 0px;
        }
      }
    }
  }

  hr {
    width: 100%;
    border: 1px solid rgba(black,0.1);
    padding: 0px;
    margin: 0px;
  }

  .bottom-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 24px;
    @include media-breakpoint-up(md) {
      padding: 14px 24px;
    }
    @include media-breakpoint-up(lg) {
      padding: 14px 40px;
    }
    .exp-plan-builder-footer-description {
      color: $black-4;
      &.--hasTermsApply {
        max-width: 70%;
        flex: auto;
      }
    }
    .terms-apply {
      font-size: 12px;
      font-weight: 400;
      a {
        color: $text-link-4;
      }
      &.--hasDescription {
        max-width: 40%;
        margin-bottom: 18px;
        text-align: right;
        @include media-breakpoint-up(md) {
          margin-bottom: 0px;
        }
      }
    }
  }
}
