@import '@hulu/web-ui/generated/_variables.scss';

/**
  Before adding a new color to this file, check if it exists below or in
  @hulu/web-ui/generated/_variables.scss
**/
$body-background: $color-base-gray-200;
$lazy-schedule-border: #EEEEEE;
$toggler-background: #999999;
$signup-modal-border: #e5e5e5;
$slider-background: #345345;
$input-color: #555555;
$content-tabs-border: #B1B3B8;
$anchor-circle-border: var(--anchor-circle-border);

// Typography colors
$text-placeholder: #555555;
$text-contrast-dark-gray: #515159;
$plan-card-text: var(--plan-card-text);

// CTA colors
$cta-button-gray: rgba(39, 44, 53, 0.9);
$cta-button-white-hover: rgba(255, 255, 255, 0.8);
$cta-button-transparent-stroke: rgba(255, 255, 255, 1);
$cta-button-transparent-stroke-hover: rgba(255, 255, 255, 0.5);
$cta-button-transparent-dark-stroke: rgba(4, 5, 5, 1);
$cta-button-transparent-dark-stroke-hover: rgba(39, 44, 53, 0.5);

// Grays
$gray-1: var(--gray-1);
$gray-2: #979797;
$gray-3: var(--gray-3);
$gray-4: #54575C;
$gray-5: var(--gray-5);
$gray-6: var(--gray-6);
$gray-7: var(--gray-7);
$gray-8: #B8BECC;
$gray-9: var(--gray-9);
$gray-10: #888888;
$gray-11: var(--gray-11);
$gray-12: var(--gray-12);
$gray-13: var(--gray-13);
$gray-14: var(--gray-14);
$gray-15: var(--gray-15);
$gray-16: var(--gray-16);
$gray-17: var(--gray-17);
$gray-18: #424752;
$gray-19: var(--gray-19);
$gray-20: var(--gray-20);
$gray-21: var(--gray-21);
$gray-22: #C8C8C8;
$gray-23: var(--gray-23);
$gray-24: var(--gray-24);
$gray-25: #DCDCDC;
$gray-26: var(--gray-26);
$gray-27: var(--gray-27);
$gray-28: var(--gray-28);
$gray-29: var(--gray-29);
$gray-30: #BEC0C2;
$gray-31: #797D84;
$gray-32: #191919;
$gray-910: #272c34;
$gray-dimmed: #C8C8C866;
$white-dimmed: #FFFFFF66;
$text-body: var(--text-body);
$text-contrast-gray: var(--text-contrast-gray);
$white-with-opacity: rgba(255, 255, 255, 0.6);

// greens
$logo-green: var(--logo-green);
$brand-green: var(--brand-green);
$green: var(--green);
$green2: var(--green2);
$green3: var(--green3);
$green4: var(--green4);
$green5: var(--green5);

// blues
$text-link: var(--text-link);
$text-link-2: var(--text-link-2);
$text-link-3: var(--text-link-3);

// reds
$error-red: var(--error-red);
$error-red-2: var(--error-red-2);
$error-red-3: var(--error-red-3);

// whites
$white: var(--white);
$text-white: var(--text-white);
$background-light-grey: var(--background-light-grey);
$background-white-2: var(--background-white-2);
$accent-grey: var(--accent-grey);

// blacks
$black: var(--black);
$black-2: var(--black-2);
$black-3: var(--black-3);
$black-4: var(--black-4);
$black-5: var(--black-5);
$background-faux-black: var(--background-faux-black);
$cta-button-black: var(--cta-button-black);
$background-faux-black-2: #16181D;

$logo-green: $color-base-green-tertiary;
$brand-green: $color-base-green-tertiary;
$green: $color-base-green-tertiary; // #1ce783
$green-2: $color-base-green-primary;
$green-3: $color-base-green-secondary;
$green4: $color-base-green-primary;
$green5: $color-base-green-tertiary;
$green-hover: #1ce783cc;

$text-link: $color-base-blue;
$text-link-2: $color-base-blue;
$text-link-3: $color-base-blue;
$text-link-4: #3C88EB;

$error-red: $color-base-red;
$error-red-2: $color-base-red;
$error-red-3: $color-base-red;

$white: $color-base-white;
$text-white: $color-base-white;
$background-light-grey: $color-base-gray-200;
$background-white-2: $color-base-gray-300;
$accent-grey: $color-base-gray-300;

$black: $color-base-black;
$black-2: $color-base-gray-1300;
$black-3: $color-base-black;
$black-4: $color-base-gray-1200;
$black-5: $color-base-gray-1200;
$background-faux-black: $color-base-black;
$cta-button-black: $color-base-black;
$gray-1: $color-base-gray-1200;
$gray-3: $color-base-gray-300;
$gray-5: $color-base-gray-800;
$gray-6: $color-base-gray-1300;
$gray-7: $color-base-gray-400;
$gray-9: $color-base-gray-800;
$gray-11: $color-base-gray-1200;
$gray-12: $color-base-gray-800;
$gray-13: $color-base-gray-400;
$gray-15: $color-base-gray-1200;
$gray-14: $color-base-gray-500;
$gray-16: $color-base-gray-1300;
$gray-17: $color-base-gray-1100;
$gray-19: $color-base-gray-900;
$gray-20: $color-base-gray-800;
$gray-21: $color-base-black;
$gray-23: $color-base-gray-400;
$gray-24: $color-base-white;
$gray-26: $color-base-gray-700;
$gray-27: $color-base-gray-300;
$gray-28: $color-base-gray-600;
$gray-29: $color-base-gray-700;

$text-body: $color-base-gray-900;
$text-contrast-gray: $color-base-gray-900;

$plan-card-text: $color-base-gray-1000;
$anchor-circle-border: $color-base-gray-200;
