@import '../../../styles/global';
@import '../../../styles/breakpoints';
@import '../../../styles/colors';

.HubTabs {
  background-color: $white;
  border-bottom: 1px solid $accent-grey;

  .tabs__container {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }

  .nav {
    background-color: $white;

    .nav-item {
      margin-right: 40px;
    }

    .nav__accent-bar {
      background-color: $brand-green !important;
    }
  }

  .tab {
    padding-bottom: 32px;

    @include media-breakpoint-up(md) {
      padding-bottom: 4px;
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: 24px;
    }

    .NonSubEditorialCollection {
      padding-left: 0;
      padding-right: 0;

      .PortraitTile__wrapper {
        @include media-breakpoint-up(lg) {
          box-shadow: 0 12px 20px -8px rgba(30,30,30,0.4);
        }
      }
    }
  }
}
