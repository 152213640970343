@import '../../../../styles/global';
@import '../../../../styles/breakpoints';

.NonSubSimpleCollection {
  @extend .container--wide;
  background-color: $white;
  padding-top: 40px;

  .SimpleCollection {
    margin-top: 0;
    border-bottom: none;
    padding-bottom: 32px;

    @include media-breakpoint-up(md) {
      padding-bottom: 0;
    }

    .Tile__content {
      height: 5em;
    }
  }

  .Slider__button {
    cursor: pointer;
    display: none;
    @include media-breakpoint-up(md) {
      display: block;
    }
  }
}
