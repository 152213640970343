@import "../../../styles/colors";
@import "../../../styles/breakpoints";
@import "../../../styles/mixins";

.TabButtons {
  text-align: center;
  padding-bottom: 31px;
  white-space: nowrap;
  transition: transform 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  @include media-breakpoint-up(lg) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    text-align: left;
  }

  &__tab {
    cursor: pointer;
    text-transform: uppercase;
    color: $white-dimmed;
    display: inline-block;
    margin-left: 13px;
    margin-right: 13px;
    background: none;
    border: none;
    padding: 0;
    &:hover {
      color: $gray-dimmed;
    }
    &.--active {
      color: $white;
      @include media-breakpoint-up(lg) {
        &:hover {
          color: $gray-22;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      margin-left: 0;
      margin-right: 37px;
    }
  }

  &__activeBar {
    display: block;
    height: 7px;
    background: $white;
    position: absolute;
    transform: scaleX(0);
    transform-origin: right center;
    transition: width 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955),
      transform 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    margin-top: 13px;
    z-index: 2;
    @include media-breakpoint-up(lg) {
      height: 5px;
      margin-top: 15px;
    }
  }
}