@import '../../../styles/breakpoints';
@import '../../../styles/mixins';
@import '../../../styles/colors';

.offset-lg-1 {
  margin-left: 8.33333%;
  @include media-breakpoint-down(md) {
    margin-left: 0;
  }
}
.justify-content-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.mobile {
  .hide-mobile-device {
    display: none;
  }
}

.supported-devices {
  background: $background-faux-black;
  text-align: center;
  margin: 0 auto;
  padding: 40px 0;

  &--list-item {
    img {
      width: 72px;

      &.lazyloaded {
        width: unset;
      }
      @include lazyLoad(72px);
    }
  }

  @include media-breakpoint-down(lg) {
    padding: 40px 20px;
    &--list {
      margin: 50px auto 0;
    }
  }

  &--list-item:nth-child(5n) {
    margin-right: 8.333333%;
  }

  @include media-breakpoint-down(md) {
    &--list-item:nth-child(5n) {
      margin-right: 0;
    }
  }

  &--description {
    color: $white;
    margin: 0 auto 10px;
    @include one-hulu-body-16;
    a {
      color: $white;
      border-bottom: 1px solid $white;
    }
  }

  &--disclaimer {
    color: $gray-28;
    @include one-hulu-body-10;
  }

  &--list {
    margin: 50px auto 0;
  }
}