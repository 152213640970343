@import "../../../styles/colors";
@import "../../../styles/breakpoints";
@import "../../../styles/mixins";

.SpotlightTabs {
  font-family: "Graphik", Helvetica, Arial, sans-serif;
  background-color: $black;
  width: 100%;
  z-index: -1;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  @include media-breakpoint-up(md) {
    background-position: 0% 80%;
    background-size: cover;
  }
  @include media-breakpoint-up(lg) {
    background-position: 0% 0%;
  }
  &__outerContainer {
    margin: 0 auto;
    padding-top: 34px;
    height: 760px;
    @include media-breakpoint-up(md) {
      height: 1000px;
    }
    @include media-breakpoint-up(lg) {
      max-width: $container-max-width-lg;
      max-height: 702px;
      padding: 60px 0 190px 0;
    }
    @include media-breakpoint-up(xl) {
      max-width: $container-max-width-xl;
      max-height: 811px;
    }
    @include media-breakpoint-up(xxl) {
      max-width: $container-max-width-xxl;
      max-height: 870px;
    }
  }

  &__innerContainer {
    @include media-breakpoint-up(lg) {
      margin-top: 100px;
    }
    @include media-breakpoint-up(xl) {
      margin-top: 140px;
    }
    @include media-breakpoint-up(xxl) {
      margin-top: 160px;
    }
  }
}