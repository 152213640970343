@import '../../styles/breakpoints';
@import '../../styles/mixins';
@import '../../styles/global';

@mixin separator {
  &:not(:last-child) {
    &:after {
      content: ' • ';
      padding-right: 4px;
    }
  }
}

.DetailEntityMetadata {
  &__credits {
    color: $gray-20;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      margin-bottom: 16px;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 20px;
    }

    .prefix {
      font-weight: 600;
      margin-right: 4px;
    }

    p {
      &:not(:last-child) {
        margin: 0 0 4px;
      }
    }
  }
  &__credit {
    &:not(:last-child) {
      &:after {
        content: ', ';
      }
    }
  }
  &__tag-group {
    color: $gray-20;
    margin-bottom: 16px;

    @include media-breakpoint-up(md) {
      margin-bottom: 12px;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 16px;
    }
  }

  &__rating {
    @include separator;
  }

  &__genres {
    line-height: normal;
    @include separator;
    a {
      color: $gray-20;
    }
    .DetailEntityMetadata__genre {
      &:not(:last-child) {
        &:after {
          content: ', ';
        }
      }
    }
  }

  &__hub {
    &-with-tags {
      @include separator;
    }
  }

  &__type {
    color: $gray-20;
    &:hover, &:visited, &:active, &:focus {
      color: $gray-20;
    }
  }

  &__has-inline-av-features {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .DetailEntityMetadata__tag-group {
      margin: 0 0 10px 0;
      @include media-breakpoint-up(xl) {
        margin: 0 15px 0 0;
      }
    }
  }

  &__av-features {
    list-style-type: none;
    padding: 0;
    margin: 0;
    @include flex(start, center, wrap);

    .av-feature {
      @include flex(center, center, nowrap);
      margin: 4px 8px 0 0;
    }

    &-inline {
      margin-bottom: 10px;
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
      .av-feature {
        margin-top: 0;
      }
    }
  }
}
