@import '../../../styles/global';
@import '../../../styles/mixins';

.hub-nav {
  @extend .container--wide;
  padding-top: 40px;
  background-color: $white;

  &__title {
    @include one-hulu-title-48;
    color: $gray-6;
    margin-bottom: 0;
  }
}
