/** @define NonSubEditorialCollection */
@import '../../../../styles/global';
@import '../../../../styles/colors';

.NonSubEditorialCollection {
  @extend .container--wide;
  overflow: hidden;
  background-color: $white;
  padding-bottom: 32px;

  &__header {
    text-align: center;
    padding-top: 40px;
    &__headline {
      margin: 0 auto 20px;
    }
    &__description {
      margin: 0 auto;
      color: $text-body;
      @include media-breakpoint-down(sm) {
        margin: 5px auto 0;
      }
    }
  }
}
