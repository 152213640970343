.DetailEntityBackground {
  &__wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
  }

  &__base,
  &__art,
  &__color,
  &__overlay,
  &__gradient,
  &__highlight,
  &__editorial,
  &__scrim {
    // expand to avoid subpixel artifacts
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
  }

  &__art {
    width: 100%;
    text-align: right;
  }
}
