@import '~@hulu/site-styles/font_utils';
@import '../../../styles/breakpoints';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/colors';

.accordion {
  &__header {
    text-align: center;
    padding: 40px 0;
  }

  &__headline {
    &--brand {
      text-transform: uppercase;
    }
  }

  &__list {
    ul {
      list-style: none;
      padding: 0;
    }
    li {
      border-bottom: 1px solid $gray-27;
      &:last-of-type {
        border-bottom: none;
      }
    }
  }

  &__link {
    cursor: pointer;
  }

  &__item {
    &.open {
      padding-bottom: 25px;

      > div {
        display: block;
        text-align: left;
      }
      .accordion__icon {
        background: url('#{$prefix}/static/icons/icon_round_minus.png') no-repeat center center / cover;
      }
    }
    > div {
      display: none;
      color: $text-body;
    }
    & > a {
      display: -ms-flexbox; // IE10
      display: flex;
      -ms-flex-direction: row; // IE10
      flex-direction: row;
    }
  }

  &__icon {
    background: url('#{$prefix}/static/icons/icon_round_plus.png') no-repeat center center / cover;
    margin-left: auto;
    box-sizing: border-box;
    height: 48px;
    width: 48px;
    -ms-flex-pack: center; // IE10
    justify-content: center;
    -ms-flex-item-align: center; // IE10
    align-self: center;
    padding: 20px;
    @include media-breakpoint-down(sm) {
      height: 0px;
      width: 0px;
    }
  }

  &__question {
    padding: 25px 10px 25px 0;
    margin: 0;
    text-align: left;
    -ms-flex-item-align: start; // IE10
    align-self: flex-start;
  }

  .section-body,
  .accordion__description {
    @include one-hulu-body-16;
  }
}