@import '../../../styles/breakpoints';
@import '../../../styles/mixins';

.logo-wall {
  text-align: center;
  padding: 40px 0;
  @include media-breakpoint-down(md) {
    padding: 40px 20px;
  }
  @include media-breakpoint-only(md) {
    .network-col-md {
      flex: 0 0 12.5%;
      max-width: 12.5%;
    }
    .network-offset-md-left {
      margin-left: 6.25%;
    }
    &__list-item:nth-child(7n) {
      margin-right: 6.25%;
    }
  }
  @include media-breakpoint-up(lg) {
    .network-offset-lg-left {
      margin-left: 16.6667%;
    }
    &__list-item:nth-child(8n) {
      margin-right: 16.66667%;
    }
  }
  @include media-breakpoint-between(sm, lg) {
    .gutter-8.row {
      margin-left: -8px;
      margin-right: -8px;
    }
    & .gutter-8> [class^="col-"], & .gutter-8 > [class^=" col-"] {
      padding-right: 8px;
      padding-left: 8px;
    }
    &__logo-list {
      padding-right: 8px;
      padding-left: 8px;
    }
  }
  &__headline {
    margin: 0 auto 16px;
    // TODO: WEB-24569 Remove the conflicting style from CNBL
    // and remove the !important flag
    @include media-breakpoint-up(lg) {
      font-size: 56px !important;
      line-height: 1.25 !important;
      letter-spacing: -0.5px !important;
    }
  }
  &__description {
    max-width: 648px;
    margin: 0 auto 10px;
    @include one-hulu-body-16;
  }
  &__logo-list {
    margin-top: 20px;
    padding-right: 15px;
    padding-left: 15px;
  }
  &__list-item {
    height: 88px;
    max-height: 88px;
    margin-bottom: 24px;
  }
}