@import '~@hulu/site-styles/variables';
@import '~@hulu/site-styles/screen_xl_variables';
@import '~@hulu/site-styles/colors';
@import '~@hulu/site-styles/font_utils';
@import '~@hulu/site-styles/typography';
@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../stylesheets/tileMixins.scss';

$content-padding: 24px;
$content-padding-lg: 32px;
$tile-line-height: 1.15;
$animation-duration: 0.35s;

.PortraitTile {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  position: relative;
  width: 100%;
  background: $white;
  height: 100%;
  // HUWEB-5961: z-index transition isn't hardware accelerated,
  // but needs to be delayed for the hovered tiles overlaps look
  // good when zoomed
  z-index: 10;
  transition: z-index 0.4s linear;

  @include tile-highlight-border-on-hover-and-active;

  height: auto;
  width: auto;

  &__clickable {
    cursor: pointer;
  }
  &__content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 18px 16px;

    @include media-breakpoint-up(lg) {
      margin: 18px 16px;
    }
  }
  &__metadataLayer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-end;
  }

  &__metadata {
    a {
      color: $white;
    }
    position: absolute;
    padding: 18px 16px;
    bottom: calc(#{$content-padding-lg} + 32px);
    color: $white;
  }

  &__btns {
    bottom: 0;
    left: 0;

    svg {
      vertical-align: bottom; // to eliminate extra whitespace below
      height: 40px;
      width: 40px;
    }

    @include media-breakpoint-up(lg) {
      display: block;
    }
    &--hover {
      cursor: pointer;
    }
  }
  &__scrim {
    background: linear-gradient(180deg, transparent, #040405);
  }

  &__overlay {
    background: #0b0c0f80;
  }

  &__spacer {
    width: 100%;
    padding-bottom: 150%; // 2 * 3 ratio
  }
  
  &__hoverData {
    display: block;
    max-height: 0;
    overflow: hidden;
    opacity: 0.2;
    transition: max-height $animation-duration ease-in-out, opacity $animation-duration ease-in-out;
    &:hover {
      margin-bottom: 8px;
    }
  }

  &__wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    transition: transform $animation-duration ease, box-shadow $animation-duration ease;
    border-radius: 10px;
  }

  &__base,
  &__art,
  &__gradient,
  &__highlight,
  &__editorial,
  &__scrim {
    // expand these slightly outside of their container so they aren't subject
    // to aliasing around the edges during subpixel width remainders and during
    // scaling transitions
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  &__gradient,
  &__highlight,
  &__overlay {

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  &__art {
    // needs to be expanded to fit the container
    width: calc(100% + 4px);
    opacity: 0.96;
    transition: opacity 1s, transform 12s cubic-bezier(0.23, 1, 0.32, 1);
  }

  &__brand {
    position: absolute;
    bottom: 20px;
    right: 16px;
    pointer-events: none;
    opacity: 1;
    width: 50px;
    height: 50px;
  }

  &__description {
    font-size: 14px;
    margin-bottom: 15px;
  }

  &__title-art {
    &__image {
      max-height: 69px;
      max-width: 90%;
      width: auto;
    }

    &__text {
      @include media-breakpoint-up(lg) {
        visibility: hidden;
      }
      &--fade-in {
        visibility: visible;
        animation: fade-in 0.25s ease-in forwards;
      }
    }

    &--loaded {
      .PortraitTile__title-art__image {
        @include media-breakpoint-up(lg) {
          display: block;
          animation: fade-in 0.25s ease-in forwards;
        }
      }
      .PortraitTile__title-art__text {
        display: none !important; // overrides TruncatedText inline styles
      }
    }
  }

  &__title {
    @include tile-header;

    color: $gray-6;
    margin-bottom: 8px;
    display: block;

    @include media-breakpoint-up(lg) {
      font-weight: 300;
      line-height: $tile-line-height;
      letter-spacing: -0.5px;
      color: inherit;
      font-size: 2em;
    }

    @include media-breakpoint-up(xl) {
      font-size: 2.5em;
      letter-spacing: -1px;
    }

    &:hover,
    &:focus {
      color: inherit;
    }

    &--hover {
      cursor: pointer;
    }
  }

  &__actionBar {
    width: 100%;
    padding: 0px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    overflow: hidden;
    height: 72px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    &::before {
      @include cover;
      content: '';

      background-color: #0b0c0f;
      opacity: 0.1;
    }
  }

  &__sideDetailsBtn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding-left: 4px;
    &:hover {
      opacity: 0.8;
      color: inherit;
      background-color:transparent;
    }
  }

  &__joined-tags {
    font-size: 12px;
    line-height: $tile-line-height;
    margin-bottom: 0;
    font-weight: 400;
    color: $white;
    @include media-breakpoint-up(lg) {
      font-size: 14px;
    }
  }

  &__thumbnail {
    display: block;
    position: relative;
    width: 100%;
    background: $white;
    height: auto;
  }

  &--horizontal {
    .PortraitTile__spacer {
      padding-bottom: 56.25%; // 16 * 9 ratio
    }

    .PortraitTile__hoverData {
      color: $white;
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    .PortraitTile__content {
      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        margin: 32px;  // mobile margin
      }
    }

    .PortraitTile__gradient,
    .PortraitTile__highlight,
    .PortraitTile__overlay {
      display: block;
    }

    .PortraitTile__metadata {
      position: absolute;
      bottom: 32px;
      left: 32px;
      max-width: 72%;

      @include media-breakpoint-up(md) {
        bottom: 100px;
        left: 40px;
      }

      @include media-breakpoint-up(lg) {
        color: inherit;
        bottom: 116px;
        left: 56px;
      }

      @include media-breakpoint-up(xl) {
        color: inherit;
        bottom: 140px;
        left: 80px;
      }
    }

    .PortraitTile__title {
      color: $white;
      font-size: 2.5em;
      letter-spacing: -1px;

      @media (min-width: $screen-md-min) {
        font-size: 2em;
        letter-spacing: -0.5px;
      }

      @media (min-width: $screen-lg-min) {
        font-size: 2.5em;
        letter-spacing: -1px;
      }

      @media (min-width: $screen-xxl-min) {
        font-size: 2em;
        letter-spacing: -0.5px;
      }
    }

    .PortraitTile__joined-tags {
      display: none;
      color: $white;

      @include media-breakpoint-up(md) {
        display: block;
      }
    }
  }

  &--small {
    .PortraitTile__hoverData {
      @media (max-width: $screen-md-max) {
        display: none;
      }
    }

    .PortraitTile__content {
      margin: 24px;
    }

    .PortraitTile__title {
      @include Regular-Font;

      font-size: 24px;
      letter-spacing: -0.5px;

      @media (min-width: $screen-ml-min) {
        @include Light-Font;

        font-size: 32px;
        letter-spacing: -0.5px;
      }

      @media (min-width: $screen-lg-min) {
        @include Regular-Font;

        font-size: 24px;
        letter-spacing: -0.5px;
      }

      @media (min-width: $screen-xl-min) {
        @include Light-Font;

        font-size: 32px;
        letter-spacing: -0.5px;
      }
    }

    .PortraitTile__description {
      font-size: 14px;
    }

    .PortraitTile__btns {
      svg {
        @media (max-width: $screen-md-max) {
          height: 40px;
          width: 40px;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &:hover {
      .PortraitTile__sponsor-gradient {
        transform: scale(1.03);
      }

      .PortraitTile__wrapper {
        box-shadow: 0 24px 88px -8px rgba($black, 0.4);
      }

      .PortraitTile__hoverData {
        max-height: 10em;
        opacity: 1;
      }

      .PortraitTile__art {
        opacity: 1;
      }
    }
  }

  &--large.PortraitTile--horizontal {
    .PortraitTile__hoverData {
      // always visible
      display: block;
      max-height: 10em;
      opacity: 1;

      @media (max-width: $screen-sm-max) {
        display: none;
      }
    }

    .PortraitTile__metadata {
      max-width: 60%;

      @media (min-width: $screen-xl-min) {
        margin-bottom: 60px;
        max-width: 600px;
      }
    }

    .PortraitTile__title-art {
      &--loaded {
        .PortraitTile__title-art__image {
          display: block;
        }
        .PortraitTile__title-art__text {
          display: none !important; // overrides TruncatedText inline styles
        }
      }
    }

    .PortraitTile__title {
      @include Regular-Font;

      font-size: 24px;
      line-height: 40px;
      margin-bottom: 16px;

      @media (min-width: $screen-md-min) {
        @include Light-Font;

        font-size: 32px;
      }

      @media (min-width: $screen-ml-min) {
        font-size: 40px;
        line-height: $tile-line-height;
      }

      @media (min-width: $screen-xl-min) {
        font-size: 48px;
        line-height: $tile-line-height;
      }
    }

    .PortraitTile__btns {
      @media (min-width: $screen-ml-min) {
        margin-top: 48px;

        svg {
          height: 40px;
          width: 40px;
        }
      }
    }

    .PortraitTile__content {
      margin: 32px;

      @include media-breakpoint-up(md) {
        margin: 40px;
      }

      @media (min-width: $screen-md-min) {
        margin: 56px;
      }

      @media (min-width: $screen-ml-min) {
        margin: 72px;
      }

      @media (min-width: $screen-xl-min) {
        margin: 80px;
        margin-bottom: 100px;
      }
    }

    .PortraitTile__brand {
      opacity: 0.5;
      position: absolute;
      bottom: 20px;
      right: 16px;
      width: 64px;
      height: 64px;
      max-width: 56px;

      @media (min-width: $screen-md-min) {
        width: 84px;
        height: 84px;
      }
    }

    &:hover {
      .PortraitTile__sponsor-gradient {
        transform: none;
      }

      .PortraitTile__wrapper {
        transform: none;
        box-shadow: none;
      }

      .PortraitTile__art {
        opacity: 1;
        transform: none;
      }
    }
  }
}
