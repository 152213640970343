@import "~@hulu/site-styles/variables";
@import '../../../styles/breakpoints';
@import '../../../styles/mixins';
@import '../../../styles/colors';

.body {
  @include one-hulu-body-16;
  color: $text-contrast-dark-gray;
}

.networks {
  min-height: 440px;
  margin: 0;

  @media (max-width: $screen-sm-max) {
    padding-left: 0;
  }

  .networks-title {
    margin: 10px 0;
    @include one-hulu-title-32;
  }

  .networks-subtitle {
    padding-top: 8px;
    padding-bottom: 20px;
    display: block;
    max-width: 80%;
    @include one-hulu-body-16;
    @media (max-width: $screen-sm-max) {
      max-width: 100%;
    }
  }

  .legal-advice {
    padding-bottom: 20px;
    max-width: 80%;
    @include one-hulu-body-12;
    @media (max-width: $screen-sm-max) {
      min-width: 100%;
      padding-bottom: 20px;
    }
  }

  .networks-form {
    padding-bottom: 25px;
    display: flex;
    flex-direction: column;

    .submit-button,
    .submit-button:active,
    .submit-button:focus,
    .submit-button:hover {
      width: 86px;
      height: 42px;
      border-radius: 0 4px 4px 0;
      background-color: $cta-button-black;
      color: $white;
      text-transform: uppercase;
      border: 0;
      float: left;
    }

    .submit-button:hover {
      background-color: $cta-button-gray;
    }

    .form-control {
      display: inline;
    }

    .zipcode-input {
      width: 200px;
      height: 42px;
      padding: 8px 12px;
      background-color: $gray-24;
      background-image: none;
      border: 1px solid $gray-25;
      border-radius: 4px 0 0 4px;
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
      transition: border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s;
      float: left;
      @include one-hulu-body-16;

      @media (max-width: $screen-sm-max) {
        width: 140px;
      }

      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $text-placeholder;
        opacity: 1; /* Firefox */
      }
      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $text-placeholder;
      }
      &::-ms-input-placeholder { /* Microsoft Edge */
        color: $text-placeholder;
      }
    }

    .error {
      border-color: $color-base-red;
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    }

    .circle-close-icon {
      background: asset-url("h3o/landing/networkmodal/close.svg") no-repeat;
      background-position: 170px;
      cursor: pointer;
      @media (max-width: $screen-sm-max) {
        background-position: 114px;
      }
    }

    @keyframes spinner {
      to {
        transform: rotate(360deg);
      }
    }

    .icon.loading-icon {
      display: inline-block;
      width: 25px;
      height: 25px;
      position: relative;
      margin-left: 20px;

      &::before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 25px;
        height: 25px;
        margin-top: -10px;
        margin-left: -10px;
        border-radius: 50%;
        border: 3px solid $hulu-brand-dark;
        border-top-color: $color-base-green-tertiary;
        animation: spinner 0.6s linear infinite;
      }
    }
  }

  .invalid-zip-code {
    margin-top: 5px;
    color: $hulu-error-message;
    @include one-hulu-body-14;

    @media (max-width: $screen-sm-max) {
      @include one-hulu-body-12;
    }
  }

  .channels-container {
    border-top: 1px solid $gray-3;
  }

  .server-error {
    padding-top: 24px;
    border-top: 1px solid $gray-3;
    color: $hulu-error-message;
    @include one-hulu-body-14;
  }

  .channel-category {
    margin-top: 25px;
    color: $gray-6;

    @media (max-width: $screen-sm-max) {
      margin-top: 15px;
    }

    .channel-category-title {
      margin-left: 15px;
      margin-bottom: 0;
      text-align: center;
      @include one-hulu-subtitle-18;
    }

    .network-icons-group {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .NetworkIcon {
    height: 90px;
    margin: 14px;
    position: relative;
    width: 90px;

    @media (max-width: $screen-sm-max) {
      height: 60px;
      width: 60px;
    }

    &__frame {
      box-shadow: 0 1px 2px 0 $tooltip-shadow-color;
      border-radius: 50%;
      height: 90px;
      overflow: hidden;
      width: 90px;

      @media (max-width: $screen-sm-max) {
        height: 60px;
        width: 60px;
      }
    }

    &__logo {
      background-size: 75px;
      background-repeat: no-repeat;
      background-position: 50%;
      display: table-cell;
      height: 90px;
      overflow: hidden;
      text-align: center;
      vertical-align: middle;
      width: 90px;
      @include one-hulu-body-12;

      @media (max-width: $screen-sm-max) {
        background-size: 52px;
        font-size: 10px;
        height: 60px;
        width: 60px;
      }
    }

    &__logo-be {
      background-size: 56px;
      @media (max-width: $screen-sm-max) {
        background-size: 35px;
      }
    }

    &__network-name-invisible {
      display: none;
    }

    &__badge {
      bottom: -20px;
      cursor: pointer;
      position: absolute;
      width: 100%;

      .__react_component_tooltip {
        margin: 0;
        padding: 0;
        border: none;
        background-color: transparent;
        &::before,&::after {
          content: none;
          left: 0;
        }
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      .HuluTooltip {
        display: block;
        width: 100%;
        @include one-hulu-label-10;
        text-align: center;
        line-height: unset;
      }
    }
  }

  .onDemandTooltip {
    width: 200px;
    margin-left: 10px;
    color: $text-body;
    box-shadow: 0 0 3px $text-body;
    background-color: $white;
    padding: 15px 13px;
    text-transform: none;
    @include one-hulu-label-12;

    /* arrow */
    &:after,&:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 10px;
      display: block;
      -webkit-transform: translateX(-50%) rotate(45deg);
      transform: translateX(-50%) rotate(45deg);
      height: 20px;
      width: 20px;
      margin-top: -10px;
    }

    &:before {
      -webkit-box-shadow: 0 1px 4px 0 $text-body;
      box-shadow: 0 1px 4px 0 $text-body;
      @include z-index(hide);
    }

    &:after {
      background: $white;
      @include z-index(low);
    }
  }
}
