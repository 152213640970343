@import "../../../styles/mixins";

.html-module {
  h1 {
    @include one-hulu-title-40;
  }
  h2 {
    @include one-hulu-title-32;
  }
  h3 {
    @include one-hulu-title-24;
  }
  h4 {
    @include one-hulu-title-18;
  }
  h5 {
    @include one-hulu-title-14;
  }
}