@import '../../../styles/breakpoints';
@import '../../../styles/colors';

.Ribbon {
  background: linear-gradient(
    318.68deg,
    #0f495c 0%,
    #0d3640 49.72%,
    #08141f 100%
  );
  padding: 28px 10px 28px 20px;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(md) {
    padding: 34px 40px;
    z-index: 1;
    width: 100%;
  }
  @include media-breakpoint-up(lg) {
    padding: 25px 40px;
    z-index: 1;
    width: 100%;
  }
  &--black-transparent {
    background: linear-gradient(
      179.81deg,
      rgba(4, 4, 4, 0.7) 0.17%,
      rgba(4, 4, 4, 0.45) 85.02%,
      rgba(4, 4, 4, 0.3) 112.52%
    ) !important;
  }
  .row {
    align-items: center;
    margin: 0 auto;
    width: 100%;
    max-width: 1520px;
  }
  &__hide-xs {
    display: none;
    padding: 0;
    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
    }
  }
  &__brand-img {
    @include media-breakpoint-up(lg) {
      padding-left: 0;
    }
  }
  &__brand-img-mobile {
    display: inline;
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
  &--showmobile {
    color: $white;
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
  &--showdt {
    display: none;
    color: $white;
    @include media-breakpoint-up(md) {
      display: inline;
    }
  }
  &__learn {
    display: inline;
    @include media-breakpoint-up(md) {
      display: block;
      padding-top: 5px;
    }
    a {
      font-size: 14px;
      line-height: 15px;
    }
  }
  &__eyebrow {
    padding-bottom: 8px;
    color: $color-base-green-tertiary;
  }
  &__details-container {
    margin-top: 5px;
  }
  &__button {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding-top: 15px;
    @include media-breakpoint-up(md) {
      padding-top: 20px;
      align-items: flex-end;
    }
  }
  &__info {
    text-align: center;
    @include media-breakpoint-up(md) {
      text-align: left;
      padding: 0 15px;
      padding-right: 25px;
    }
    @include media-breakpoint-up(lg) {
      padding: 0 30px;
      padding-right: 100px;
    }
  }
  &__learn a,
  &__legal_modal {
    color: $white;
    opacity: 0.7;
    text-decoration: underline;
    &:hover,
    &:focus,
    &:active {
      color: $white;
    }
  }
  &__legal_modal {
    font-size: 10px;
  }
  img {
    max-width: 180px;
    width: 100%;
    @include media-breakpoint-up(md) {
      max-width: 220px;
    }
    @include media-breakpoint-up(lg) {
      max-width: 355px;
    }
  }
  &--rounded-corners {
    border-radius: 15px;
    margin: 0 auto 20px auto;
    width: 90%;
  }
}
