/** @define Slider */
@import '../../../../styles/breakpoints';
@import '../../../../styles/mixins';
@import '../../../../styles/fluid-grid';
@import '../../../../styles/colors';

$slider-button-shadow: rgba(48, 65, 97, 0.2);

.Slider {
  position: relative;

  &__wrapper {
    overflow: hidden;
  }

  &__items {
    display: flex;
    flex-wrap: nowrap;

    @include media-breakpoint-up(sm) {
      margin: 0 -6px;
    }

    @include media-breakpoint-up(md) {
      margin: 0 -8px;
    }

    @include media-breakpoint-up(lg) {
      margin: 0 -12px;
    }
  }

  &__item {
    flex: 0 0 (100% / 3);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease;

    &--visible {
      opacity: 1;
      display: block;
      pointer-events: all;
    }

    @include media-breakpoint-down(sm) {
      pointer-events: all;
    }
  }

  &__see-more-button {
    border: 1.5px solid $gray-7;
    background-color: $white;
    padding: 15px;
    text-align: center;
    color: $gray-6;
    font-size: 14px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 280px;
    margin-top: 20px;
  }

  &__button {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: none;
    padding: 0;
    position: absolute;
    top: 50%;
    opacity: 1;
    transition: opacity 0.1s ease, box-shadow 0.1s ease, top 0.2s ease;
    @include z-index(lower);

    &:disabled {
      opacity: 0;
      pointer-events: none;
    }

    &:active {
      box-shadow: 0 4px 8px 0 $slider-button-shadow;
    }

    @include media-breakpoint-down(lg) {
      height: 40px;
      width: 40px;
    }

    &--previous {
      left: 0;
      transform: translateY(-50%) translateX(-50%);
      box-shadow: -2px 4px 10px 0 $slider-button-shadow;

      &:active {
        // top and margin-top are already being used, so we'll overload translate
        transform: translateY(calc(-50% + 2px)) translateX(-50%);
      }
    }

    &--next {
      right: 0;
      transform: translateY(-50%) translateX(50%);
      box-shadow: 2px 4px 10px 0 $slider-button-shadow;

      &:active {
        // top and margin-top are already being used, so we'll overload translate
        transform: translateY(calc(-50% + 2px)) translateX(50%);
      }
    }

    svg {
      // stylelint-disable-line plugin/selector-bem-pattern
      width: 100%;
      height: 100%;
    }
  }

  &--tile {
    .Slider {
      &__items {
        @include media-breakpoint-up(sm) {
          margin: 0;
        }

        @include media-breakpoint-up(lg) {
          margin: 0 -4px;
        }
      }

      &__button {
        top: 0;
        margin-top: calc((50% * (9 / 16) / 3) - (24px / 3));

        @include media-breakpoint-up(lg) {
          margin-top: calc((50% * (9 / 16) / 4) - (24px / 4));
        }

        @include media-breakpoint-up(xl) {
          margin-top: calc((50% * (9 / 16) / 5) - (24px / 5));
        }

        @include media-breakpoint-up(xxl) {
          margin-top: calc((50% * (9 / 16) / 6) - (24px / 6));
        }
      }

      &__item {
        @extend .grid-col;
        padding: 0 12px;
      }
    }
  }

  &--onboarding-tile {
    .Slider__item {
      padding: 0 12px;
      overflow: hidden;
      flex: 0 0 (100% / 3);

      @include media-breakpoint-up(lg) {
        flex: 0 0 (100% / 5);
      }
    }

    .Slider__button--previous {
      left: 12px;
    }

    .Slider__button--next {
      right: 12px;
    }
  }

  &--portrait {
    .Slider__wrapper {
      overflow: visible;
    }

    @include media-breakpoint-down(md) {
      .Slider__button {
        display: none;
      }
    }

    .Slider__item {
      flex: 0 0 100%;

      @include media-breakpoint-up(xs) {
        flex: 0 0 (100% / 1.5);
        padding: 0 6px;
        opacity: 1;
      }

      @include media-breakpoint-up(md) {
        flex: 0 0 (100% / 2.5);
        padding: 0 8px;
      }

      @include media-breakpoint-up(lg) {
        flex: 0 0 (100% / 3);
        padding: 0;
        opacity: 0;
      }

      @include media-breakpoint-up(xl) {
        flex: 0 0 (100% / 4);
      }

      @include media-breakpoint-up(xxl) {
        flex: 0 0 (100% / 5);
      }

      &--visible {
        opacity: 1;
      }
    }

    .Slider__items {
      @include media-breakpoint-up(lg) {
        margin: 0;
      }
    }
  }

  &--small-portrait {
    .Slider__item {
      flex: 0 0 (100% / 3);

      @include media-breakpoint-up(md) {
        flex: 0 0 (100% / 2);
      }

      @include media-breakpoint-up(lg) {
        flex: 0 0 (100% / 3);
      }

      @include media-breakpoint-up(xl) {
        flex: 0 0 (100% / 4);
      }

      @include media-breakpoint-up(xxl) {
        flex: 0 0 (100% / 5);
      }
    }
  }

  &--adaptive {
    .Slider__item {
      flex: 0 0 100%;

      @include media-breakpoint-up(lg) {
        flex: 0 0 (100% / 2);
      }

      @include media-breakpoint-up(xxl) {
        flex: 0 0 (100% / 3);
      }
    }
  }

  &--network {
    .Slider {
      &__button {
        top: 77px;
      }

      &__item {
        flex: 0 0 (100% / 3);

        @include media-breakpoint-up(md) {
          flex: 0 0 (100% / 5);
        }

        @include media-breakpoint-up(lg) {
          flex: 0 0 (100% / 6);
        }

        @include media-breakpoint-up(xl) {
          flex: 0 0 (100% / 7);
        }

        @include media-breakpoint-up(xxl) {
          flex: 0 0 (100% / 10);
        }
      }
    }
  }

  &--static {
    .Slider__items {
      flex-wrap: wrap;
    }

    .Slider__item {
      opacity: 1;
      display: none;
    }

    .Slider__item:nth-child(-n + 4) {
      display: block;
      transition: display 0.5 ease-out;
    }

    .Slider__items--expanded {
      .Slider__item {
        display: block;
      }
    }
  }
}
