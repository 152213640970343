@import "~@hulu/site-styles/variables";
@import '../../../styles/breakpoints';
@import '../../../styles/mixins';
@import '../../../styles/colors';

.body {
  @include one-hulu-body-16;
  color: $text-contrast-dark-gray;
}

.NetworksWithGeo {
  min-height: 440px;
  margin: 0;

  .networks-subtitle {
    padding-top: 8px;
    padding-bottom: 20px;
    max-width: 80%;
  }

  .networks-title {
    margin: 10px 0;
  }

  &__legalAdvice {
    padding-bottom: 20px;
    margin: 0 auto;
    color: $gray-12;
    text-align: center;
  }

  .server-error {
    color: $gray-6;
    padding-top: 24px;
    border-top: 1px solid $gray-3;
  }

  &__channelCategory {
    margin-top: 25px;
  }

  &__channelCategoryTitle {
    margin-left: 15px;
    margin-bottom: 0;
    text-align: center;
    color: $gray-6;
    @include one-hulu-subtitle-18;
  }

  &__networksIconGroup {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .ZipCodeInput {
    max-width: none;
    margin: 0;
  }

  @media (max-width: $screen-sm-max) {
    padding-left: 0;

    .networks-subtitle {
      max-width: 100%;
    }

    .legal-advice {
      min-width: 100%;
      padding-bottom: 20px;
    }

    .networks-form {
      .form-control {
        display: inline;
      }

      .circle-close-icon {
        background-position: 114px;
      }
    }

    .channel-category {
      margin-top: 15px;
    }

    .NetworkIcon {
      height: 60px;
      width: 60px;

      &__frame {
        height: 60px;
        width: 60px;
      }

      &__logo {
        background-size: 52px;
        font-size: 10px;
        height: 60px;
        width: 60px;
      }

      &__logo-be {
        background-size: 35px;
      }
    }
  }
}

.onDemandTooltip {
  width: 200px;
  margin-left: 10px;
  color: $text-body;
  box-shadow: 0 0 3px $text-body;
  background-color: $white;
  padding: 15px 13px;
  text-align: left;

  /* arrow */
  &:after,
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 10px;
    display: block;
    -webkit-transform: translateX(-50%) rotate(45deg);
    transform: translateX(-50%) rotate(45deg);
    height: 20px;
    width: 20px;
    margin-top: -10px;
  }

  &:before {
    -webkit-box-shadow: 0 1px 4px 0 $text-body;
    box-shadow: 0 1px 4px 0 $text-body;
    @include z-index(hide);
  }

  &:after {
    background: $white;
    @include z-index(low);
  }
}

.ZipModal {
  .modal--close {
    position: absolute;
    top: 27px;
    right: 54px;
    z-index: 1001;
  }

  .modal_title--header {
    margin-bottom: 50px;
    border-bottom: 1px solid $gray-23;
  }

  .modal_title--header .modal_title--header__text {
    color: $black-3;
    text-align: left;
    margin-left: 10%;
    max-width: 70%;
    @include one-hulu-title-24;
  }

  .modal--body {
    margin: 0 10%;
  }

  .modal--body {
    padding: 0;
  }

  .modal_title--header--scrolling {
    background: $white;
  }
}