@import '../../../styles/breakpoints';
@import '../../../styles/mixins';
@import '../../../styles/colors';

.ExitIntentModal__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @include z-index(top);
  overflow: auto;

  &.--show {
    display: block;
    justify-content: center;
    opacity: 1;
    animation: 'fade-in' 0.15s linear forwards;

    .ExitIntentModal__backdrop {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      @include z-index(higher);
      background-color: $black;
      opacity: 0.8;
      transition: opacity 0.3s linear;
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
    }

    &.modal-closing {
      animation: 'fade-out' 0.15s ease-out forwards;
    }
  }
  &.--hide {
    display: none;
  }

  &.modal-hide {
    display: none;
  }
  .ExitIntentModal__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: flex-end;
    // padding: 32px 32px 0;
    padding-top: 32px;
    @include media-breakpoint-down(md) {
      padding: 20px 20px 0;
    }
  }
  
  .ExitIntentModal__body {
    // width: auto;
    position: relative;
    display: block;
    text-align: center;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    outline: 0;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    @include z-index(higher);

    // width: 90%;
    @include media-breakpoint-up(md) {
      width: 80%;
      max-width: 930px;
      margin: 80px auto;
    }

    // @include media-breakpoint-down(sm) {
    //   min-height: 100vh;
    // }
    &.--rounded-corners {
      border-radius: 16px;
    }
    &.--background-color {
      &-gradient {
        background: linear-gradient(310deg, rgba(24, 57, 73, 1), rgba(4, 4, 5, 1));
      }
      &-black {
        background: black;
      }
      &-white {
        background: white;
      }
    }
    &.--border-color {
      &-green {
        border: 2px solid $green;
      }
    }


    .ExitIntentModal__image {
      padding: 20px;
      img {
        max-height: 400px;
        max-width: 300px;
        @include media-breakpoint-up(md) {
          max-height: 400px;
          max-width: 500px;
        }
        @include media-breakpoint-up(lg) {
          max-height: 400px;
          max-width: 800px;
          // max-width: 500px;
        }
      }
    }

    .ExitIntentModal__preheadline {
      @include one-hulu-label-12;
      color: $white;
      padding-bottom: 8px;
    }

    .ExitIntentModal__headline {
      @include one-hulu-title-32;
      color: $white;
      padding-bottom: 8px;
    }

    .ExitIntentModal__subheadline {
      @include one-hulu-subtitle-24;
      color: $white;
      padding-bottom: 16px;
    }

    .ExitIntentModal__primary {
      @include one-hulu-body-16;
      color: $white;
      padding-bottom: 16px;
    }

    .ExitIntentModal__cta {
      max-width: 150px;
      margin-bottom: 16px;

      &.--green {
        color: $green;
      }
    }

    .ExitIntentModal__legal {
      @include one-hulu-body-12;
      color: $white;
      padding-bottom: 8px;
    }
  }
}