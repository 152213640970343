@import '../../../../styles/breakpoints';
@import '../../../../styles/mixins';
@import '../../../../styles/colors';

$badge-min-height: 27px;
$navigation-padding: 74px;
$head-card-height-lg: 215px;
$head-card-height-md: 201px;
$head-card-height-sm: 189px;
$head-card-height-no-badge: $head-card-height-lg - $badge-min-height;

.plan-head-column-for-new-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  padding: 20px 0px;

  @include media-breakpoint-up(md) {
    padding: 20px 40px;
  }

  @include media-breakpoint-up(lg) {
    height: $head-card-height-lg;
    padding: 15px 0 10px;
    border-bottom: 1px solid $gray-3;
  }
}

.is-dark {
  .plan-head-column-for-new-toggle {
    @include media-breakpoint-up(lg) {
      border-bottom: 1px solid $gray-dimmed;
    }
  }
}
