.tab {
  flex: 0 0 100%;
  opacity: 0;
  transition: all 0.5s ease;
  &--hidden {
    display: none;
  }

  &--active {
    opacity: 1;
    display: block;
  }
}