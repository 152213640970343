@import '../../../../styles/global';
@import '../../../../styles/breakpoints';
@import '../../../../styles/fluid-grid';

.GridCollection {
  @extend .container--wide;
  background-color: $white;
  padding-top: 54px;

  &__title {
    margin-bottom: 48px;
  }

  &__items {
    @extend .grid-container;
  }

  &__item {
    @extend .grid-col;

    margin-bottom: 34px;

    .Tile__content {
      height: 77px;
    }
  }
}
