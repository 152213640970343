@import '../../../styles/breakpoints';
@import '../../../styles/colors';

$standardHeightD: 660px;
$standardHeightT: 600px;
$standardHeightM: 660px;
// This time is matched with a timeout in the carousel. If the carousel time changes
// This would need to change as well
$animationTime: 0.4s;

.mhcarousel {
  position: relative;
  background-position: center top;
  background-size: cover;

  @include media-breakpoint-up(xs) {
    min-height: $standardHeightM;
    max-height: $standardHeightM;
  }

  @include media-breakpoint-up(md) {
    min-height: $standardHeightT;
    max-height: $standardHeightT;
  }

  @include media-breakpoint-up(lg) {
    min-height: $standardHeightD;
    max-height: $standardHeightD;
  }

  &__content-pane {
    width: 100%;
    transform: translateX(0);
    -webkit-transition: transform $animationTime ease;
    -moz-transition: transform $animationTime ease;
    -o-transition: transform $animationTime ease;
    transition: transform $animationTime ease;
    &.mhcarousel--animate-prev {
      transform: translateX(100%);
    }
    &.mhcarousel--animate-next {
      transform: translateX(-100%) !important;
    }
    &.mhcarousel--tease-prev {
      transform: translateX(5%);
      z-index: 2;
    }
    &.mhcarousel--tease-next {
      transform: translateX(-5%);
    }
  }
  &--content-pane-cur {
    transition: none;
  }
  &--content-pane-prev,
  &--content-pane-next {
    z-index: 2;
  }
  &__content-panes {
    display: flex;
    position: relative;
    left: -100%;
  }
  &__content-wrapper {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
  }

  &__nav {
    align-content: center;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    position: absolute;
    bottom: 5%;
    z-index: 4;
    width: 100%;
    @include media-breakpoint-down(sm) {
      bottom: 2.5%;
    }
    &.align-left {
      justify-content: flex-end;
      right: 10%;
      @include media-breakpoint-down(sm) {
        justify-content: center;
        right: 0;
      }
    }
    &.align-right {
      justify-content: flex-start;
      left: 10%;
      @include media-breakpoint-down(sm) {
        justify-content: center;
        left: 0;
      }
    }
  }

  &__navButton {
    button {
      background: none;
      border: 0;
      height: 30px;
      outline: none;
      position: relative;
      width: 40px;
      padding: 0;
      cursor: pointer;
      overflow: visible;
    }
    button::after {
      background-color: $white;
      opacity: 0.4;
      border-radius: 50%;
      content: '';
      display: block;
      height: 10px;
      position: absolute;
      top: 10px;
      width: 10px;
      left: 50%;
      margin-left: -5px;
    }
    button::before {
      border-radius: 50%;
      content: '';
      display: block;
      height: 10px;
      position: absolute;
      top: 10px;
      width: 10px;
      left: 50%;
      margin-left: -5px;
    }
    @media (hover: hover), (-moz-touch-enabled: 0) {
      &:hover {
        button::after {
          opacity: 1;
        }
      }
    }
  }

  &--navButton-active {
    button::before {
      background-color: $white;
      border-radius: 50%;
      content: '';
      display: block;
      height: 10px;
      position: absolute;
      top: 10px;
      width: 10px;
      left: 50%;
      margin-left: -5px;
      z-index: 1;
      transition: transform 0.25s linear;
    }
  }

  @media (hover: hover), (-moz-touch-enabled: 0) {
    .mhcarousel--move-next button {
      &::before {
        transform: translateX(40px);
      }
    }

    @for $i from 3 through 8 {
      $transx: -40px * ($i - 1);

      .mhcarousel--move-first-#{$i} button {
        &::before {
          transform: translateX($transx);
        }
      }
    }

    .mhcarousel--move-prev button {
      &::before {
        transform: translateX(-40px);
      }
    }

    @for $i from 3 through 8 {
      $transx: 40px * ($i - 1);

      .mhcarousel--move-last-#{$i} button {
        &::before {
          transform: translateX($transx);
        }
      }
    }

    .mhcarousel--halo {
      button::before {
        height: 30px;
        width: 30px;
        margin-left: -15px;
        top: 0;
        border: 1px solid $white;
        transition: all 0.25s linear;
      }
    }

    &__navButton:hover {
      button::before {
        height: 30px;
        width: 30px;
        margin-left: -15px;
        top: 0;
        border: 1px solid $white;
        transition: all 0.25s linear;
      }
    }

    &--navButton-active:hover {
      button::before {
        border-radius: 50%;
        height: 10px;
        top: 10px;
        width: 10px;
        left: 50%;
        margin-left: -5px;
        z-index: 1;
        transition: none;
      }
    }
  }

  &__arrows {
    height: 0;
    left: 0;
    position: absolute;
    top: calc(50% - 20px);
    width: 100%;
    z-index: 4;
    @include media-breakpoint-down(sm) {
      top: calc(50% - 10px);
    }
  }

  &__arrow {
    background: none;
    border: none;
    height: 38px;
    outline: none;
    position: absolute;
    padding: 0px;
    width: 19px;
    cursor: pointer;
    @include media-breakpoint-down(sm) {
      height: 17px;
    }

    &::after,
    &::before {
      background-color: $white;
      border-radius: 3px;
      content: '';
      display: block;
      height: 23px;
      position: absolute;
      width: 2px;
      left: 10px;
      transition: background-color 0.25s ease;
      @include media-breakpoint-down(sm) {
        height: 10px;
      }
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }

  &--arrows-left {
    top: 0;
    left: 8%;
    @include media-breakpoint-down(lg) {
      left: 7.1%;
    }
    @include media-breakpoint-down(md) {
      left: 4.8%;
    }
    &::before {
      transform: rotate(45deg);
      transition: margin 0.25s ease;
    }

    &::after {
      transform: rotate(-45deg);
      transition: margin 0.25s ease;
    }
    @media (hover: hover), (-moz-touch-enabled: 0) {
      &:hover:before,
      &:hover:after {
        margin-left: -3px;
      }
    }
  }

  &--arrows-right {
    top: 0;
    right: 8%;
    @include media-breakpoint-down(lg) {
      right: 7.1%;
    }
    @include media-breakpoint-down(md) {
      right: 4.8%;
    }
    &::before {
      transform: rotate(-45deg);
      transition: margin 0.25s ease;
    }

    &::after {
      transform: rotate(45deg);
      transition: margin 0.25s ease;
    }
    @media (hover: hover), (-moz-touch-enabled: 0) {
      &:hover:before,
      &:hover:after {
        margin-left: 3px;
      }
    }
  }

  .align-left .mhcarousel__content-pane {
    .start-masthead.align-left,
    .start-masthead.align-right,
    .start-masthead {
      @include media-breakpoint-up(md) {
        text-align: left;

        .start-masthead--block {
          margin-left: 0;
          margin-right: auto;
        }
      }
    }

    @include media-breakpoint-up(md) {
      .start-masthead--input-container.align-left,
      .start-masthead--input-container.align-right,
      .start-masthead--input-container {
        justify-content: flex-start;
      }
    }
  }

  .align-right .mhcarousel__content-pane {
    .start-masthead.align-left,
    .start-masthead.align-right,
    .start-masthead {
      @include media-breakpoint-up(md) {
        text-align: right;

        .start-masthead--block {
          margin-right: 0;
          margin-left: auto;
        }
      }
    }

    @include media-breakpoint-up(md) {
      .start-masthead--input-container.align-left,
      .start-masthead--input-container.align-right,
      .start-masthead--input-container {
        justify-content: flex-end;
      }
    }
  }

  .align-top .mhcarousel__content-pane {
    .start-masthead.align-top,
    .start-masthead.align-bottom,
    .start-masthead {
      .content {
        -ms-align-self: flex-start;
        align-self: flex-start;
      }
    }
  }

  .align-bottom .mhcarousel__content-pane {
    .start-masthead.align-top,
    .start-masthead.align-bottom,
    .start-masthead {
      .content {
        -ms-align-self: flex-end;
        align-self: flex-end;
      }
    }
  }

  &--hide-content {
    .start-masthead {
      .content {
        opacity: 0;
        transform: translate3d(0, 20px, 0);
      }
    }
  }

  &.mhcarousel--fade-in {
    .start-masthead {
      .content {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: transform $animationTime ease, opacity $animationTime ease;
      }
    }
  }

  .start-masthead {
    background: none;
  }

  .start-masthead--scroll {
    z-index: 4;
  }

  .masthead.has-padding {
    padding: 0;
  }

  .start-masthead--content-container {
    margin: 0 4.15%;
    @include media-breakpoint-down(lg) {
      margin: 0 8.33%;
    }
  }

  .mhcarousel--fade-in-next-pane,
  .mhcarousel--fade-in-prev-pane {
    order: 1 !important;
    z-index: 3 !important;
    animation: 'fade-in' $animationTime ease-in-out forwards;
  }

  .mhcarousel--fade-in-next-pane {
    order: 3 !important;
  }

  .mhcarousel--freeze-next-pane {
    transform: translateX(100%);
    transition: none;
    z-index: 0 !important;
  }
  .mhcarousel--freeze-prev-pane {
    transform: translateX(-100%);
    transition: none;
    z-index: 0 !important;
  }
}
