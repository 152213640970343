@import '../../styles/breakpoints';
@import '../../styles/colors';
$footer-line-space: 32px;

footer {
  background-color: $accent-grey;
  flex: 1 1 0;
  flex-shrink: 0;

  .footer__copyright {
    display: inline;
    @include media-breakpoint-down(sm) {
      display: none;
    }
    &-mobile {
      display: none;
      @include media-breakpoint-down(sm) {
        display: inline;
      }
    }
  }

  .hulu-footer {
    text-align: left;
    color: $text-contrast-gray;
  }

  .footer--site-links {
    padding: 32px 0;
    a {
      padding-right: 32px;
      font-size: 14px;
    }
  }

  a {
    text-decoration: none;
    color: $text-contrast-gray;
  }

  span {
    color: $text-contrast-gray;
  }

  .footer--legal-links {
    border-top: 2px solid $gray-7;
    padding: $footer-line-space 0 0 0;
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    line-height: 32px;
    justify-content: flex-start;

    &-left {
      margin: 0 42px 32px 0;
      min-width: fit-content;
      white-space: nowrap;

      span, a {
        padding-right: 32px;
      }
      a:last-child {
        padding-right: 0;
      }
    }

    &-right {
      display: flex;
      flex-wrap: wrap;
      margin-left: -32px;
      margin-bottom: 32px;

      &-col {
        margin-left: 26px;
        margin-right: 0;
        line-height: 32px;
        box-sizing: border-box;
        min-width: fit-content;

        &:last-child {
          margin: 0;
        }

        @include media-breakpoint-down(sm) {
          &:nth-child(2) {
            margin: 0;
          }
          &:last-child {
            margin-left: 32px;
          }
        }
        .footer--legal-link{
          img {
            padding-right: 4px;
          }
        }
      }
    }
  }

  .footer--legal-link {
    display: inline-block;
    @include media-breakpoint-down(sm) {
      font-size: 12px;
    }
  }
}
