@import '../../../styles/mixins';

.media-container {
  width: 100%;
  text-align: center;
  margin: 0 auto;

  &__wrapper {
    max-width: 1600px;
    width: 100%;
    margin: 0 auto;

    img {
      @include lazyLoad(null);
    }
  }
}
