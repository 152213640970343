@import '../../../styles/breakpoints';
@import '../../../styles/colors';

.signup-modal {
  text-align: center;
  margin-bottom: 60px;

  @include media-breakpoint-between(sm,md) {
    margin-right: 40px;
    margin-left: 40px;
  }

  &__parent.modal-dialog {
    @include media-breakpoint-up(md) {
      max-width: 600px;
    }
  }

  &__brand {
    width: 60px;
    height: 74px;

    span {
      display: inline-block;
      position: absolute;
      text-indent: -9999px;
    }
  }

  &__logo {
    margin: 5px auto 0;
    width: 61px;
    height: 24px;
    fill: $logo-green;
  }

  &__headline {
    margin-top: 76px;
    margin-bottom: 0;
    span {
      font-weight: 600;
    }
  }

  &__subheadline {
    margin-top: 4px;
    color: $text-body;
  }

  &__body {
    margin-top: 16px;
    margin-bottom: 54px;
    color: $text-body;
  }

  &__button {
    width: 100%;

    &:not(&:last-of-type){
      margin-bottom: 8px;
    }
  }

  &__parent {
    .modal--footer {
      display: none;
    }
  }

  &__legal {
    margin-top: 16px;
    color: $text-body;

    a {
      color: $text-body;
      text-decoration: underline;
    }
  }
}