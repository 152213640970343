@import '../../../styles/variables';
@import '../../../styles/breakpoints';
@import '../../../styles/colors';

.ListCard {
  margin-top: 20px;

  &:last-of-type {
    margin-bottom: 64px;
  }

  &__content {
    background: $white;
    box-shadow: 0 10px 20px -5px rgba(0,0,0,0.08);
    padding: 25px 24px 41px;

    @include media-breakpoint-up(md) {
      padding: 32px 38px 25px;
    }

    @include media-breakpoint-up(lg) {
      padding: 32px 40px 40px;
    }
  }

  &__header {
    border-bottom: 1px solid $gray-3;
    padding-bottom: 25px;

    &__title {
      color: $gray-1;
    }

    &__viewAll {
      color: $gray-1;
      opacity: 0.8;
      padding-left: 10px;

      &:hover {
        color: $gray-4;
      }

      &__arrow {
        background: url('#{$prefix}/static/icons/arrow_right.svg') no-repeat center center / cover;
        display: inline-block;
        height: 14px;
        left: 6px;
        position: relative;
        top: 3px;
        width: 7px;
      }
    }
  }
}
