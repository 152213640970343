@import '../../../../styles/mixins';
@import '../../../../styles/colors';

.bundle-header-with-new-toggle {
  display: flex;
  justify-content: center;
  gap: 10px;
  &__left-bottom-headline {
    display: flex;
    justify-content: center;
    margin-top: 19px;
    img {
      width: 46px;
      object-fit: cover;
    }
  }
  &__right-top-headline {
    display: flex;
    align-items: center;
  }
}
