@import '~@hulu/site-styles/font_utils';
@import '../../../styles/breakpoints';
@import '../../../styles/variables';
@import '../../../styles/colors';

.EmailUnsubscribe {
  display: flex;
  justify-content: center;
  font-family: "Graphik", Helvetica, Arial, sans-serif;
  margin-top: 96px;
  margin-bottom: 120px;

  @include media-breakpoint-up(lg) {
    margin-top: 129px;
  }

  &__container {
    position: relative;
    background: $white;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.06);
    padding: 40px 0;
    width: 272px;

    @include media-breakpoint-up(sm) {
      width: 327px;
    }
    @include media-breakpoint-up(md) {
      width: 354px;
    }
    @include media-breakpoint-up(lg) {
      width: 536px;
    }
  }

  &__image {
    margin: 0 auto;
    display: block;
    width: 60px;
  }

  &__inner-container {
    text-align: center;
    margin: 32px auto 0 auto;
    width: 228px;

    @include media-breakpoint-up(sm) {
      width: 279px;
    }
    @include media-breakpoint-up(lg) {
      width: 384px;
    }
  }

  &__headline {
    color: $gray-11;
    margin-bottom: 0;
  }

  &__description {
    color: $gray-11;
    margin: 24px 0 32px 0;
  }

  &__loading {
    width:24px;
    height:24px;
    border-radius:50%;
    border:3px solid rgba(255, 255, 255, 0.7);
    border-right-color: $gray-18;
    margin: auto;
    animation: rotate 800ms infinite linear;
    transform:translateZ(0px);

  @keyframes rotate {
    0% {transform:rotate(0deg);}
    100% {transform:rotate(360deg);}
  }
}

  &__resubscribe {
    width: 100%;
    margin-bottom: 5px !important;
  }

  &__back {
    width: 100%;
    margin-bottom: 40px;
  }

}