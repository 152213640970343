@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/mixins';
@import '../../../styles/global';

.headline__wrapper {
  text-align: center;

  &--sitemap {
    text-align: left;
    margin: 74px 0 40px;
    padding: 36px 0;
    border-top: 1px solid $accent-grey;
    border-bottom: 1px solid $accent-grey;
    background-color: $white;

    @include media-breakpoint-up(lg) {
      padding: 48px 0;
    }
  }

  .section-headline {
    @include one-hulu-title-32;
    @include media-breakpoint-up(lg) {
      @include one-hulu-title-40;
    }
  }

  .headline--description {
    @include one-hulu-body-16;
  }
}
