@import '../../../styles/mixins';
@import '../../../styles/colors';

.bundle-header-with-new-toggle {
  display: flex;
  justify-content: center;
  gap: 10px;
  &__left-bottom-headline {
    display: flex;
    justify-content: center;
    margin-top: 18px;
    img {
      width: 35px;
      object-fit: cover;
    }
  }
  &__right-top-headline {
    display: flex;
    align-items: center;
  }
}

.bundle-header {
  display: flex;
  flex-direction: column;
  &__headline {
    margin-bottom: 5px;
    text-transform: none;
    display: flex;
    align-items: center;

    @include media-breakpoint-up(lg) {
      margin-top: 7px;
      order: 2;
    }

    img {
      margin-left: 6px;
      position: relative;
      bottom: 1px;
      width: 16px;
      height: 16px;
      @include media-breakpoint-up(lg) {
        margin-left: 16px;
      }
    }
  }
  &__toggle-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    img {
      width: 166px;
      height: 35px;
    }
    @include media-breakpoint-up(lg) {
      order: 1;
    }
    &--desktop {
      @include media-breakpoint-up(lg) {
        order: 4;
      }
    }
  }
  &__description {
    margin-bottom: 0;
    width: 60%;

    @include media-breakpoint-up(lg) {
      margin-bottom: 12px;
      order: 3;
    }
  }
}

.bundle-header-with-new-toggle__right-top-headline img {
  padding-left: 6px;
}
