@import '../../../../styles/breakpoints';
@import '../../../../styles/variables';

.VideoBackground {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &__video {
    /* Make video to at least 100% wide and tall */
    min-width: 100%;
    min-height: 100%;

    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: auto;
    height: auto;

    /* Center the video */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    animation: fade-in 1s ease-in forwards;
  }
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    /* This is the least amount of opacity that will allow the contrast of white font on white video. */
    background-color: rgba(0, 0, 0, 0.59);
  }

  &__controls {
    bottom: 16px;
    cursor: pointer;
    height: 40px;
    position: absolute;
    right: 56px;
    width: 40px;
    z-index: 1;

    &--playing {
      background: url('#{$prefix}/static/icons/pause.svg') no-repeat center
        center / cover;

      &:hover {
        background: url('#{$prefix}/static/icons/pause_hover.svg') no-repeat
          center center / cover;
      }
    }

    &--paused {
      background: url('#{$prefix}/static/icons/play.svg') no-repeat center
        center / cover;

      &:hover {
        background: url('#{$prefix}/static/icons/play_hover.svg') no-repeat
          center center / cover;
      }
    }
  }
}
