@import '../../../styles/breakpoints';
@import '../../../styles/mixins';
@import '../../../styles/colors';

.Anchor {
  margin-top: auto;
  position: relative;
  &__copy {
    margin: 0 auto;
    color: $white;
    letter-spacing: 1px;
    font-size: 12px;
    padding-bottom: 10px;
  }

  &__circle {
    margin: 0 auto;
    display: block;
    width: 74px;
    cursor: pointer;

    img {
      @include media-breakpoint-down(sm) {
        width: 48px;
      }
    }
  }
}

.Anchor__circle--transparent {
  margin: 0 auto;
  display: block;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid $anchor-circle-border;
  background-color: transparent;
  overflow: hidden;
  position: relative;
  text-align: center;
  line-height: 61px;
  width: 66px;
  height: 66px;
  @include media-breakpoint-down(sm) {
    line-height: 42px;
    width: 42px;
    height: 42px;
  }

  img {
    @include media-breakpoint-down(sm) {
      width: 12px;
      height: 12px;
    }
  }
}

.Anchor__container {
  display: flex;
  flex-direction: column;
  .Anchor {
    margin: -36px auto -36px;
    @include z-index(low);

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}
