@import 'breakpoints';

$grid-columns: 12 !default;

$grid-gutter-width: 24px !default;

$grid-column-width: calc((100% - #{$grid-columns - 1} * #{$grid-gutter-width}) / #{$grid-columns});

// calculate width of an element based on n number of grid columns
@function grid-column-width($n) {
  @return calc(#{$grid-column-width} * #{$n} + (#{$n - 1} * #{$grid-gutter-width}));
}

$grid-mobile-columns: 8 !default;

$grid-mobile-gutter-width: 12px !default;

$grid-mobile-column-width: calc((100% - #{$grid-mobile-columns - 1} * #{$grid-mobile-gutter-width}) / #{$grid-mobile-columns});

// calculate width of an element based on n number of grid columns
@function grid-mobile-column-width($n) {
  @return calc(#{$grid-mobile-column-width} * #{$n} + (#{$n - 1} * #{$grid-mobile-gutter-width}));
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
}

.grid-col {
  overflow: hidden;
  flex: 0 0 100%;

  @include media-breakpoint-up(xs) {
    padding: 0 6px;
  }

  @include media-breakpoint-up(md) {
    flex: 0 0 (100% / 3);
    padding: 0 8px;
  }

  @include media-breakpoint-up(lg) {
    flex: 0 0 (100% / 4);
    padding: 0 12px;
  }

  @include media-breakpoint-up(xl) {
    flex: 0 0 (100% / 5);
  }

  @include media-breakpoint-up(xxl) {
    flex: 0 0 (100% / 6);
  }
}
