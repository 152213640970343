@import '~@hulu/site-styles/variables';
@import '../../../../styles/breakpoints';
@import '../../../../styles/mixins';
@import '../../../../styles/colors';

.one-click-reconnect-modal-container {
  padding: 32px;
  max-width: 600px;
  border-radius: 4px;

  // animates the modal to slide down on load
  animation-duration: 360ms;
  animation-fill-mode: forwards;
  animation-name: animateDown;
  animation-timing-function: ease;

  .modal--body {
    padding: 0 32px;
    @media (max-width: $screen-sm-min) {
      padding: 0px;
    }
  }

  .one-click-modal-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    @include one-hulu-subtitle-18;
    .modal-headline {
      font-size: 32px;
      font-weight: 600;
      margin: 0px 32px;
      @media (max-width: $screen-sm-min) {
        margin: 0px;
      }
    }
    .modal-logos {
      margin-bottom: 24px;
      @media (max-width: $screen-sm-min) {
        margin-top: 16px;
      }
    }
    .modal-logos {
      margin-bottom: 24px;
    }
    .modal-description {
      margin: 16px 32px 0px 32px;
      font-size: 16px;
      font-weight: 400;
      @media (max-width: $screen-sm-min) {
        margin: 16px 0px 0px 0px;
      }
    }
    .button--cta {
      margin-top: 32px;
      &:hover {
        background: $green-hover !important;
      }
    }
    .modal-link {
      margin-top: 24px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  @media (max-width: $screen-sm-min) {
    margin: 24px;
    padding: 24px;
    min-height: 0;
  }
}

@keyframes animateDown {
  from {
    transform: (translateY(-20%));

    opacity: 0;
  }

  to {
    transform: translateY(0);

    opacity: 1;
  }
}
